import React from "react";
import { useParams, useNavigate, NavLink } from "react-router-dom";
import moment from "moment";
import { useForm } from "react-hook-form";
import JoditEditor from "jodit-react";

import useTeachersStore from "../../../store/admin/teachersStore";
import useTheatresStore from "../../../store/admin/theatresStore";

import Button from "../../../components/button/button.component";
import TheatreRequest from "../../../components/page_components/theatre_request/theatre_request.component";
import Notif from "../../../components/notif/notif.component";
import Popup from "../../../components/popup/popup.component";
import Editor from "../../../components/reach_editor/editor.component";
import Accordion from "../../../components/accordion/accordion.component";

import commonStyles from "../../common.module.scss";
import { AdminIcons } from "../../../components/svgs.js";

const TheatreRequestPage = () => {
    let { id } = useParams();
    const navigate = useNavigate();

    const { register, handleSubmit, control } = useForm();

    const {
        theatreRequest,
        loadTheatreRequest,
        editTheatre,
        removeTheatre,
        requestChangeNew,
        loading,
        sending,
        error,
        errorText,
        clearErrorText,
    } = useTheatresStore();
    const teachersStore = useTeachersStore();

    const [popup, setPopup] = React.useState(<></>);
    const [edit, setEdit] = React.useState(false);

    const fetchData = async () => {
        const request = await loadTheatreRequest({ id });
        await teachersStore.loadTeachers({ schoolID: request.schoolID });

        if (request.status === "Новая") await requestChangeNew({ id });

        console.clear();
        console.log(request);
    };

    React.useEffect(() => {
        if (error) {
            setPopup(
                <Notif
                    title='Ошибка!'
                    text={errorText}
                    opened={true}
                    onClose={() => {
                        clearErrorText();
                        setPopup(<></>);
                    }}
                />
            );
        }
    }, [error]);

    React.useEffect(() => {
        fetchData();
    }, []);

    const back = () => navigate("/admin/theatreRequests");

    const getStatusText = (value) => {
        switch (value) {
            case "Новая":
                return <p className='request-status --status-new'>Новая</p>;
            case "Принята":
                return <p className='request-status --status-accept'>Принята</p>;
            case "Отклонена":
            case "Удаление отклонено":
                return <p className='request-status --status-decline'>Отклонена</p>;
            case "Отозвана":
                return <p className='request-status --status-callback'>Отозвана</p>;
            case "Рассмотрение":
                return <p className='request-status --status-review'>Рассмотрение</p>;
            case "Удаление":
                return <p className='request-status --status-decline'>Удаление</p>;
            default:
                return <>{value}</>;
        }
    };

    const checkForComplete = (item) => {

        if(item.title === "")
        {
            setPopup(
                <Notif
                    title="Ошибка!"
                    text={"Вы не заполнили поле: Название театра"}
                    opened={true}
                    onClose={() => {
                        clearErrorText();
                        setPopup(<></>);
                    }}
                />
            );
            return false;
        }

        if(item.address === "")
        {
            setPopup(
                <Notif
                    title="Ошибка!"
                    text={"Вы не заполнили поле: Адрес"}
                    opened={true}
                    onClose={() => {
                        clearErrorText();
                        setPopup(<></>);
                    }}
                />
            );
            return false;
        }

        if(item.form_activity_select.length === 0)
        {
            setPopup(
                <Notif
                    title="Ошибка!"
                    text={"Вы не заполнили поле: Форма осуществления деятельности"}
                    opened={true}
                    onClose={() => {
                        clearErrorText();
                        setPopup(<></>);
                    }}
                />
            );
            return false;
        }

        if(item.age_members_select.length === 0)
        {
            setPopup(
                <Notif
                    title="Ошибка!"
                    text={"Вы не заполнили поле: Возрастной состав участников школьного театра"}
                    opened={true}
                    onClose={() => {
                        clearErrorText();
                        setPopup(<></>);
                    }}
                />
            );
            return false;
        }

        if(item.foundationDate === "")
        {
            setPopup(
                <Notif
                    title="Ошибка!"
                    text={"Вы не заполнили поле: Дата основания"}
                    opened={true}
                    onClose={() => {
                        clearErrorText();
                        setPopup(<></>);
                    }}
                />
            );
            return false;
        }

        return true;

    };

    const onAcceptSubmit = async (params) => {
        params.id = id;
        params.status = 3;
        params.theatreID = theatreRequest.theatreID;
        params.schoolID = theatreRequest.schoolID;

        if(!checkForComplete(params))
            return;

        const result = await editTheatre(params);

        if (!result.error) {
            back();
        }
    };

    const onDeclineSubmit = async (params) => {
        const onSendSubmit = async (text) => {
            params.id = id;
            params.status = 4;
            params.declineText = text.declineText;
            params.theatreID = theatreRequest.theatreID;
            params.schoolID = theatreRequest.schoolID;

            if(!checkForComplete(params))
                return;

            const result = await editTheatre(params);

            if (!result.error) {
                back();
            }
        };

        setPopup(
            <Popup
                title={"Укажите причину отклонения заявки"}
                opened={true}
                onClose={() => {
                    setPopup(<></>);
                }}
            >
                <form onSubmit={handleSubmit(onSendSubmit)} className='form'>
                    <fieldset className='form__section --content-info'>
                        <Editor required={true} control={control} name='declineText' />
                    </fieldset>
                    <div className='form__controls'>
                        <Button
                            type='submit'
                            text='Отправить'
                            spinnerActive={sending}
                            style={{ marginLeft: "auto", display: "block" }}
                        />
                    </div>
                </form>
            </Popup>
        );
    };

    const onDeclineDeleteSubmit = async () => {
        const onDeleteSendSubmit = async (text) => {
            const result = await editTheatre({
                id,
                status: 7,
                declineText: text.deleteDeclineText,
            });

            if (!result.error) {
                back();
            }
        };

        setPopup(
            <Popup
                title={"Укажите причину отклонения заявки"}
                opened={true}
                onClose={() => {
                    setPopup(<></>);
                }}
            >
                <form onSubmit={handleSubmit(onDeleteSendSubmit)} className='form'>
                    <fieldset className='form__section --content-info'>
                        <Editor required={true} control={control} name='deleteDeclineText' />
                    </fieldset>
                    <div className='form__controls'>
                        <Button
                            type='submit'
                            text='Отправить'
                            spinnerActive={sending}
                            style={{ marginLeft: "auto", display: "block" }}
                        />
                    </div>
                </form>
            </Popup>
        );
    };

    const onDeleteSubmit = async () => {
        setPopup(
            <Notif
                text={
                    <div>
                        <h3>Вы действительно хотите удалить театр?</h3>
                        <p style={{ color: "red" }}>ВНИМАНИЕ!</p>
                        <p>1. Удалятся все фотографии и файлы связанные с театром.</p>
                        <p>2. Удалятся все заявки, фотографии и файлы связанные с заявками.</p>
                        <p>3. Удалятся все постановки, фотографии и файлы связанные с постановками.</p>
                        <div>
                            <b>Данное действие не обратимо.</b>
                        </div>
                    </div>
                }
                opened={true}
                onClose={() => {
                    setPopup(<></>);
                }}
                buttons={
                    <>
                        <Button type='button' text='Нет' size={"small"} theme='text' onClick={() => setPopup(<></>)} />
                        <Button
                            type='button'
                            text='Да'
                            theme='info'
                            size={"small"}
                            onClick={async () => {
                                const result = await removeTheatre({
                                    id: theatreRequest.theatreID,
                                });

                                if (!result.error) {
                                    clearErrorText();
                                    setPopup(<></>);
                                    back();
                                }
                            }}
                        />
                    </>
                }
            />
        );
    };

    if (loading || teachersStore.loading) return <p>Загрузка...</p>;

    if (edit) {
        return (
            <>
                <div className={commonStyles.title_block}>
                    <Button
                        type='button'
                        theme='text'
                        size='small'
                        iconClass={"mdi mdi-arrow-left"}
                        isIconBtn='true'
                        aria-label='Назад'
                        onClick={() => setEdit(false)}
                    />
                    <h1 className={commonStyles.title}>Редактирование заявки ID: {id} </h1>
                </div>
                <TheatreRequest
                    isAdmin={true}
                    request={theatreRequest}
                    onDecline={onDeclineSubmit}
                    onSubmitDone={onAcceptSubmit}
                    onBack={() => setEdit(false)}
                />
                {popup}
            </>
        );
    }

    return (
        <>
            {id && theatreRequest && Object.keys(theatreRequest).length > 0 ? (
                <>
                    <div className={commonStyles.title_block}>
                        <Button
                            type='button'
                            theme='text'
                            size='small'
                            iconClass={"mdi mdi-arrow-left"}
                            isIconBtn='true'
                            aria-label='Назад'
                            onClick={back}
                        />
                        <h1 className={commonStyles.title}>Заявка ID: {id}</h1>
                    </div>
                    <div className='request-card'>
                        <div className='request-card__section --content-main-info'>
                            <>{getStatusText(theatreRequest.status)}</>
                            {theatreRequest.status === "Отклонена" && (
                                <Accordion title={"Причина отказа"}>
                                    <JoditEditor
                                        config={{
                                            readonly: true,
                                            toolbar: false,
                                        }}
                                        value={theatreRequest.decline_text}
                                    />
                                </Accordion>
                            )}
                            {theatreRequest.status === "Удаление отклонено" && theatreRequest.decline_text && (
                                <Accordion title={"Причина отказа"}>
                                    <JoditEditor
                                        config={{
                                            readonly: true,
                                            toolbar: false,
                                        }}
                                        value={theatreRequest.decline_text}
                                    />
                                </Accordion>
                            )}
                            <ul className='request-card__dates'>
                                <li>
                                    <p className='request-card__date'>
                                        Дата подачи:
                                        <span className='request-card__date-accent'>
                                            {" "}
                                            {moment(theatreRequest.create_time).format("HH:mm DD.MM.YYYY")}
                                        </span>
                                    </p>
                                </li>
                                <li>
                                    <p className='request-card__date'>
                                        Дата обновления:
                                        <span className='request-card__date-accent'>
                                            {" "}
                                            {moment(theatreRequest.update_time).format("HH:mm DD.MM.YYYY")}
                                        </span>
                                    </p>
                                </li>
                            </ul>
                            <h1 className='request-card__title'>{theatreRequest.title}</h1>
                            {theatreRequest.theatreID > 0 && (
                                <NavLink
                                    className={commonStyles.link}
                                    to={"/admin/theatres/" + theatreRequest.theatreID}
                                    target={"_blank"}
                                    rel='noopener nofollow noreferrer'
                                >
                                    Страница театра <span className='mdi mdi-open-in-new' />
                                </NavLink>
                            )}
                            <p className={"request-card__item"}>
                                <span className={commonStyles.svgIcon}>{AdminIcons.toolbox}</span>
                                {theatreRequest.school.title}
                                <span className='request-card__description' style={{ textIndent: "2.5em" }}>
                                    Полное наименование школы
                                </span>
                            </p>
                            {theatreRequest.status === "Удаление" && (
                                <>
                                    <Button
                                        type='button'
                                        theme={"outline"}
                                        extraClass='request-card__btn'
                                        text={"Отклонить"}
                                        onClick={onDeclineDeleteSubmit}
                                    />
                                    <Button
                                        type='button'
                                        theme={"outline"}
                                        extraClass='request-card__btn'
                                        text={"Удалить театр"}
                                        onClick={onDeleteSubmit}
                                    />
                                </>
                            )}
                            {theatreRequest.status !== "Удаление" && theatreRequest.status !== "Удаление отклонено" && (
                                <Button
                                    type='button'
                                    theme={"outline"}
                                    extraClass='request-card__btn'
                                    text={"Просмотр заявки"}
                                    onClick={() => setEdit(true)}
                                />
                            )}
                        </div>
                        {theatreRequest.user.fio && (
                            <div class='request-card__section --content-contact-person'>
                                <p class='request-card__description'>Контактное лицо</p>
                                <p class='request-card__title'>{theatreRequest.user.fio}</p>
                                <ul className='request-card__list'>
                                    {theatreRequest.user.email && (
                                        <li>
                                            <a
                                                href={`email:${theatreRequest.user.email}`}
                                                className={["request-card__link", commonStyles.link].join(" ")}
                                                rel='noreferrer nofollow noopener'
                                                target='_blank'
                                            >
                                                <span className={commonStyles.svgIcon}>{AdminIcons.email}</span>
                                                {theatreRequest.user.email}
                                            </a>
                                        </li>
                                    )}
                                    {theatreRequest.user.phone && (
                                        <li>
                                            <a
                                                href={`tel:${theatreRequest.user.phone}`}
                                                className={["request-card__link", commonStyles.link].join(" ")}
                                                rel='noreferrer nofollow noopener'
                                                target='_blank'
                                            >
                                                <span className={commonStyles.svgIcon}>{AdminIcons.phone}</span>
                                                {theatreRequest.user.phone}
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <p>Не удалось найти заявку № {id}</p>
            )}
            {popup}
        </>
    );
};

export default TheatreRequestPage;

import React from "react";
import { Outlet } from "react-router-dom";

import Header from "../components/header/header.component";
import Menu from "../components/menu/menu.component";
import SupportHeaderComponent from "../components/header/support.header.component";
import ProfileHeader from "../components/header/profile.header.component";
import { MenuIcons } from "../components/svgs.js";
import styles from "./admin.module.scss";

const AdminLayout = () => {
    const [burgerOpened, setBurgerOpened] = React.useState(false);
    const menu = [
        {
            title: "Пользователи",
            icon: MenuIcons.users,
            link: "/admin/users",
        },
        {
            title: "Школы",
            icon: MenuIcons.schools,
            link: "/admin/schools",
        },
        // {
        //     title: "Фестивали",
        //     icon: MenuIcons.festival,
        //     link: "/admin/festivals",
        // },
        {
            title: "Заявки на фестивали",
            icon: MenuIcons.festivals_requests,
            link: "/admin/festivalRequests",
        },
        {
            title: "Театры",
            icon: MenuIcons.theatres,
            link: "/admin/theatres",
        },
        {
            title: "Заявки на театры",
            icon: MenuIcons.requests,
            link: "/admin/theatreRequests",
        },
        {
            title: "Постановки",
            icon: MenuIcons.spectacles,
            link: "/admin/spectacles",
        },
        {
            title: "Расписания постановок",
            icon: MenuIcons.spectacles_schedules,
            link: "/admin/spectacleSchedules",
        },
        {
            title: "Педагоги",
            icon: MenuIcons.teachers,
            link: "/admin/teachers",
        },
        {
            title: "Новости",
            icon: MenuIcons.news,
            link: "/admin/news",
        },
        {
            title: "Деятельность",
            icon: MenuIcons.activity,
            link: "/admin/activity",
        },
        {
            title: "Банк сценариев",
            icon: MenuIcons.scenarioBank,
            link: "/admin/scenarioBank",
        },
        // {
        //     title: "Настройки",
        //     icon: "--type-settings",
        //     link: "/admin/settings"
        // },
    ];

    const handleBurgerMenu = () => {
        setBurgerOpened(!burgerOpened);
    };

    return (
        <div className={styles.content}>
            <Header handleBurger={handleBurgerMenu}>
                <SupportHeaderComponent />
                <ProfileHeader />
            </Header>
            <Menu
                menu={menu}
                burgerOpened={burgerOpened}
                setBurgerOpened={handleBurgerMenu}
            />
            <main className={styles.main}>
                <Outlet />
            </main>
        </div>
    );
};

export default AdminLayout;

import React from "react";
import { NavLink } from "react-router-dom";
import { YMaps, Map, Placemark } from "react-yandex-maps";
import moment from "moment";
import { motion } from "framer-motion";
import lodash from "lodash";
import Cookies from "js-cookie";

import useTheatresStore from "../../../store/public/theatresStore";

import Skeleton from "react-loading-skeleton";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumbs.component";
import SearchFilter from "../../../components/search_filter/search.filter.component";
import Pagination from "../../../components/pagination/pagination.component";

import commonStyles from "../common.module.scss";
import styles from "./theatres.module.scss";
import noTheatreImage1 from "../../../images/no_photo_theatre_type_1.jpg";
import noTheatreImage2 from "../../../images/no_photo_theatre_type_2.jpg";
import noTheatreImage3 from "../../../images/no_photo_theatre_type_3.jpg";
import {signal, useSignalEffect} from "@preact/signals-react";

const startIndex = signal(0);
const pageIndex = signal(1);
const pageCount = signal(0);
const filtered = signal([]);

const TheatresPage = () => {
    const theatresStore = useTheatresStore();

    const fetchData = async () => {
        await theatresStore.loadTheatres();
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    const Theatres = () => {
        const pageSize = 12;
        const [paginatedItems, setPaginatedItems] = React.useState([]);
        const itemConfig = [
            {
                header: "Театр",
                theme: "public",
                size: "normal",
                key: "title",
                type: "string",
                filter: "string",
                sorting: true,
            },
            {
                header: "Школа",
                theme: "public",
                size: "normal",
                key: "school_title",
                type: "string",
                filter: "string",
                sorting: true,
            },
            {
                header: "Возраст участников",
                theme: "public",
                size: "normal",
                key: "age_members",
                type: "array",
                filter: "select",
                sorting: false,
            },
            {
                header: "Округ",
                theme: "public",
                size: "normal",
                key: "okrug",
                type: "string",
                filter: "select",
                sorting: true,
            },
        ];

        useSignalEffect(() => {
            pageCount.value = Math.ceil(filtered.value.length / pageSize);

            setPaginatedItems(lodash(filtered.value)
                .slice(startIndex.value)
                .take(pageSize)
                .value());
        });

        React.useEffect(() => {
            const tablePageIndex = Cookies.get("table_public_theatres") ? parseInt(Cookies.get("table_public_theatres")) : 1;

            handleChangePage(tablePageIndex);
        }, []);

        const handleFilter = (filter, changePage = true) => {
            function checkItem(config, itemValue, filterValue, prop) {
                if (prop === "search_string") {
                    let tmpFilter = {};

                    for (const itemKey in itemValue)
                        if (itemValue[itemKey] !== null)
                            tmpFilter[itemKey] = !!itemValue[itemKey]
                                .toString()
                                .toLowerCase()
                                .includes(filterValue["search_string"].toLowerCase());

                    if (Object.keys(tmpFilter).some((key) => tmpFilter[key])) return true;
                }

                switch (config?.type) {
                    case "int":
                        return parseInt(itemValue[prop]) === parseInt(filterValue[prop]);

                    case "string":
                        return config.filter === "select" || config.filter === "multiselect"
                            ? itemValue[prop] === filterValue[prop]
                            : itemValue[prop].toLowerCase().includes(filterValue[prop].toLowerCase());

                    case "date":
                        if ("linkKey" in config) {
                            if ("dateFilter" in config && config.dateFilter === "to")
                                return moment(itemValue[config["linkKey"]]).isBefore(moment(filterValue[prop]));
                            if ("dateFilter" in config && config.dateFilter === "from")
                                return moment(itemValue[config["linkKey"]]).isAfter(moment(filterValue[prop]));
                        }

                        return moment(itemValue[prop]).isSame(moment(filterValue[prop]));

                    case "datetime":
                        if ("linkKey" in config) {
                            if ("dateFilter" in config && config.dateFilter === "to")
                                return moment(itemValue[config["linkKey"]]).isBefore(moment(filterValue[prop]));
                            if ("dateFilter" in config && config.dateFilter === "from")
                                return moment(itemValue[config["linkKey"]]).isAfter(moment(filterValue[prop]));
                        }

                        const itemDate = moment(itemValue[prop]);
                        return moment({
                            year: itemDate.get("year"),
                            month: itemDate.get("month"),
                            day: itemDate.get("date"),
                        }).isSame(moment(filterValue[prop]));

                    case "array":
                        return itemValue[prop].some((item) => item === filterValue[prop]);

                    default:
                        if (itemValue[prop]) return itemValue[prop] === filterValue[prop];
                        else return false;
                }
            }

            if (filter) {
                let tmpArray = [];

                for (const item of theatresStore.theatres) {
                    let tmpFilter = {};

                    for (let prop in filter)
                        tmpFilter[prop] = !!(
                            filter[prop] === "" ||
                            filter[prop] === "Все" ||
                            checkItem(
                                itemConfig.find((itemConfig) => itemConfig.key === prop),
                                item,
                                filter,
                                prop
                            )
                        );

                    if (!Object.keys(tmpFilter).some((key) => !tmpFilter[key])) {
                        tmpArray.push(item);
                    }
                }

                filtered.value = tmpArray;
            }
            else
                filtered.value = theatresStore.theatres;

            if(changePage)
                handleChangePage(1);
        };

        const handleChangePage = (index) => {
            startIndex.value = (index - 1) * pageSize;
            pageIndex.value = index;

            Cookies.set("table_public_theatres", index);
        };

        const getMainLogoImage = (item) => {
            const getRandomEmptyImage = () => {
                const number = Math.floor(Math.random() * (4 - 1) + 1);

                switch (number) {
                    case 1:
                        return noTheatreImage1;
                    case 2:
                        return noTheatreImage2;
                    case 3:
                        return noTheatreImage3;
                }
            };

            let image = item.image;

            if (image === "" && item.photo !== null) image = item.photo;

            return image === "" ? getRandomEmptyImage() : process.env.REACT_APP_BASE_URL + image;
        };

        return (
            <>
                {theatresStore.loading && (
                    <>
                        <Skeleton height={66} />
                        <Skeleton width={"25vmax"} height={40} style={{ margin: "1.875em auto", display: "block" }} />
                        <ul className={styles.list}>
                            {Array(4)
                                .fill()
                                .map((item, index) => (
                                    <li
                                        className={commonStyles.theatreCard}
                                        key={index}
                                        style={{ borderColor: "#ebebeb" }}
                                    >
                                        <Skeleton className={commonStyles.theatreCardImage} />
                                        <div className={commonStyles.theatreCardMainInfo}>
                                            <Skeleton className={commonStyles.theatreCardTitle} count={2} />
                                            <Skeleton className={commonStyles.theatreCardSubtitle} count={2} />
                                            <Skeleton className={commonStyles.theatreCardButton} />
                                        </div>
                                    </li>
                                ))}
                        </ul>
                        <Skeleton width={"25vmax"} height={40} style={{ margin: "1.875em auto", display: "block" }} />
                    </>
                )}
                {!theatresStore.loading && (
                    <>
                        <SearchFilter
                            front={true}
                            items={theatresStore.theatres}
                            config={itemConfig}
                            onSubmit={handleFilter}
                        />
                        <Pagination pageCount={pageCount} pageIndex={pageIndex.value} setPageChangeCallback={handleChangePage} />
                        <ul className={styles.list}>
                            {paginatedItems.map((item, index) => (
                                <li key={item.ID} className={commonStyles.theatreCard}>
                                    <NavLink
                                        key={item.ID}
                                        to={"/theatres/" + item.ID}
                                        className={commonStyles.theatreCardImageLink}
                                    >
                                        <img
                                            className={commonStyles.theatreCardImage}
                                            src={getMainLogoImage(item)}
                                            alt={item.title}
                                        />
                                    </NavLink>
                                    <div className={commonStyles.theatreCardMainInfo}>
                                        <h3 className={commonStyles.theatreCardTitle}>{item.title}</h3>
                                        <p className={commonStyles.theatreCardSubtitle}>{item.school_title}</p>
                                        <NavLink to={"/theatres/" + item.ID} className={commonStyles.theatreCardButton}>
                                            Подробнее
                                        </NavLink>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <Pagination pageCount={pageCount} pageIndex={pageIndex.value} setPageChangeCallback={handleChangePage} />
                    </>
                )}
            </>
        );
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
        >
            <Breadcrumbs
                items={[
                    {
                        title: "Главная",
                        url: "/",
                    },
                    {
                        title: "Театры",
                        url: "",
                    },
                ]}
            />
            <section className={`${commonStyles.wrap} ${commonStyles.wrap_shadow}`}>
                <h2 className={commonStyles.title}>Театры</h2>
                {/*
                <div className={styles.map + " --hide"}>
                    <YMaps>
                        <Map
                            state={{
                                center: [55.755383, 37.573223],
                                zoom: 14,
                            }}
                            width='100%'
                            height='100%'
                        ></Map>
                    </YMaps>
                </div>
                */}
                <Theatres />
            </section>
        </motion.div>
    );
};

export default TheatresPage;

import React from 'react';
import {useNavigate} from "react-router-dom";

import useFestivalsStore from "../../../store/admin/festivalsStore";
import useAuthStore from "../../../store/authStore";

import Table from "../../../components/table/table.component";
import styles from "../../../components/search_filter/search.module.scss";
import Icon from "@mdi/react";
import {mdiMicrosoftExcel} from "@mdi/js";
import Button from "../../../components/button/button.component";
import {isArray} from "lodash";
import Swal from "sweetalert2";
import {utils, writeFile} from "xlsx";

const AdminFestivalRequestsPage = () => {
    const { user } = useAuthStore();
    const navigate = useNavigate();
    const festivalStore = useFestivalsStore();

    const onItemClick = (props) => {
        navigate(`/admin/festivalRequests/${props}`);
    };

    const exportToExcel = () => {
        const getColumnWidth = (data, column, title, minWidth = 50) => {
            const width = data.reduce((result, item) => {
                if (isArray(item[column]) || !item[column]) {
                    return result;
                }

                return Math.max(result, item[column].length);
            }, 5);

            if (isNaN(width))
                return Math.max(5, title.length);

            return Math.min(minWidth, Math.max(width, title.length));
        }

        Swal.fire({
            title: 'Пожалуйста подождите, отчет формируется...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
        });
        Swal.showLoading();

        setTimeout(async () => {
            const festivalRequests = await festivalStore.loadFestivalRequestsForXLSX({});

            const workBook = utils.book_new();
            const workSheet = utils.json_to_sheet(festivalRequests);

            utils.sheet_add_aoa(workSheet, [[
                "ID заявки",
                "Статус заявки",
                "Сезон заявки",
                "ОО",
                "Театр",
                "Постановка",
                "Кол-во уч.",
                "ФИО режиссера-постановщика",
                "Автор",
                "Название литературного материала",
                "Возрастная категория",
                "Номинация",
                "Продолжительность (мин)",
                "Ответы на вопросы",
                "Афиша",
                "Видео",
            ]], {origin: 'A1'});
            utils.book_append_sheet(workBook, workSheet, 'Школьная Мельпомена');

            workSheet["!cols"] = [
                {wch: getColumnWidth(festivalRequests, "ID", "ID заявки")},
                {wch: getColumnWidth(festivalRequests, "status", "Статус заявки")},
                {wch: getColumnWidth(festivalRequests, "performance_season", "Сезон заявки")},
                {wch: getColumnWidth(festivalRequests, "schoolTitle", "ОО")},
                {wch: getColumnWidth(festivalRequests, "theatreTitle", "Театр")},
                {wch: getColumnWidth(festivalRequests, "spectacleTitle", "Постановка")},
                {wch: getColumnWidth(festivalRequests, "performance_count", "Кол-во уч.")},
                {wch: getColumnWidth(festivalRequests, "performance_producer", "ФИО режиссера-постановщика")},
                {wch: getColumnWidth(festivalRequests, "performance_author", "Автор", 60)},
                {wch: getColumnWidth(festivalRequests, "performance_book", "Название литературного материала")},
                {wch: getColumnWidth(festivalRequests, "performance_age", "Возрастная категория")},
                {wch: getColumnWidth(festivalRequests, "performance_nomination", "Номинация")},
                {wch: getColumnWidth(festivalRequests, "performance_length", "Продолжительность (мин)")},
                {wch: getColumnWidth(festivalRequests, "performance_file", "Ответы на вопросы", 200)},
                {wch: getColumnWidth(festivalRequests, "poster", "Афиша", 150)},
                {wch: getColumnWidth(festivalRequests, "video_fragment", "Видео", 150)},
            ];

            writeFile(workBook, 'Заявки на фестивали Школьная Мельпомена.xlsx');

            Swal.close();
        }, 1500);
    };

    const fetchData = async () => {
        await festivalStore.loadFestivalRequests();
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    const itemConfig = [
        {
            header: "ID",
            key: "ID",
            type: "int",
            filter: "number",
            sorting: true,
        },
        {
            header: "Фестиваль",
            key: "festivalTitle",
            type: "string",
            filter: "string",
            sorting: true,
        },
        {
            header: "Направление",
            key: "directionTitle",
            type: "string",
            filter: "string",
            sorting: true,
        },
        {
            header: "Название театра",
            key: "theatreTitle",
            type: "string",
            filter: "string",
            sorting: true,
        },
        {
            header: "Название постановки",
            key: "spectacleTitle",
            type: "string",
            filter: "string",
            sorting: true,
        },
        {
            header: "Дата подачи",
            key: "create_time",
            type: "datetime",
            filter: "date",
            sorting: true,
        },
        {
            header: "Дата обновления",
            key: "update_time",
            type: "datetime",
            filter: "date",
            sorting: true,
        },
        {
            header: "Статус",
            key: "status",
            type: "string",
            filter: "select",
            sorting: true,
        },
    ];

    return (
        <Table
            title={"Таблица заявок на фестивали администратора" + user.ID}
            loading={festivalStore.loading}
            items={festivalStore.festivalRequests}
            itemsConfig={itemConfig}
            onItemClick={onItemClick}
            withFilter={true}
        >
            <Button
                type="button"
                size="small"
                extraClass={styles.button}
                text={""}
                aria-label={"Выгрузка в XLS"}
                onClick={exportToExcel}
            >
                <Icon path={mdiMicrosoftExcel} size={1}/>
            </Button>
        </Table>
    );
};

export default AdminFestivalRequestsPage;
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import moment from "moment";

import useAuthStore from "../../../store/authStore";
import useSpectaclesStore from "../../../store/admin/spectaclesStore";

import Notif from "../../../components/notif/notif.component";
import Button from "../../../components/button/button.component";
import FieldInput from "../../../components/field/field.input.component";
import MultiSelect from "../../../components/multi_select/multi_select.component";
import Table from "../../../components/table/table.component";

import commonStyles from "../../common.module.scss";
import { AdminIcons } from "../../../components/svgs";
import Score from "../../../components/score/score.component";

const AdminSpectacleSchedulePage = () => {
    let { id } = useParams();
    const navigate = useNavigate();
    const { register, handleSubmit, reset, control, setValue, getValues } = useForm();
    const scoreForm = useForm();

    const { user } = useAuthStore();
    const spectaclesStore = useSpectaclesStore();

    const [popup, setPopup] = React.useState(<></>);
    const [edit, setEdit] = React.useState(false);

    const fetchData = async () => {
        let spectacleSchedule;

        if (id) spectacleSchedule = await spectaclesStore.loadSpectacleSchedule({ id });

        // console.clear();
        // console.log(id);
        // console.log(spectacleSchedule);
        // console.log(spectacles);
    };

    React.useEffect(() => {
        if (spectaclesStore.error) {
            setPopup(
                <Notif
                    title='Ошибка!'
                    text={spectaclesStore.errorText}
                    opened={true}
                    onClose={() => {
                        spectaclesStore.clearErrorText();
                        setPopup(<></>);
                    }}
                />
            );
        }
    }, [spectaclesStore.error]);

    React.useEffect(() => {
        fetchData();
    }, [id]);

    const back = () => navigate("/admin/spectacleSchedules");

    const checkForComplete = (item) => {
        console.log(item);

        if (item.spectacleID === "") {
            setPopup(
                <Notif
                    title='Ошибка!'
                    text={"Выберите постановку"}
                    opened={true}
                    onClose={() => {
                        spectaclesStore.clearErrorText();
                        setPopup(<></>);
                    }}
                />
            );
            return false;
        }

        if (item.scheduleDate === "") {
            setPopup(
                <Notif
                    title='Ошибка!'
                    text={"Вы не заполнили поле: Дата и время"}
                    opened={true}
                    onClose={() => {
                        spectaclesStore.clearErrorText();
                        setPopup(<></>);
                    }}
                />
            );
            return false;
        }

        const date = moment(item.scheduleDate);
        const dateEnd = moment().add(30, "years");

        if (date.isAfter(dateEnd)) {
            setPopup(
                <Notif
                    title='Ошибка!'
                    text={"Вы ввели некорректную дату"}
                    opened={true}
                    onClose={() => {
                        spectaclesStore.clearErrorText();
                        setPopup(<></>);
                    }}
                />
            );
            return false;
        }

        if (item.scheduleAddress === "") {
            setPopup(
                <Notif
                    title='Ошибка!'
                    text={"Вы не заполнили поле: Место показа"}
                    opened={true}
                    onClose={() => {
                        spectaclesStore.clearErrorText();
                        setPopup(<></>);
                    }}
                />
            );
            return false;
        }

        if (item.scheduleType === "") {
            setPopup(
                <Notif
                    title='Ошибка!'
                    text={"Вы не заполнили поле: Тип показа"}
                    opened={true}
                    onClose={() => {
                        spectaclesStore.clearErrorText();
                        setPopup(<></>);
                    }}
                />
            );
            return false;
        }

        if (parseInt(item.schedulePlaceCount) === 0) {
            setPopup(
                <Notif
                    title='Ошибка!'
                    text={"Мест в зале должно быть больше 0"}
                    opened={true}
                    onClose={() => {
                        spectaclesStore.clearErrorText();
                        setPopup(<></>);
                    }}
                />
            );
            return false;
        }

        if (parseInt(item.scheduleTicketCount) > parseInt(item.schedulePlaceCount)) {
            setPopup(
                <Notif
                    title='Ошибка!'
                    text={"Мест в зале должно быть больше или равно доступным для бронирования"}
                    opened={true}
                    onClose={() => {
                        spectaclesStore.clearErrorText();
                        setPopup(<></>);
                    }}
                />
            );
            return false;
        }

        let ticketPeopleCount = 0;

        if (spectaclesStore.spectacleSchedule.tickets && spectaclesStore.spectacleSchedule.tickets.length > 0) {
            spectaclesStore.spectacleSchedule.tickets.map(
                (item) => (ticketPeopleCount += parseInt(item.people.length))
            );
        }

        if (ticketPeopleCount > parseInt(item.scheduleTicketCount)) {
            setPopup(
                <Notif
                    title='Ошибка!'
                    text={
                        "Мест для бронирования должно быть больше или равно забронированным билетам. Забронированных билетов: " +
                        ticketPeopleCount
                    }
                    opened={true}
                    onClose={() => {
                        spectaclesStore.clearErrorText();
                        setPopup(<></>);
                    }}
                />
            );
            return false;
        }

        return true;
    };

    const onEditSchedule = async (params) => {
        const data = getValues();

        let sendObject = { ...data };

        sendObject["id"] = id;
        sendObject["schoolID"] = user.schoolID;
        sendObject["scheduleType"] = sendObject.schedule_type.value;

        if (!checkForComplete(sendObject)) return;

        const result = await spectaclesStore.editSpectacleSchedule(sendObject);

        if (!result.error) {
            setPopup(
                <Notif
                    title=''
                    text={"Постановка успешно отредактирована"}
                    opened={true}
                    onClose={() => {
                        spectaclesStore.clearErrorText();
                        setPopup(<></>);
                        back();
                    }}
                />
            );
        }
    };

    const onDelete = async () => {
        setPopup(
            <Notif
                text={"Вы уверены что хотите удалить?"}
                opened={true}
                onClose={() => setPopup(<></>)}
                buttons={
                    <>
                        <Button
                            type='button'
                            size={"small"}
                            text={"Нет"}
                            theme='text'
                            onClick={() => setPopup(<></>)}
                        />
                        <Button
                            type='button'
                            size={"small"}
                            theme='info'
                            text={"Да"}
                            onClick={async () => {
                                let sendObject = {};

                                sendObject["id"] = id;
                                sendObject["archive"] = 1;

                                const result = await spectaclesStore.editSpectacleSchedule(sendObject);

                                if (!result.error) {
                                    setPopup(
                                        <Notif
                                            title=''
                                            text={"Событие успешно отправлено в архив"}
                                            opened={true}
                                            onClose={() => {
                                                spectaclesStore.clearErrorText();
                                                setPopup(<></>);
                                                back();
                                            }}
                                        />
                                    );
                                }
                            }}
                        />
                    </>
                }
            />
        );
    };

    const scoreSave = async (place, input) => {
        await spectaclesStore.editSpectacleScheduleScore({
            id,
            schoolID: spectaclesStore.spectacleSchedule.schoolID,
            theatreID: spectaclesStore.spectacleSchedule.theatreID,
            spectacleID: spectaclesStore.spectacleSchedule.spectacleID,
            place,
            score: input
        });
    };

    const itemConfig = [
        {
            header: "Телефон",
            key: "phone",
            type: "string",
            filter: "string",
            sorting: true,
        },
        {
            header: "Email",
            key: "email",
            type: "string",
            filter: "string",
            sorting: true,
        },
        {
            header: "Список гостей",
            key: "people",
            type: "array",
            filter: "array",
            arrayKey: "fio",
        },
    ];

    const onItemClick = (props) => {};

    if (spectaclesStore.loading)
        return (
            <div className={commonStyles.title_block}>
                <h1 className={commonStyles.title}>Загрузка...</h1>
            </div>
        );

    return (
        <>
            {/* Просмотр события */}
            {id &&
                !edit &&
                spectaclesStore.spectacleSchedule &&
                Object.keys(spectaclesStore.spectacleSchedule).length > 0 && (
                    <>
                        <div className={commonStyles.title_block}>
                            <Button
                                type='button'
                                theme='text'
                                size='small'
                                iconClass={"mdi mdi-arrow-left"}
                                isIconBtn={true}
                                aria-label='Назад'
                                onClick={back}
                            />
                            <h1 className={commonStyles.title}>Событие ID: {spectaclesStore.spectacleSchedule.ID}</h1>
                            {moment().isBefore(spectaclesStore.spectacleSchedule.date) && (
                                <Button
                                    size='smaller'
                                    type='submit'
                                    isIconBtn='true'
                                    iconClass={"mdi mdi-pencil"}
                                    theme='outline'
                                    aria-label='Редактировать событие'
                                    onClick={() => {
                                        setEdit(true);
                                    }}
                                />
                            )}
                        </div>
                        <p>Дата создания: {moment(spectaclesStore.spectacleSchedule.create_time).format("DD.MM.YYYY")}</p>
                        <Score
                            place={"screening"}
                            score={spectaclesStore.spectacleSchedule.score.screening}
                            getValues={scoreForm.getValues}
                            register={scoreForm.register}
                            scoreEdit={true}
                            saveScore={scoreSave}
                        />
                        <article className={commonStyles.spectacleCard}>
                            <p
                                className={`${commonStyles.spectacleCardElement} ${commonStyles.spectacleCardStatus}`}
                                aria-label='Тип показа'
                                title='Тип показа'
                            >
                                {AdminIcons.expand}
                                {spectaclesStore.spectacleSchedule.scheduleType}
                            </p>
                            <p
                                className={`${commonStyles.spectacleCardElement} ${commonStyles.spectacleCardCaption}`}
                                aria-label='Дата и время'
                                title='Дата и время'
                            >
                                {moment(spectaclesStore.spectacleSchedule.date).format("DD.MM.YYYY HH:mm")}
                            </p>
                            <div className={commonStyles.spectacleCardMainText}>
                                <h2 className={commonStyles.spectacleCardTitle}>
                                    {spectaclesStore.spectacleSchedule.spectacleTitle}
                                </h2>
                                <p className={commonStyles.spectacleCardSubtitle} aria-label='Театр' title='Театр'>
                                    {spectaclesStore.spectacleSchedule.theatreTitle}
                                </p>
                            </div>
                            <div className={commonStyles.spectacleCardElementsColumn}>
                                <p
                                    className={commonStyles.spectacleCardElement}
                                    aria-label='Адрес площадки'
                                    title='Адрес площадки'
                                >
                                    {AdminIcons.location}
                                    {spectaclesStore.spectacleSchedule.address}
                                </p>
                                <p
                                    className={commonStyles.spectacleCardElement}
                                    aria-label='Мест доступных для бронирования на сайте/Всего мест'
                                    title='Мест доступных для бронирования на сайте/Всего мест'
                                >
                                    {AdminIcons.tiket}
                                    {spectaclesStore.spectacleSchedule.ticketsCount}/
                                    {spectaclesStore.spectacleSchedule.placeCount}
                                </p>
                                <p className={`${commonStyles.spectacleCardElement}`} style={{ marginTop: "1.25em" }}>
                                    {spectaclesStore.spectacleSchedule.external === "Да"
                                        ? AdminIcons.success
                                        : AdminIcons.close}
                                    Доступно для внешних гостей
                                </p>
                                <p className={`${commonStyles.spectacleCardElement}`}>
                                    {spectaclesStore.spectacleSchedule.festival === "Да"
                                        ? AdminIcons.success
                                        : AdminIcons.close}
                                    В рамках фестивального показа в направлении “Школьная Мельпомена”
                                </p>
                            </div>
                        </article>

                        <h2 className={commonStyles.title}>Информация о билетах</h2>
                        <Table
                            title={"Таблица билетов постановки" + spectaclesStore.spectacleSchedule.ID}
                            loading={spectaclesStore.loading}
                            items={spectaclesStore.spectacleSchedule.tickets}
                            itemsConfig={itemConfig}
                            onItemClick={onItemClick}
                            withFilter={true}
                        />
                    </>
                )}
            {/* Не найден */}
            {id &&
                (!spectaclesStore.spectacleSchedule ||
                    (spectaclesStore.spectacleSchedule &&
                        Object.keys(spectaclesStore.spectacleSchedule).length === 0)) && (
                    <div className={commonStyles.title_block}>
                        <Button
                            type='button'
                            theme='text'
                            size='small'
                            iconClass={"mdi mdi-arrow-left"}
                            isIconBtn={true}
                            aria-label='Назад'
                            onClick={back}
                        />
                        <h1 className={commonStyles.title}>Событие не найдено</h1>
                    </div>
                )}
            {/* Редактировать событие */}
            {id && edit && (
                <>
                    <div className={commonStyles.title_block}>
                        <Button
                            type='button'
                            theme='text'
                            size='small'
                            iconClass={"mdi mdi-arrow-left"}
                            isIconBtn={true}
                            aria-label='Назад'
                            onClick={() => setEdit(false)}
                        />
                        <h1 className={commonStyles.title}>
                            Редактирование события ID: {spectaclesStore.spectacleSchedule.ID}
                        </h1>
                    </div>
                    <form onSubmit={handleSubmit(onEditSchedule)} className='form'>
                        <fieldset className='form__section'>
                            <FieldInput
                                label='Постановка'
                                layout='flex'
                                type='textarea'
                                placeholder='Введите адрес...'
                                disabled={true}
                                value={`${spectaclesStore.spectacleSchedule.spectacleTitle}. Театр: ${spectaclesStore.spectacleSchedule.theatreTitle}`}
                            />
                            <FieldInput
                                label='Дата и время'
                                type='datetime-local'
                                layout='flex'
                                required={true}
                                {...register("scheduleDate", {
                                    value: spectaclesStore.spectacleSchedule.date,
                                })}
                            />
                            <FieldInput
                                label='Место показа'
                                layout='flex'
                                type='textarea'
                                placeholder='Введите адрес...'
                                required={true}
                                {...register("scheduleAddress", {
                                    value: spectaclesStore.spectacleSchedule.address,
                                })}
                            />
                            <div className='form__multy-block'>
                                <p className='form__label'>Тип показа</p>
                                <MultiSelect
                                    required={true}
                                    control={control}
                                    isMulti={false}
                                    name={"schedule_type"}
                                    closeMenuOnSelect={true}
                                    options={[
                                        {
                                            label: "Показ на своей площадке",
                                            value: 0,
                                        },
                                        {
                                            label: "Показ на внешней площадке",
                                            value: 1,
                                        },
                                    ]}
                                    values={{
                                        label: spectaclesStore.spectacleSchedule.scheduleType,
                                        value:
                                            spectaclesStore.spectacleSchedule.scheduleType === "Показ на своей площадке"
                                                ? 0
                                                : 1,
                                    }}
                                />
                            </div>
                            <FieldInput
                                label='Кол-во мест в зале всего'
                                layout='flex'
                                type='number'
                                min={0}
                                required={true}
                                {...register("schedulePlaceCount", {
                                    value: spectaclesStore.spectacleSchedule.placeCount,
                                })}
                            />
                            <FieldInput
                                label='Кол-во мест доступных для бронирования на сайте'
                                layout='flex'
                                type='number'
                                min={0}
                                required={true}
                                {...register("scheduleTicketCount", {
                                    value: spectaclesStore.spectacleSchedule.ticketsCount,
                                })}
                            />
                            <FieldInput
                                label='Доступно для внешних гостей'
                                type='checkbox_variant'
                                {...register("scheduleExternal", {
                                    value: spectaclesStore.spectacleSchedule.external === "Да",
                                })}
                            />
                            <FieldInput
                                label='В рамках фестивального показа в направлении “Школьная Мельпомена”'
                                type='checkbox_variant'
                                {...register("scheduleFestival", {
                                    value: spectaclesStore.spectacleSchedule.festival === "Да",
                                })}
                            />
                        </fieldset>
                        <div className='form__controls'>
                            <Button
                                type='submit'
                                theme='primary'
                                text='Сохранить'
                                spinnerActive={spectaclesStore.sending}
                            />
                            <Button
                                type='button'
                                theme='text'
                                text='В архив'
                                onClick={onDelete}
                                spinnerActive={spectaclesStore.sending}
                            />
                            <Button
                                type='button'
                                theme='text'
                                text='Отмена'
                                onClick={() => {
                                    reset();
                                    setEdit(false);
                                }}
                                spinnerActive={spectaclesStore.sending}
                            />
                        </div>
                    </form>
                </>
            )}
            {popup}
        </>
    );
};

export default AdminSpectacleSchedulePage;

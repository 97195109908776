import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { motion } from "framer-motion";
import Cookies from "js-cookie";
import lodash from "lodash";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import useAuthStore from "../../../store/authStore";
import useScenarioBankStore from "../../../store/public/scenarioBankStore";

import Skeleton from "react-loading-skeleton";
import SearchFilter from "../../../components/search_filter/search.filter.component";
import Pagination from "../../../components/pagination/pagination.component";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumbs.component";

import styles from "./common.module.scss";
import commonStyles from "../common.module.scss";

import noTheatreImage1 from "../../../images/no_photo_theatre_type_1.jpg";
import noTheatreImage2 from "../../../images/no_photo_theatre_type_2.jpg";
import noTheatreImage3 from "../../../images/no_photo_theatre_type_3.jpg";

const ScenariosBankPage = () => {
    const navigate = useNavigate();

    const { user } = useAuthStore();
    const scenarioBankStore = useScenarioBankStore();

    const fetchData = async () => {
        await scenarioBankStore.loadAll({ auth: user.ID ? 1 : 0 });
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    // Private components
    const Scenarios = () => {
        const pageSize = 12;

        const [filtered, setFiltered] = React.useState([]);
        const [startIndex, setStartIndex] = React.useState(0);
        const [pageIndex, setPageIndex] = React.useState(0);
        const [paginatedItems, setPaginatedItems] = React.useState([]);
        const [pageCount, setPageCount] = React.useState(
            scenarioBankStore.scenarios ? Math.ceil(scenarioBankStore.scenarios.length / pageSize) : 0
        );

        const itemConfig = [
            {
                header: "Название",
                theme: "public",
                size: "normal",
                key: "title",
                type: "string",
                filter: "string",
            },
            {
                header: "Автор",
                theme: "public",
                size: "normal",
                key: "author",
                type: "string",
                filter: "string",
            },
            {
                header: "Автор адаптации",
                theme: "public",
                size: "normal",
                key: "adaptation_author",
                type: "string",
                filter: "string",
            },
            {
                header: "Жанр",
                theme: "public",
                size: "normal",
                key: "genres",
                type: "array",
                filter: "select",
            },
        ];

        React.useEffect(() => {
            setFiltered(scenarioBankStore.scenarios);
        }, [scenarioBankStore.scenarios]);

        React.useEffect(() => {
            setPageCount(Math.ceil(filtered.length / pageSize));
            setPaginatedItems(lodash(filtered).slice(startIndex).take(pageSize).value());
        }, [filtered, startIndex, pageSize]);

        React.useEffect(() => {
            if (Cookies.get("table_public_scenarios")) {
                handleChangePage(parseInt(Cookies.get("table_public_scenarios")));
            } else {
                handleChangePage(1);
            }
        }, []);

        const handleFilter = (filter) => {
            function checkItem(config, itemValue, filterValue, prop) {
                if (prop === "search_string") {
                    let tmpFilter = {};

                    for (const itemKey in itemValue)
                        if (itemValue[itemKey] !== null)
                            tmpFilter[itemKey] = !!itemValue[itemKey]
                                .toString()
                                .toLowerCase()
                                .includes(filterValue["search_string"].toLowerCase());

                    if (Object.keys(tmpFilter).some((key) => tmpFilter[key])) return true;
                }

                switch (config?.type) {
                    case "int":
                        return parseInt(itemValue[prop]) === parseInt(filterValue[prop]);

                    case "string":
                        return config.filter === "select" || config.filter === "multiselect"
                            ? itemValue[prop] === filterValue[prop]
                            : itemValue[prop].toLowerCase().includes(filterValue[prop].toLowerCase());

                    case "date":
                        if ("linkKey" in config) {
                            if ("dateFilter" in config && config.dateFilter === "to")
                                return moment(itemValue[config["linkKey"]]).isBefore(moment(filterValue[prop]));
                            if ("dateFilter" in config && config.dateFilter === "from")
                                return moment(itemValue[config["linkKey"]]).isAfter(moment(filterValue[prop]));
                        }

                        const date = moment(itemValue[prop]);

                        return moment(
                            `${date.year()}-${
                                date.month() <= 9 ? "0" + (date.month() + 1) : date.month() + 1
                            }-${date.date()}`
                        ).isSame(moment(filterValue[prop]));

                    case "datetime":
                        if ("linkKey" in config) {
                            if ("dateFilter" in config && config.dateFilter === "to")
                                return moment(itemValue[config["linkKey"]]).isBefore(moment(filterValue[prop]));
                            if ("dateFilter" in config && config.dateFilter === "from")
                                return moment(itemValue[config["linkKey"]]).isAfter(moment(filterValue[prop]));
                        }

                        const itemDate = moment(itemValue[prop]);
                        return moment({
                            year: itemDate.get("year"),
                            month: itemDate.get("month"),
                            day: itemDate.get("date"),
                        }).isSame(moment(filterValue[prop]));

                    case "array":
                        return itemValue[prop].some((item) => item === filterValue[prop]);

                    default:
                        if (itemValue[prop]) return itemValue[prop] === filterValue[prop];
                        else return false;
                }
            }

            if (filter) {
                let tmpArray = [];

                for (const item of scenarioBankStore.scenarios) {
                    let tmpFilter = {};

                    for (let prop in filter)
                        tmpFilter[prop] = !!(
                            filter[prop] === "" ||
                            filter[prop] === "Все" ||
                            checkItem(
                                itemConfig.find((itemConfig) => itemConfig.key === prop),
                                item,
                                filter,
                                prop
                            )
                        );

                    if (!Object.keys(tmpFilter).some((key) => !tmpFilter[key])) {
                        tmpArray.push(item);
                    }
                }

                setFiltered(tmpArray);
            } else setFiltered(scenarioBankStore.scenarios);

            handleChangePage(1);
        };

        const handleChangePage = (index) => {
            setStartIndex((index - 1) * pageSize);
            setPageIndex(index);

            Cookies.set("table_public_scenarios", index);
        };

        const onItemClick = (item) => {
            navigate(`/scenario_bank/${item.ID}`);
        };

        const getRandomEmptyImage = () => {
            const number = Math.floor(Math.random() * (4 - 1) + 1);

            switch (number) {
                case 1:
                    return noTheatreImage1;
                case 2:
                    return noTheatreImage2;
                case 3:
                    return noTheatreImage3;
            }
        };

        return (
            <section className={[commonStyles.wrap, commonStyles.wrap_bubble].join(" ")}>
                <h2 className={[commonStyles.title, commonStyles.title_underline].join(" ")}>Банк сценариев</h2>
                {scenarioBankStore.loading && (
                    <>
                        <Skeleton height={66} />
                        <Skeleton width={"25vmax"} height={40} style={{ margin: "1.875em auto", display: "block" }} />
                        <ul className={`${styles.cardDeck} ${styles.cardDeck_size_lg}`}>
                            {Array(3)
                                .fill()
                                .map((item, index) => (
                                    <li className={styles.cardPlay} key={index}>
                                        <Skeleton className={styles.cardPlayImg} />
                                        <Skeleton className={styles.cardPlayTitle} width={"60%"} />
                                        <Skeleton className={styles.cardPlaySubtitle} width={"80%"} />
                                        <div className={styles.cardPlayItems}>
                                            <Skeleton className={styles.cardPlayItem} count={6} width={"70%"} />
                                        </div>
                                    </li>
                                ))}
                        </ul>
                        <Skeleton width={"25vmax"} height={40} style={{ margin: "1.875em auto", display: "block" }} />
                    </>
                )}
                {!scenarioBankStore.loading && (
                    <>
                        <SearchFilter
                            front={true}
                            items={scenarioBankStore.scenarios}
                            config={itemConfig}
                            onSubmit={handleFilter}
                        />
                        <Pagination
                            pageCount={pageCount}
                            pageIndex={pageIndex}
                            setPageChangeCallback={handleChangePage}
                        />
                        <ul className={`${styles.cardDeck} ${styles.cardDeck_size_lg}`}>
                            {paginatedItems.map((item) => (
                                <li
                                    key={window.global.makeid(12)}
                                    className={styles.cardPlay}
                                    onClick={() => {
                                        onItemClick(item);
                                    }}
                                >
                                    {item.images.length > 1 && (
                                        <Splide
                                            className='my-splide my-splide_no-paddings'
                                            options={{
                                                type: "fade",
                                                arrows: false,
                                                rewind: true,
                                                pagination: false,
                                                autoplay: true,
                                            }}
                                        >
                                            {item.images.map((image) => (
                                                <SplideSlide key={window.global.makeid(12)} data-splide-interval='5000'>
                                                    <img
                                                        className={styles.cardPlayImg}
                                                        src={
                                                            image.url.includes("http")
                                                                ? image.url
                                                                : process.env.REACT_APP_BASE_URL + image.url
                                                        }
                                                        alt={"Изображение " + image.url}
                                                    />
                                                </SplideSlide>
                                            ))}
                                        </Splide>
                                    )}
                                    {item.images.length === 1 && (
                                        <img
                                            className={styles.cardPlayImg}
                                            src={
                                                item.images[0].url.includes("http")
                                                    ? item.images[0].url
                                                    : process.env.REACT_APP_BASE_URL + item.images[0].url
                                            }
                                            alt={"Изображение " + item.images[0].url}
                                        />
                                    )}
                                    {item.images.length === 0 && (
                                        <img
                                            className={styles.cardPlayImg}
                                            src={getRandomEmptyImage()}
                                            alt={"Изображение заглушки"}
                                        />
                                    )}
                                    <h3 className={styles.cardPlayTitle}>{item.title}</h3>
                                    <p className={styles.cardPlaySubtitle}>
                                        {item.author}
                                        {item.adaptation_author && `в адаптации ${item.adaptation_author}`}
                                    </p>
                                    <ul className={styles.cardPlayItems}>
                                        <li>
                                            <p className={styles.cardPlayItem}>
                                                Жанр:
                                                <span className={styles.cardPlayAccent}>{item.genres.join(", ")}</span>
                                            </p>
                                        </li>
                                        <li>
                                            <p className={styles.cardPlayItem}>
                                                Возраст аудитории:
                                                <span className={styles.cardPlayAccent}>
                                                    от {item.audience_age_from} лет
                                                </span>
                                            </p>
                                        </li>
                                        <li>
                                            <p className={styles.cardPlayItem}>
                                                Продолжительность:
                                                <span className={styles.cardPlayAccent}>{item.duration} мин</span>
                                            </p>
                                        </li>
                                        <li>
                                            <p className={styles.cardPlayItem}>
                                                Возраст актеров:
                                                <span className={styles.cardPlayAccent}>
                                                    {item.actor_from}-{item.actor_to} лет
                                                </span>
                                            </p>
                                        </li>
                                        <li>
                                            <p className={styles.cardPlayItem}>
                                                Роли:
                                                <span className={styles.cardPlayAccent}>{item.roles_count}</span>
                                            </p>
                                        </li>
                                    </ul>
                                </li>
                            ))}
                        </ul>
                        <Pagination
                            pageCount={pageCount}
                            pageIndex={pageIndex}
                            setPageChangeCallback={handleChangePage}
                        />
                    </>
                )}
            </section>
        );
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 0.2, duration: 1 }}
        >
            <Breadcrumbs
                items={[
                    {
                        title: "Главная",
                        url: "/",
                    },
                    {
                        title: "Банк сценариев",
                        url: "",
                    },
                ]}
            />
            <Scenarios />
        </motion.div>
    );
};

export default ScenariosBankPage;

import React from "react";
import { motion } from "framer-motion";

import useActivitiesStore from "../../../store/public/activitiesStore";

import Breadcrumbs from "../../../components/breadcrumbs/breadcrumbs.component";

import commonStyles from "../common.module.scss";
import styles from "./common.module.scss";

const MethodologicalSupportPage = () => {
    const activitiesStore = useActivitiesStore();

    const fetchData = async () => {
        await activitiesStore.loadMethodologicalSupports();
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 0.2, duration: 1 }}
        >
            <Breadcrumbs
                items={[
                    {
                        title: "Главная",
                        url: "/",
                    },
                    {
                        title: "Методическая поддержка",
                        url: "",
                    },
                ]}
            />
            <section className={[commonStyles.wrap, commonStyles.wrap_bubble].join(" ")}>
                <h2 className={[commonStyles.title, commonStyles.title_underline].join(" ")}>Методическая поддержка</h2>
                <div className={styles.section}>
                    <ul className={[styles.cardDeck, styles.cardDeck_size_lg].join(" ")}>
                        {activitiesStore.methodologicalSupports.map((item, index) => (
                            <li key={item.ID} className={`${styles.card} ${styles.card_type_methodological}`}>
                                <iframe className={styles.cardIframe} src={item.url}></iframe>
                                <h3 className={styles.cardTitle}>{item.title}</h3>
                                <p className={styles.cardText}>{item.text}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            </section>
        </motion.div>
    );
};

export default MethodologicalSupportPage;

import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import JoditEditor from "jodit-react";
import moment from "moment";
import {useForm} from "react-hook-form";

import useFestivalsStore from "../../../store/user/festivalsStore";
import useSpectaclesStore from "../../../store/user/spectaclesStore";

import Notif from "../../../components/notif/notif.component";
import Button from "../../../components/button/button.component";
import Accordion from "../../../components/accordion/accordion.component";

import Tabs from "../../../components/tabs/tabs.component";
import Tab from "../../../components/tabs/tab.component";
import FieldInput from "../../../components/field/field.input.component";
import MultiSelect from "../../../components/multi_select/multi_select.component";
import Editor from "../../../components/reach_editor/editor.component";
import ImageGallery from "../../../components/image_gallery/image.gallery.component";
import VideoPlayer from "../../../components/video_player/video.player.component";

import commonStyles from "../../common.module.scss";

const UserFestivalRequestPage = () => {
    let {id} = useParams();
    const navigate = useNavigate();
    const {register, handleSubmit, reset, control, setValue, getValues} = useForm();

    const festivalStore = useFestivalsStore();
    const spectaclesStore = useSpectaclesStore();

    const [popup, setPopup] = React.useState(<></>);
    const [edit, setEdit] = React.useState(false);

    const fetchData = async () => {
        const festivalRequest = await festivalStore.loadFestivalRequest({id});
        await spectaclesStore.loadSpectacle({id: festivalRequest.spectacleID});

        console.clear();
        console.log(festivalStore);
    };

    React.useEffect(() => {
        if (festivalStore.error) {
            setPopup(
                <Notif
                    title='Ошибка!'
                    text={festivalStore.errorText}
                    opened={true}
                    onClose={() => {
                        festivalStore.clearErrorText();
                        setPopup(<></>);
                    }}
                />
            );
        }
    }, [festivalStore.error]);

    React.useEffect(() => {
        fetchData();
    }, [id]);

    const back = () => navigate("/user/festivalRequests");

    const getStatusText = (value) => {
        switch (value) {
            case "Новая":
                return <p className='request-status --status-new'>Новая</p>;
            case "Принята":
                return <p className='request-status --status-accept'>Принята</p>;
            case "Отклонена":
                return <p className='request-status --status-decline'>Отклонена</p>;
            case "Отозвана":
                return <p className='request-status --status-callback'>Отозвана</p>;
            case "Рассмотрение":
                return <p className='request-status --status-review'>Рассмотрение</p>;
            default:
                return <p className='request-status --status-accept'>{value}</p>;
        }
    };

    const onAddSubmit = async (params) => {
        const data = getValues();

        let sendObject = {...data};

        console.log(sendObject);

        return;

        const result = await festivalStore.addFestivalRequest(sendObject);

        if (!result.error) {
            setPopup(
                <Notif
                    title=''
                    text={"Спасибо. Ваши данные сохранены, ожидайте рассмотрения заявки"}
                    opened={true}
                    onClose={() => {
                        festivalStore.clearErrorText();
                        setPopup(<></>);
                        back();
                    }}
                />
            );
        }
    };

    const onEditSubmit = async (params) => {
        params.id = id;
        params.status = 2;
        //params.theatreID = theatreRequest.theatreID;
        //params.schoolID = user.schoolID;

        return;

        const result = await festivalStore.editFestivalRequest(params);

        if (!result.error) {
            setPopup(
                <Notif
                    title=''
                    text={"Спасибо. Ваши данные сохранены, ожидайте рассмотрения заявки"}
                    opened={true}
                    onClose={() => {
                        festivalStore.clearErrorText();
                        setPopup(<></>);
                        back();
                    }}
                />
            );
        }
    };

    const onReSendSubmit = async (params) => {

        const result = await festivalStore.resendFestivalRequest({id});

        if (!result.error) {
            setPopup(
                <Notif
                    title=''
                    text={"Спасибо. Ваши данные сохранены, ожидайте рассмотрения заявки"}
                    opened={true}
                    onClose={() => {
                        festivalStore.clearErrorText();
                        setPopup(<></>);
                        back();
                    }}
                />
            );
        }
    };

    const onRevokeSubmit = async () => {
        setPopup(
            <Notif
                text={"Вы действительно хотите отозвать заявку?"}
                opened={true}
                onClose={() => {
                    setPopup(<></>);
                }}
                buttons={
                    <>
                        <Button type='button' text='Нет' size={"small"} theme='text' onClick={() => setPopup(<></>)}/>
                        <Button
                            type='button'
                            text='Да'
                            theme='info'
                            size={"small"}
                            onClick={async () => {
                                const result = await festivalStore.removeFestivalRequest({id});

                                if (!result.error) {
                                    setPopup(<></>);
                                    back();
                                }
                            }}
                        />
                    </>
                }
            />
        );
    };

    if (festivalStore.loading || spectaclesStore.loading) return <p>Загрузка...</p>;

    return (
        <>
            {id && festivalStore.festivalRequest && Object.keys(festivalStore.festivalRequest).length > 0 && (
                <>
                    <div className={commonStyles.title_block}>
                        <Button
                            type='button'
                            theme='text'
                            size='small'
                            iconClass={"mdi mdi-arrow-left"}
                            isIconBtn='true'
                            aria-label='Назад'
                            onClick={back}
                        />
                        <h1 className={commonStyles.title}>Заявка ID: {id}</h1>
                    </div>
                    <div className='request-card'>
                        <div className='request-card__section --content-main-info'>
                            <>{getStatusText(festivalStore.festivalRequest.status)}</>
                            {festivalStore.festivalRequest.decline_text !== "" && (
                                <Accordion title={"Комментарий"}>
                                    <JoditEditor
                                        config={{
                                            readonly: true,
                                            toolbar: false,
                                        }}
                                        value={festivalStore.festivalRequest.decline_text}
                                    />
                                </Accordion>
                            )}
                            <ul className='request-card__dates'>
                                <li>
                                    <p className='request-card__date'>
                                        Год проведения фестиваля (сезон):
                                        <span className='request-card__date-accent'>
                                            {" "}
                                            {festivalStore.festivalRequest.performance_season}
                                        </span>
                                    </p>
                                </li>
                                <li>
                                    <p className='request-card__date'>
                                        Дата подачи:
                                        <span className='request-card__date-accent'>
                                            {" "}
                                            {moment(festivalStore.festivalRequest.create_time).format(
                                                "HH:mm DD.MM.YYYY"
                                            )}
                                        </span>
                                    </p>
                                </li>
                                <li>
                                    <p className='request-card__date'>
                                        Дата обновления:
                                        <span className='request-card__date-accent'>
                                            {" "}
                                            {moment(festivalStore.festivalRequest.update_time).format(
                                                "HH:mm DD.MM.YYYY"
                                            )}
                                        </span>
                                    </p>
                                </li>
                            </ul>
                            <div>
                                <p>
                                    Фестиваль: <b>{festivalStore.festivalRequest.festivalTitle}</b>
                                </p>
                                <p>
                                    Направление: <b>{festivalStore.festivalRequest.directionTitle}</b>
                                </p>
                                <p>
                                    Театр: <b>{festivalStore.festivalRequest.theatreTitle}</b>
                                </p>
                                <br/>
                                {festivalStore.festivalRequest.spectacleTitle && (
                                    <p>
                                        Постановка: <b>{festivalStore.festivalRequest.spectacleTitle}</b>
                                    </p>
                                )}
                                {festivalStore.festivalRequest.performance_count > 0 && (
                                    <p>
                                        Количество участников творческого коллектива:{" "}
                                        <b>{festivalStore.festivalRequest.performance_count}</b>
                                    </p>
                                )}
                                {festivalStore.festivalRequest.performance_author && (
                                    <p>
                                        Автор: <b>{festivalStore.festivalRequest.performance_author}</b>
                                    </p>
                                )}
                                {festivalStore.festivalRequest.performance_book && (
                                    <p>
                                        Название литературного материала:{" "}
                                        <b>{festivalStore.festivalRequest.performance_book}</b>
                                    </p>
                                )}
                                {festivalStore.festivalRequest.performance_producer && (
                                    <p>
                                        ФИО режиссера-постановщика:{" "}
                                        <b>{festivalStore.festivalRequest.performance_producer}</b>
                                    </p>
                                )}
                                {festivalStore.festivalRequest.performance_age && (
                                    <p>
                                        Возрастная категория: <b>{festivalStore.festivalRequest.performance_age}</b>
                                    </p>
                                )}
                                {festivalStore.festivalRequest.performance_nomination && (
                                    <p>
                                        Номинация: <b>{festivalStore.festivalRequest.performance_nomination}</b>
                                    </p>
                                )}
                                {spectaclesStore.spectacle.duration > 0 && (
                                    <p>
                                        Планируемая продолжительность, минут:{" "}
                                        <b>{spectaclesStore.spectacle.duration}</b>
                                    </p>
                                )}
                                {festivalStore.festivalRequest.performance_file && (
                                    <p>
                                        Ответы на вопросы:{" "}
                                        <a
                                            target={"_blank"}
                                            href={
                                                process.env.REACT_APP_BASE_URL +
                                                festivalStore.festivalRequest.performance_file
                                            }
                                        >
                                            <b>Ссылка</b>
                                        </a>
                                    </p>
                                )}
                            </div>
                            <div className='request-card__controls'>
                                {(festivalStore.festivalRequest.status !== "Отозвана"
                                        && festivalStore.festivalRequest.status !== "Лауреат"
                                        && festivalStore.festivalRequest.status !== "Финалист"
                                        && festivalStore.festivalRequest.status !== "Победитель"
                                        && festivalStore.festivalRequest.status !== "Спецприз"
                                    )
                                    && (
                                        <Button
                                            type='button'
                                            theme={"outline"}
                                            text={"Отозвать"}
                                            onClick={onRevokeSubmit}
                                        />
                                    )}
                                {festivalStore.festivalRequest.status === "Отклонена" && (
                                    <Button
                                        type='button'
                                        text={"Отправить повторно"}
                                        onClick={onReSendSubmit}
                                    />
                                )}
                                {/*<Button*/}
                                {/*    type="button"*/}
                                {/*    extraClass={"request-card__btn"}*/}
                                {/*    text={"Редактировать"}*/}
                                {/*    onClick={() => setEdit(true)}*/}
                                {/*/>*/}
                            </div>
                        </div>
                        {festivalStore.festivalRequest.spectacleID !== 0 && (
                            <div className='request-card__section --content-video'>
                                <h2 className='request-card__subtitle'>Афиша</h2>
                                <ImageGallery items={spectaclesStore.spectacle.posters} front={false}/>
                                <h2 className='request-card__subtitle'>Видео фрагмент</h2>
                                {spectaclesStore.spectacle.videoFragment ? (
                                    <>
                                        {/* И этот тоже */}
                                        <ul className='gallery-form'>
                                            <li className='gallery-form__item'>
                                                <VideoPlayer source={spectaclesStore.spectacle.videoFragment}/>
                                            </li>
                                        </ul>
                                    </>
                                ) : (
                                    <>
                                        <p>Нет видео</p>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </>
            )}
            {id &&
                (!festivalStore.festivalRequest ||
                    (festivalStore.festivalRequest && Object.keys(festivalStore.festivalRequest).length === 0)) && (
                    <>
                        <div className={commonStyles.title_block}>
                            <Button
                                type='button'
                                theme='text'
                                size='small'
                                iconClass={"mdi mdi-arrow-left"}
                                isIconBtn={true}
                                aria-label='Назад'
                                onClick={back}
                            />
                            <h1 className={commonStyles.title}>Не удалось найти заявку № {id}</h1>
                        </div>
                    </>
                )}
            {!id && (
                <>
                    <div className={commonStyles.title_block}>
                        <Button
                            type='button'
                            theme='text'
                            size='small'
                            iconClass={"mdi mdi-arrow-left"}
                            isIconBtn={true}
                            aria-label='Назад'
                            onClick={back}
                        />
                        <h1 className={commonStyles.title}>Новая заявка на фестиваль</h1>
                    </div>
                    <form onSubmit={handleSubmit(onAddSubmit)} className='form'>
                        <Tabs>
                            <Tab title={"Основная информация"}>
                                <fieldset className='form__section'>
                                    <FieldInput
                                        label={"Название постановки*"}
                                        type='text'
                                        layout='flex'
                                        required={true}
                                        placeholder={"Введите название"}
                                        {...register("title")}
                                    />
                                    <div className='form__multy-block'>
                                        <p className='form__label'>Школьный театр*</p>
                                        <MultiSelect
                                            required={true}
                                            control={control}
                                            isMulti={false}
                                            name={"theatre_select"}
                                            closeMenuOnSelect={true}
                                        />
                                    </div>
                                    <div className='form__multy-block'>
                                        <p className='form__label'>Педагоги*</p>
                                        <MultiSelect
                                            required={true}
                                            control={control}
                                            isMulti={true}
                                            name={"teachers_select"}
                                            closeMenuOnSelect={false}
                                        />
                                    </div>
                                    <div className='form__multy-block'>
                                        <p className='form__label'>Возрастной состав участников спектакля*</p>
                                        <MultiSelect
                                            required={true}
                                            control={control}
                                            isMulti={true}
                                            name={"age_members_select"}
                                            closeMenuOnSelect={false}
                                            // options={theatresStore.ageMembers.map(
                                            //     (item) => {
                                            //         return {
                                            //             label: item,
                                            //             value: item,
                                            //         };
                                            //     }
                                            // )}
                                        />
                                    </div>
                                    <FieldInput
                                        label={"Планируемая продолжительность, минут*"}
                                        type='number'
                                        layout='flex'
                                        min={0}
                                        required={true}
                                        {...register("duration")}
                                    />
                                    <div className='form__multy-block'>
                                        <p className='form__label'>Описание</p>
                                        <Editor control={control} name='editorReview' minHeight={250}/>
                                    </div>
                                </fieldset>
                            </Tab>
                        </Tabs>
                        <div className='form__controls'>
                            <Button
                                type='submit'
                                theme='primary'
                                text='Сохранить'
                                spinnerActive={festivalStore.sending}
                            />
                            <Button
                                type='button'
                                theme='text'
                                text='Отмена'
                                onClick={back}
                                spinnerActive={festivalStore.sending}
                            />
                        </div>
                    </form>
                </>
            )}
            {popup}
        </>
    );
};

export default UserFestivalRequestPage;

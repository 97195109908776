import React from "react";
import {motion} from "framer-motion";
import moment from "moment";

import useActivitiesStore from "../../../store/public/activitiesStore";

import Breadcrumbs from "../../../components/breadcrumbs/breadcrumbs.component";
import Notif from "../../../components/notif/notif.component";
import Button from "../../../components/button/button.component";
import FieldInputComponent from "../../../components/field/field.input.component";
import Popup from "../../../components/popup/popup.component";

import commonStyles from "../common.module.scss";
import styles from "./common.module.scss";
import {useForm} from "react-hook-form";
import MultiSelect from "../../../components/multi_select/multi_select.component";

const ClubPage = () => {
    // Private components
    const ClubMeetings = () => {
        const activitiesStore = useActivitiesStore();

        const [popup, setPopup] = React.useState(<></>);

        const fetchData = async () => {
            await activitiesStore.loadCityClubMeetings();
        };

        React.useEffect(() => {
            fetchData();
        }, []);

        const OnRegisterClick = (meeting) => {
            setPopup(
                <Popup title={"Регистрация на встречу клуба"} opened={true} onClose={() => setPopup(<></>)}>
                    <OrderTicketsForm meeting={meeting}/>
                </Popup>
            );
        };

        const OrderTicketsForm = ({meeting}) => {
            const {register, handleSubmit, reset, control, setValue, getValues} = useForm();

            const [notif, setNotif] = React.useState(<></>);
            const [sending, setSending] = React.useState(false);
            const [people, setPeople] = React.useState(0);

            React.useEffect(() => {
                setValue("ticketsCount", 1);
                setPeople(1);
            }, []);

            const handleTicketCountChange = () => {
                const currentCount = parseInt(getValues("ticketsCount"));

                setPeople(currentCount > meeting.ticketsLeft ? meeting.ticketsLeft : currentCount);
            };

            const onSendRequest = async (params) => {
                setSending(true);

                let sendObject = {};

                sendObject["meetingID"] = meeting.ID;
                sendObject["title"] = meeting.title;
                sendObject["theme"] = meeting.theme;
                sendObject["address"] = meeting.address;
                sendObject["place"] = meeting.place;
                sendObject["bookingDate"] = moment().format("DD MMMM YYYY HH:mm");
                sendObject["date"] = [moment(meeting.datetime).format("DD"), moment(meeting.datetime).format("MMMM YYYY")];
                sendObject["time"] = moment(meeting.datetime).format("HH:mm");
                sendObject["ticketsCount"] = params.ticketsCount;
                sendObject["school"] = params.school;
                sendObject["position"] = params.position;
                sendObject["theatre"] = params.theatre_select.label;
                sendObject["email"] = params.email;
                sendObject["phone"] = params.phone;

                let people = [];
                for (let i = 0; i < params.ticketsCount; i++) {
                    if (params["fio_" + i] === "") {
                        setNotif(
                            <Notif
                                title='Ошибка'
                                text={`ФИО ${i + 1} гостя не заполнено`}
                                opened={true}
                                onClose={() => {
                                    setNotif(<></>);
                                }}
                            />
                        );

                        setSending(false);

                        return;
                    }

                    if (people.includes(params["fio_" + i])) {
                        setNotif(
                            <Notif
                                title='Ошибка'
                                text={`ФИО: ${
                                    params["fio_" + i]
                                } уже есть в списке. Пожалуйста удалите или замените данного гостя.`}
                                opened={true}
                                onClose={() => {
                                    setNotif(<></>);
                                }}
                            />
                        );

                        setSending(false);

                        return;
                    }

                    people.push(params["fio_" + i]);
                }

                sendObject["people"] = people;

                //console.log(sendObject);

                const result = await activitiesStore.addTicket(sendObject);

                if (!result.error) {
                    await fetchData();

                    setPopup(
                        <Notif
                            title='Спасибо за заявку!'
                            text={`${params.ticketsCount} ${window.global.declOfNum(params.ticketsCount, [
                                "билет",
                                "билета",
                                "билетов",
                            ])} успешно ${window.global.declOfNum(params.ticketsCount, [
                                "забронирован",
                                "забронированы",
                                "забронированы",
                            ])}. Письмо со списком билетов отправлено на указанную почту.`}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                } else {
                    setNotif(
                        <Notif
                            title='Ошибка'
                            text={`${result.errorText}`}
                            opened={true}
                            onClose={() => {
                                setNotif(<></>);
                            }}
                        />
                    );
                }

                setSending(false);
            };

            return (
                <>
                    <form className={commonStyles.form} onSubmit={handleSubmit(onSendRequest)}>
                        <p className={commonStyles.formTitle}>Осталось билетов: {meeting.ticketsLeft}</p>
                        <fieldset className={commonStyles.formFieldset}>
                            <div className={commonStyles.fieldTikets}>
                                <Button
                                    type='button'
                                    theme={"public_primary"}
                                    aria-label='Уменьшить'
                                    title='Уменьшить'
                                    isIconBtn={true}
                                    iconClass={"mdi mdi-minus"}
                                    disabled={sending}
                                    onClick={() => {
                                        const currentCount = parseInt(getValues("ticketsCount"));

                                        if (currentCount > 1) {
                                            setValue("ticketsCount", currentCount - 1);
                                            handleTicketCountChange();
                                        }
                                    }}
                                />
                                <FieldInputComponent
                                    extraClass={commonStyles.fieldTiketsInput}
                                    type={"number"}
                                    placeholder={"Количество билетов..."}
                                    required={true}
                                    disabled={sending}
                                    min={1}
                                    max={meeting.ticketsLeft}
                                    {...register("ticketsCount", {
                                        onBlur: () => {
                                            handleTicketCountChange();
                                        },
                                    })}
                                />
                                <Button
                                    type='button'
                                    theme={"public_primary"}
                                    aria-label='Увеличить'
                                    title='Увеличить'
                                    isIconBtn={true}
                                    disabled={sending}
                                    iconClass={"mdi mdi-plus"}
                                    onClick={() => {
                                        const currentCount = parseInt(getValues("ticketsCount"));

                                        if (currentCount < meeting.ticketsLeft) {
                                            setValue("ticketsCount", currentCount + 1);
                                            handleTicketCountChange();
                                        }
                                    }}
                                />
                            </div>
                            {[...Array(people)].map((item, index) => (
                                <FieldInputComponent
                                    key={index}
                                    type={"text"}
                                    autoComplete='off'
                                    extraClass={commonStyles.formRow}
                                    placeholder={"ФИО гостя № " + (index + 1) + "..."}
                                    disabled={sending}
                                    required={true}
                                    {...register("fio_" + index, {
                                        pattern: /^[A-Za-zА-Яа-яЁё\s]+$/i,
                                    })}
                                />
                            ))}
                            <FieldInputComponent
                                type={"textarea"}
                                extraClass={commonStyles.formRow}
                                placeholder={"Образовательная организация"}
                                required={true}
                                disabled={sending}
                                {...register("school")}
                            />
                            <FieldInputComponent
                                type={"text"}
                                placeholder={"Должность"}
                                required={true}
                                disabled={sending}
                                {...register("position")}
                            />
                            <div className='form__multy-block'>
                                {/*<p className='form__label'>Наличие школьного театра *</p>*/}
                                <MultiSelect
                                    placeholder={"Наличие школьного театра?"}
                                    required={true}
                                    control={control}
                                    isMulti={false}
                                    name={"theatre_select"}
                                    closeMenuOnSelect={true}
                                    options={[
                                        {
                                            label: "Да",
                                            value: 1,
                                        },
                                        {
                                            label: "Нет",
                                            value: 2,
                                        }
                                    ]}
                                />
                            </div>
                            <FieldInputComponent
                                type={"email"}
                                placeholder={"Email"}
                                required={true}
                                disabled={sending}
                                {...register("email")}
                            />
                            <FieldInputComponent
                                type={"tel"}
                                placeholder={"Телефон"}
                                required={true}
                                disabled={sending}
                                {...register("phone")}
                            />
                            <Button
                                type='submit'
                                theme='public_primary'
                                extraClass={commonStyles.formRow}
                                text={"Оставить заявку"}
                                spinnerActive={sending}
                            />
                        </fieldset>
                    </form>
                    {notif}
                </>
            );
        };

        const getStatus = (status) => {
            switch (status) {
                case 2:
                    return "Регистрация идет";
                case 3:
                    return "Регистрация завершена";
                default:
                    return "Регистрация скоро начнется";
            }
        };

        return (
            <>
                {activitiesStore.cityClubMeetings.length > 0 && (
                    <div className={styles.section}>
                        <h3 className={styles.title}>Тематики ближайших встреч Клуба</h3>
                        <ul className={styles.cardDeck}>
                            {activitiesStore.cityClubMeetings.map((item, index) => (
                                <li
                                    key={window.global.makeid(8)}
                                    className={styles.card}
                                >
                                    <h4 className={styles.cardTitle}>{item.title}</h4>
                                    <ul className={styles.cardList}>
                                        {
                                            item.meeting_type !== ""
                                            &&
                                            <li>
                                                Тип встречи:{" "} <span
                                                className={styles.cardSpanAccent}>«{item.meeting_type}»</span>
                                            </li>
                                        }
                                        <li>
                                            Дата проведения:{" "}
                                            <span className={styles.cardSpanAccent}>
                                                {moment(item.datetime).format("DD.MM.YYYY")}
                                            </span>
                                        </li>
                                        <li>
                                            Тема клуба: <span className={styles.cardSpanAccent}>«{item.theme}»</span>
                                        </li>
                                        <li>
                                            Продолжительность:{" "}
                                            <span className={styles.cardSpanAccent}>{item.duration}</span>
                                        </li>
                                        <li>
                                            Время начала:{" "}
                                            <span className={styles.cardSpanAccent}>
                                                {moment(item.datetime).format("HH:mm")}
                                            </span>
                                        </li>
                                        <li>
                                            Площадка: <span className={styles.cardSpanAccent}>{item.place}</span>
                                        </li>
                                        <li>
                                            Адрес: <span className={styles.cardSpanAccent}>{item.address}</span>
                                        </li>
                                    </ul>
                                    {item.status === 2 ? (
                                        <>
                                            {
                                                item.external === 1 ?
                                                    (
                                                        <p
                                                            className={styles.cardLink}
                                                            onClick={() => {
                                                                window.open(item.external_link, "_blank");
                                                            }}
                                                        >
                                                            Регистрация
                                                        </p>
                                                    ) :
                                                    (
                                                        <p
                                                            className={styles.cardLink}
                                                            onClick={() => {
                                                                OnRegisterClick(item);
                                                            }}
                                                        >
                                                            Регистрация <br/> (доступно {item.ticketsLeft}{" "}
                                                            {window.global.declOfNum(item.ticketsLeft, ["билет", "билета", "билетов"])})
                                                        </p>
                                                    )
                                            }
                                        </>
                                    ) : (
                                        <p className={`${styles.cardLink} ${styles.cardLink_closed}`}>
                                            {getStatus(item.status)}
                                        </p>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                {popup}
            </>
        );
    };

    return (
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{delay: 0.2, duration: 1}}
        >
            <Breadcrumbs
                items={[
                    {
                        title: "Главная",
                        url: "/",
                    },
                    {
                        title: "Московская Мастерская Театральной Педагогики",
                        url: "",
                    },
                ]}
            />
            <section className={[commonStyles.wrap, commonStyles.wrap_bubble].join(" ")}>
                <h2 className={[commonStyles.title, commonStyles.title_underline].join(" ")}>
                    Московская Мастерская Театральной Педагогики
                </h2>
                <div className={styles.section}>
                    <h3 className={styles.title}>О Клубе</h3>
                    <p className={styles.text}>
                        Городской клуб «Московская Мастерская Театральной Педагогики» создан в рамках реализации проекта
                        «Школьный театр» с целью расширения возможностей педагогов московских школ получить специальные
                        театральные знания и практические навыки для профессиональной педагогической работы, связанной с
                        различными формами и жанрами сценических искусств. А также для адаптации театральных методик и
                        технологий к условиям общеобразовательной школы и освоения их школьными учителями.
                    </p>
                </div>
                <div className={styles.section}>
                    <h3 className={styles.title}>Участники Клуба</h3>
                    <p className={styles.text}>
                        К участию в работе Мастерской приглашаются педагоги дополнительного образования, педагоги —
                        организаторы и руководители школьных театральных студий, кружков и творческих проектов,
                        использующие в педагогической работе различные формы театрализации.
                    </p>
                </div>
                <div className={styles.section}>
                    <h3 className={styles.title}>Программа Клуба</h3>
                    <p className={styles.text}>
                        В рамках работы Клуба «Мастерская», проводятся мастер-классы, встречи с деятелями культуры,
                        актерами, режиссерами. Посещаются сценические площадки московских театров. Члены клуба имеют
                        возможность принять участие в выездных мастер-классах, организованных в рамках деятельности
                        Клуба.
                    </p>
                </div>
                <div className={styles.section}>
                    <h3 className={styles.title}>Примерная тематика встреч:</h3>
                    <ol className={styles.text}>
                        <li>Театральное искусство и учебно-воспитательные задачи школы</li>
                        <li>
                            Формирование и эффективное использование в педагогической работе Театрального
                            Образовательного Пространства школ
                        </li>
                        <li>Особенности театрализации в школе и основы режиссуры</li>
                        <li>Выбор репертуара школьного театра и действенный анализ текста</li>
                        <li>Что такое актерское мастерство и как ему обучать школьников</li>
                        <li>Музыкальное оформление сценического действия</li>
                        <li>Проведение и организация занятий сценического движения в школе</li>
                        <li>Костюм, бутафория и реквизит силами ресурсами школы</li>
                        <li>Опыт публичного выступления и сценическая речь, как универсальный учебный навык</li>
                        <li>
                            Главные правила и реальные возможности художественного оформления сценического действия
                            силами школы
                        </li>
                        <li>Световая партитура и ее возможности в условиях школьных помещений</li>
                        <li>Художественная целостность любого сценического действия</li>
                    </ol>
                </div>
                <ClubMeetings/>
            </section>
        </motion.div>
    );
};

export default ClubPage;

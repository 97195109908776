import React from "react";
import { useNavigate } from "react-router-dom";

import useAuthStore from "../../../store/authStore";
import useSpectaclesStore from "../../../store/admin/spectaclesStore";

import Table from "../../../components/table/table.component";

const SpectaclesPage = () => {
    const navigate = useNavigate();

    const { user } = useAuthStore();
    const { loading, spectacles, loadSpectacles } = useSpectaclesStore();

    const onItemClick = (props) => {
        navigate(`/admin/spectacles/${props}`);
    };

    const fetchData = async () => {

        await loadSpectacles({schoolID: user.schoolID});

    };

    React.useEffect(() => {
        fetchData();
    }, []);

    const itemConfig = [
        {
            header: "ID",
            key: "ID",
            type: "int",
            filter: "number",
            sorting: true,
        },
        {
            header: "Школа",
            key: "schoolTitle",
            type: "string",
            filter: "string",
            sorting: true,
        },
        {
            header: "Театр",
            key: "theatreTitle",
            type: "string",
            filter: "string",
            sorting: true,
        },
        {
            header: "Спектакль",
            key: "title",
            type: "string",
            filter: "string",
            sorting: true,
        },
        {
            header: "Проверить баллы",
            key: "score_approved",
            type: "string",
            filter: "select",
            sorting: true,
        },
        {
            header: "Баллы за сезон 1",
            key: "score_season1",
            type: "int",
            filter: "number",
            sorting: true,
        },
        {
            header: "Баллы за сезон 2",
            key: "score_season2",
            type: "int",
            filter: "number",
            sorting: true,
        },
        {
            header: "Баллы за сезон 3",
            key: "score_season3",
            type: "int",
            filter: "number",
            sorting: true,
        },
        {
            header: "Статус",
            key: "active",
            type: "string",
            filter: "select",
            sorting: true,
        },
        {
            header: "Дата обновления",
            key: "update_time",
            type: "datetime",
            filter: "date",
            sorting: true,
        },
    ];

    return (
        <>
            <Table
                title={"Таблица постановок администратора" + user.ID}
                loading={loading}
                items={spectacles}
                itemsConfig={itemConfig}
                onItemClick={onItemClick}
                withFilter={true}
            />
        </>
    );
};

export default SpectaclesPage;

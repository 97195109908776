import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import useSchoolStore from "../../../store/admin/schoolsStore";
import useTheatresStore from "../../../store/user/theatresStore";
import useTeachersStore from "../../../store/admin/teachersStore";

import Theatre from "../../../components/page_components/theatre/theatre.component";

const TheatrePage = () => {
    let { id } = useParams();
    const navigate = useNavigate();

    const schoolStore = useSchoolStore();
    const {
        theatre,
        theatreScore,
        loadTheatre,
        loadTheatreScore,
        loading,
    } = useTheatresStore();
    const teachersStore = useTeachersStore();

    React.useEffect(() => {
        const fetchData = async () => {
            if (id) {
                let tempTheatre = await loadTheatre({ id });

                if (tempTheatre) {
                    await schoolStore.loadSchool({ id: tempTheatre.schoolID });
                    await teachersStore.loadTeachers({
                        schoolID: tempTheatre.schoolID,
                    });
                    await fetchScore();
                }
            }
        };
        const fetchScore = async () => {
            let score = await loadTheatreScore({ id });
        };

        fetchData();
    }, [id]);

    const back = () => navigate("/user/theatres/");

    if (loading || schoolStore.loading || teachersStore.loading)
        return <p>Загрузка...</p>;

    if (id && theatre === null) {
        return <p>Театр не найден</p>;
    }

    return (
        <>
            {id && theatre && (
                <Theatre
                    id={id}
                    theatre={theatre}
                    teachersStore={teachersStore}
                    onBack={back}
                    onEdit={() => navigate("/user/theatres/edit/" + id)}
                    totalScore={theatreScore}
                />
            )}
        </>
    );
};

export default TheatrePage;

import React from "react";
import { motion } from "framer-motion";

import Breadcrumbs from "../../../components/breadcrumbs/breadcrumbs.component";

import commonStyles from "../common.module.scss";
import styles from "./common.module.scss";

const MethodologicalSupportPage = () => {
    const manuals = [
        {
            title: "Методические рекомендации по проведению тематического урока по мотивам рок-оратории «Молодая Гвардия. Чёрный январь 1943 года» в общеобразовательных организациях Российской Федерации",
            url:
                process.env.REACT_APP_BASE_URL +
                "docs/methodological recommendations/methodological support/metodicheskie-rekomendacii-po-provedeniju-tematicheskogo-uroka-po-motivam-r....pdf",
        },
        {
            title: "Методические рекомендации по созданию пластического этюда по мотивам рок-оратории «Молодая Гвардия. Чёрный январь 1943 года» в общеобразовательных организациях Российской Федерации",
            url:
                process.env.REACT_APP_BASE_URL +
                "docs/methodological recommendations/methodological support/metodicheskie-rekomendacii-po-sozdaniju-plasticheskogo-jetjuda-po-motivam-rok....pdf",
        },
        {
            title: "Методические рекомендации по созданию театрализованного представления (спектакля) по мотивам рок-оратории «Молодая Гвардия. Чёрный январь 1943 года» в общеобразовательных организациях Российской Федерации",
            url:
                process.env.REACT_APP_BASE_URL +
                "docs/methodological recommendations/methodological support/metodicheskie-rekomendacii-po-sozdaniju-teatralizovannogo-predstavlenija-s....pdf",
        },
        {
            title: "Методические рекомендации по воспроизведению рок-оратории «Молодая гвардгвардия. Черный январь 1943 года» в общеобразовательных организациях Российской Федерации.",
            url:
                process.env.REACT_APP_BASE_URL +
                "docs/methodological recommendations/methodological support/metodicheskie-rekomendacii-po-vosproizvedeniju-rok-oratorii-molodaja-gvard....pdf",
        },
        {
            title: "Методические рекомендации по проведению концерта по мотивам рок-оратории «Молодая Гвардия. Чёрный январь 1943 года» в общеобразовательных организациях Российской Федерации",
            url:
                process.env.REACT_APP_BASE_URL +
                "docs/methodological recommendations/methodological support/metodicheskie-rekomendacii-po-provedeniju-koncerta-po-motivam-rok-oratorii....pdf",
        },
    ];
    const programms = [
        {
            title: "Методические рекомендации по созданию в общеобразовательных организациях школьных театров",
            url:
                process.env.REACT_APP_BASE_URL +
                "docs/methodological recommendations/methodological support/metodicheskie-rekomendatsii-shkolnye-teatry.pdf",
        },
        {
            title: "Программа внеурочной деятельности. Театральная педагогика",
            url:
                process.env.REACT_APP_BASE_URL +
                "docs/methodological recommendations/methodological support/programma-vneurochnoj-dejatelnosti_teatralnaja-pedagogika.pdf",
        },
        {
            title: "Учебно-методическое пособие для руководителей школьных театров",
            url:
                process.env.REACT_APP_BASE_URL +
                "docs/methodological recommendations/methodological support/uchebno-metodicheskoe-posobie-dlja-rukovoditelej-shkolnyh-teatrov.pdf",
        },
    ];
    const types_programms = [
        {
            title: "Типовая программа «Школьный театр» (12-14 лет)",
            url:
                process.env.REACT_APP_BASE_URL +
                "docs/methodological recommendations/typical programs/teatralnaja-studija-v-shkole-2-red..docx",
        },
        {
            title: "Типовая программа «Школьный театр» (15-17 лет)",
            url:
                process.env.REACT_APP_BASE_URL +
                "docs/methodological recommendations/typical programs/tipovaja-programma-shkolnyj-teatr-15-17-let.docx",
        },
        {
            title: "Типовая программа «Школьный театр» (11-17 лет)",
            url:
                process.env.REACT_APP_BASE_URL +
                "docs/methodological recommendations/typical programs/tipovaja-programma_shkolnyi-teatr__.docx",
        },
        {
            title: "Типовая программа «Школьный театр» (7-10 лет)",
            url:
                process.env.REACT_APP_BASE_URL +
                "docs/methodological recommendations/typical programs/primernaja-dopolnitelnaja-obshherazvivajushhaja-programma_teart-v-shkole_7-10-le....docx",
        },
    ];
    const provisions = [
        {
            title: "ПОЛОЖЕНИЕ о проведении Московского городского фестиваля-конкурса школьных театров «Живая сцена»",
            url:
                process.env.REACT_APP_BASE_URL +
                "docs/methodological recommendations/provisions/Положение Живая сцена.pdf",
        },
    ];
    const meeting = [
        {
            title: "Презентация",
            url:
                process.env.REACT_APP_BASE_URL +
                "docs/methodological recommendations/information/Презентация. Живая сцена.pdf",
        },
        {
            title: "Видеоролик",
            url: "https://disk.yandex.ru/d/PyLkyBwnSvJTdQ",
        },
        {
            title: "Школьный театр в вопросах и ответах",
            url:
                process.env.REACT_APP_BASE_URL +
                "docs/methodological recommendations/information/voprosy_shkolnyj-teatr.pdf",
        },
    ];
    const videos = [
        {
            title: "Портнягин Алексей «Как режиссёру школьного театра интерпретировать текст»",
            url: "https://disk.yandex.ru/d/uhVWqtkl67Vi1Q/mclass.mp4",
        },
        {
            title: "Несвяченая Ксения «Выбор литературной основы для спектакля»",
            url: "https://disk.yandex.ru/d/uhVWqtkl67Vi1Q/mclass%201.mp4",
        },
    ];
    const plays = [
        {
            title: "для 1-4 классов",
            url: process.env.REACT_APP_BASE_URL + "docs/methodological recommendations/bank of plays/1-4.rar",
        },
        {
            title: "для 5-7 классов",
            url: process.env.REACT_APP_BASE_URL + "docs/methodological recommendations/bank of plays/5-7.rar",
        },
        {
            title: "для 8-11 классов",
            url: process.env.REACT_APP_BASE_URL + "docs/methodological recommendations/bank of plays/8-11.rar",
        },
    ];
    const instructions = [
        {
            title: "1. Размещение информации о театре",
            url:
                process.env.REACT_APP_BASE_URL +
                "docs/methodological recommendations/instructions/1. Размещение информации о театре.mp4",
        },
        {
            title: "2. Редактирование информации о театре",
            url:
                process.env.REACT_APP_BASE_URL +
                "docs/methodological recommendations/instructions/2. Редактирование информации о театре.mp4",
        },
        {
            title: "3. Размещение театральной постановки",
            url:
                process.env.REACT_APP_BASE_URL +
                "docs/methodological recommendations/instructions/3. Размещение театральной постановки.mp4",
        },
        {
            title: "4. Расписание показов постановок",
            url:
                process.env.REACT_APP_BASE_URL +
                "docs/methodological recommendations/instructions/4. Расписание показов постановок.mp4",
        },
        {
            title: "5. Подача заявок на фестиваль-конкурс Живая сцена",
            url:
                process.env.REACT_APP_BASE_URL +
                "docs/methodological recommendations/instructions/5. Подача заявок на фестиваль-конкурс Живая сцена.mp4",
        },
        {
            title: "6. Благосклонная Талия. Видеовизитка школьного театра",
            url:
                process.env.REACT_APP_BASE_URL +
                "docs/methodological recommendations/instructions/6. Благосклонная Талия. Видеовизитка школьного театра.mp4",
        },
        {
            title: "7. Благосклонная Талия. Страницы театра в социальных сетях",
            url:
                process.env.REACT_APP_BASE_URL +
                "docs/methodological recommendations/instructions/7. Благосклонная Талия. Страницы театра в социальных сетях.mp4",
        },
        {
            title: "8. Благосклонная Талия. Страница театра на сайте образовательной организации",
            url:
                process.env.REACT_APP_BASE_URL +
                "docs/methodological recommendations/instructions/8. Благосклонная Талия. Страница театра на сайте образовательной организации.mp4",
        },
        {
            title: "9. Благосклонная Талия. Размещение видеоприглашений на спектакли театра",
            url:
                process.env.REACT_APP_BASE_URL +
                "docs/methodological recommendations/instructions/9. Благосклонная Талия. Размещение видеоприглашений на спектакли театра.mp4",
        },
        {
            title: "10. Благосклонная Талия. Размещение афиши и программки спектакля",
            url:
                process.env.REACT_APP_BASE_URL +
                "docs/methodological recommendations/instructions/10. Благосклонная Талия. Размещение афиши и программки спектакля.mp4",
        },
        {
            title: "11. Благосклонная Талия. Галерея фотографий спектаклей",
            url:
                process.env.REACT_APP_BASE_URL +
                "docs/methodological recommendations/instructions/11. Благосклонная Талия. Галерея фотографий спектаклей.mp4",
        },
        {
            title: "12. Благосклонная Талия. Количество показов на собственной площадке",
            url:
                process.env.REACT_APP_BASE_URL +
                "docs/methodological recommendations/instructions/12. Благосклонная Талия. Количество показов на собственной площадке.mp4",
        },
        {
            title: "13. Благосклонная Талия. Коллективное посещения музея или мастер-класса",
            url:
                process.env.REACT_APP_BASE_URL +
                "docs/methodological recommendations/instructions/13. Благосклонная Талия. Коллективное посещения музея или мастер-класса.mp4",
        },
        {
            title: "14. Благосклонная Талия. Коллективное посещение профессионального театра",
            url:
                process.env.REACT_APP_BASE_URL +
                "docs/methodological recommendations/instructions/14. Благосклонная Талия. Коллективное посещение профессионального театра.mp4",
        },
        {
            title: "15. Благосклонная Талия. Информационные материалы о школьном театре",
            url:
                process.env.REACT_APP_BASE_URL +
                "docs/methodological recommendations/instructions/15. Благосклонная Талия. Информационные материалы о школьном театре.mp4",
        },
        {
            title: "16. Благосклонная Талия. Выездной показ спектаклей на внешних площадках",
            url:
                process.env.REACT_APP_BASE_URL +
                "docs/methodological recommendations/instructions/16. Благосклонная Талия. Выездной показ спектаклей на внешних площадках.mp4",
        },
        {
            title: "17. Благосклонная Талия. Победы в сторонних конкурсах и фестивалях",
            url:
                process.env.REACT_APP_BASE_URL +
                "docs/methodological recommendations/instructions/17. Благосклонная Талия. Победы в сторонних конкурсах и фестивалях.mp4",
        },
        {
            title: "18. Благосклонная Талия. Проведение собственного театрального фестиваля внутри ОО",
            url:
                process.env.REACT_APP_BASE_URL +
                "docs/methodological recommendations/instructions/18. Благосклонная Талия. Проведение собственного театрального фестиваля внутри ОО.mp4",
        },
    ];

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 0.2, duration: 1 }}
        >
            <Breadcrumbs
                items={[
                    {
                        title: "Главная",
                        url: "/",
                    },
                    {
                        title: "Методическая поддержка",
                        url: "",
                    },
                ]}
            />
            <section className={[commonStyles.wrap, commonStyles.wrap_bubble].join(" ")}>
                <h2 className={[commonStyles.title, commonStyles.title_underline].join(" ")}>Методическая поддержка</h2>
                <div className={styles.section}>
                    <h3 className={styles.title}>
                        Федеральные методические рекомендации
                        <span>
                            * Методические рекомендации по воспроизведению рок-оратории «Молодая гвардия. Черный январь
                            1943 года»
                        </span>
                    </h3>
                    <ul className={[styles.cardDeck, styles.cardDeck_size_lg].join(" ")}>
                        {manuals.map((item, index) => (
                            <li className={[styles.card, styles.card_type_docs].join(" ")}>
                                <h4 className={[styles.cardTitle, styles.cardTitle_size_sm].join(" ")}>{item.title}</h4>
                                <a
                                    className={styles.cardLink}
                                    href={item.url}
                                    target={"_blank"}
                                    rel={"noopener nofollow noreferrer"}
                                >
                                    Скачать
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={styles.section}>
                    <ul className={[styles.cardDeck, styles.cardDeck_size_lg].join(" ")}>
                        {programms.map((item, index) => (
                            <li className={[styles.card, styles.card_type_docs].join(" ")}>
                                <h4 className={[styles.cardTitle, styles.cardTitle_size_lg].join(" ")}>{item.title}</h4>
                                <a
                                    className={styles.cardLink}
                                    href={item.url}
                                    target={"_blank"}
                                    rel={"noopener nofollow noreferrer"}
                                >
                                    Скачать
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={styles.section}>
                    <h3 className={styles.title}>
                        Типовые дополнительные общеразвивающие <br /> программы по направлению «Школьный театр»
                    </h3>
                    <ul className={[styles.cardDeck, styles.cardDeck_size_lg].join(" ")}>
                        {types_programms.map((item, index) => (
                            <li className={[styles.card, styles.card_type_docs].join(" ")}>
                                <h4 className={[styles.cardTitle, styles.cardTitle_size_lg].join(" ")}>{item.title}</h4>
                                <a
                                    className={styles.cardLink}
                                    href={item.url}
                                    target={"_blank"}
                                    rel={"noopener nofollow noreferrer"}
                                >
                                    Скачать
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={styles.section}>
                    <h3 className={styles.title}>Положения</h3>
                    <ul className={[styles.cardDeck, styles.cardDeck_size_lg].join(" ")}>
                        {provisions.map((item, index) => (
                            <li className={[styles.card, styles.card_type_docs].join(" ")}>
                                <h4 className={[styles.cardTitle, styles.cardTitle_size_lg].join(" ")}>{item.title}</h4>
                                <a
                                    className={styles.cardLink}
                                    href={item.url}
                                    target={"_blank"}
                                    rel={"noopener nofollow noreferrer"}
                                >
                                    Скачать
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={styles.section}>
                    <h3 className={styles.title}>
                        Информация с установочных совещаний <br /> по реализации проекта «Школьный театр»
                    </h3>
                    <ul className={[styles.cardDeck, styles.cardDeck_size_lg].join(" ")}>
                        {meeting.map((item, index) => (
                            <li className={[styles.card, styles.card_type_docs].join(" ")}>
                                <h4 className={[styles.cardTitle, styles.cardTitle_size_lg].join(" ")}>{item.title}</h4>
                                <a
                                    className={styles.cardLink}
                                    href={item.url}
                                    target={"_blank"}
                                    rel={"noopener nofollow noreferrer"}
                                >
                                    Скачать
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={styles.section}>
                    <h3 className={styles.title}>Видеоуроки</h3>
                    <ul className={[styles.cardDeck, styles.cardDeck_size_lg].join(" ")}>
                        {videos.map((item, index) => (
                            <li className={[styles.card, styles.card_type_docs].join(" ")}>
                                <h4 className={[styles.cardTitle, styles.cardTitle_size_lg].join(" ")}>{item.title}</h4>
                                <a
                                    className={styles.cardLink}
                                    href={item.url}
                                    target={"_blank"}
                                    rel={"noopener nofollow noreferrer"}
                                >
                                    Скачать
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={styles.section}>
                    <h3 className={styles.title}>Инструкции по работе с личным кабинетом</h3>
                    <ul className={[styles.cardDeck, styles.cardDeck_size_lg].join(" ")}>
                        {instructions.map((item, index) => (
                            <li className={[styles.card, styles.card_type_docs].join(" ")}>
                                <h4 className={[styles.cardTitle, styles.cardTitle_size_lg].join(" ")}>{item.title}</h4>
                                <a
                                    className={styles.cardLink}
                                    href={item.url}
                                    target={"_blank"}
                                    rel={"noopener nofollow noreferrer"}
                                >
                                    Скачать
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </section>
        </motion.div>
    );
};

export default MethodologicalSupportPage;

import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import useAuthStore from "../store/authStore";

import AdminLayout from "../layout/admin.layout.component";
import UserLayout from "../layout/user.layout.component";
import PublicLayout from "../layout/public.layout.component";

import Page404 from "../pages/404.page";
import LoginPage from "../pages/login/login.page";
import ProfilePage from "../pages/profile.page";

// ADMIN PAGES
import UsersPage from "../pages/admin/users/users.page";
import AdminUsersPage from "../pages/admin/users/admin.users.page";
import UserUsersPage from "../pages/admin/users/user.users.page";
import MySchoolPage from "../pages/user/my.school.page";
import TheatresPage from "../pages/admin/theatres/theatres.page";
import TheatrePage from "../pages/admin/theatres/theatre.page";
import SchoolsPage from "../pages/admin/schools/schools.page";
import SchoolPage from "../pages/admin/schools/school.page";
import TheatreRequestsPage from "../pages/admin/theatreRequests/theatreRequests.page";
import TheatreRequestPage from "../pages/admin/theatreRequests/theatreRequest.page";
import AdminTeachersPage from "../pages/admin/teachers/teachers.page";
import AdminTeacherPage from "../pages/admin/teachers/teacher.page";
import AdminSpectaclesPage from "../pages/admin/spectacles/spectacles.page";
import AdminSpectaclePage from "../pages/admin/spectacles/spectacle.page";
import AdminSpectacleSchedulesPage from "../pages/admin/spectacleSchedules/spectacle.schedules.page";
import AdminSpectacleSchedulePage from "../pages/admin/spectacleSchedules/spectacle.schedule.page";
import AdminFestivalsPage from "../pages/admin/festivals/festivals.page";
import AdminFestivalPage from "../pages/admin/festivals/festival.page";
import AdminFestivalRequestsPage from "../pages/admin/festivalRequests/festival.requests.page";
import AdminFestivalRequestPage from "../pages/admin/festivalRequests/festival.request.page";
import AdminAllNewsPage from "../pages/admin/news/all.news.page";
import AdminNewsPage from "../pages/admin/news/news.page";
import AdminActivitiesPage from "../pages/admin/activity/activities.page";
import AdminCityClubPage from "../pages/admin/activity/city.club.page";
import AdminMethodologicalSupportPage from "../pages/admin/activity/methodological.support.page";
import ScenariosPage from "../pages/admin/scenarioBank/scenarios.page";
import ScenarioPage from "../pages/admin/scenarioBank/scenario.page";

import TestPage from "../pages/test.page";

// USER PAGES
import MyTheatresPage from "../pages/user/theatres/my.theatres.page";
import MyTheatreEditPage from "../pages/user/theatres/my.theatre.page";
import MyTheatrePage from "../pages/user/theatres/theatre.page";
import MyTheatreRequestsPage from "../pages/user/theatreRequests/my.theatreRequests.page";
import MyTheatreRequestPage from "../pages/user/theatreRequests/my.theatreRequest.page";
import UserTeachersPage from "../pages/user/teachers/teachers.page";
import UserTeacherPage from "../pages/user/teachers/teacher.page";
import MySpectaclesPage from "../pages/user/spectacles/my.spectacles.page";
import MySpectaclePage from "../pages/user/spectacles/my.spectacle.page";
import UserSpectacleSchedulesPage from "../pages/user/spectacleSchedules/spectacle.schedules.page";
import UserSpectacleSchedulePage from "../pages/user/spectacleSchedules/spectacle.schedule.page";
import UserFestivalsPage from "../pages/user/festivals/festivals.page";
import UserFestivalPage from "../pages/user/festivals/festival.page";
import UserFestivalRequestsPage from "../pages/user/festivalRequests/festival.requests.page";
import UserFestivalRequestPage from "../pages/user/festivalRequests/festival.request.page";

// PUBLIC PAGES
import MainPage from "../pages/public/index.page";
import ConcordPage from "../pages/public/concord/concord.page";
import AllNewsPage from "../pages/public/news/all.news.page";
import NewsPage from "../pages/public/news/news.page";
import PublicTheatrePage from "../pages/public/theatres/theatre.page";
import PublicTheatresPage from "../pages/public/theatres/theatres.page";
import FestivalsPage from "../pages/public/festivals/festivals.page";
import SpectaclePage from "../pages/public/spectacles/spectacle.page";
import SpectaclesPage from "../pages/public/spectacles/spectacles.page";
import ClubPage from "../pages/public/activity/club.page";
import MethodologicalSupportPage from "../pages/public/activity/methodological.support.page";
import OldMethodologicalSupportPage from "../pages/public/activity/methodological.support.page.old";
import AssistanceMentorPage from "../pages/public/activity/assistance.mentor.page";
import CancelSpectacleBookingPage from "../pages/public/spectacles/cancel.spectacle.booking.page";
import CancelClubMeetingPage from "../pages/public/activity/cancel.club.meeting.page";
import ScenariosBankPage from "../pages/public/activity/scenarios.bank.page";
import ScenarioBankPage from "../pages/public/activity/scenario.bank.page";

const RoutesList = () => {
    const {user} = useAuthStore();

    const publicRoutes = <Route path="/" element={<PublicLayout/>}>
        <Route
            index
            element={<MainPage/>}
        />
        <Route
            path="/concord"
            exact={true}
            element={<ConcordPage/>}
        />
        <Route
            path="/festivals"
            exact={true}
            element={<FestivalsPage/>}
        />
        <Route
            path="/theatres"
            exact={true}
            element={<PublicTheatresPage/>}
        />
        <Route
            path="/theatres/:id"
            exact={true}
            element={<PublicTheatrePage/>}
        />
        <Route
            path="/news"
            exact={true}
            element={<AllNewsPage/>}
        />
        <Route
            path="/news/:id"
            exact={true}
            element={<NewsPage/>}
        />
        <Route
            path="/spectacles"
            exact={true}
            element={<SpectaclesPage/>}
        />
        <Route
            path="/spectacles/:id"
            exact={true}
            element={<SpectaclePage/>}
        />
        <Route
            path="/spectacles/cancel/:id"
            exact={true}
            element={<CancelSpectacleBookingPage/>}
        />
        <Route
            path="/city_club"
            exact={true}
            element={<ClubPage/>}
        />
        <Route
            path="/city_club/cancel/:id"
            exact={true}
            element={<CancelClubMeetingPage/>}
        />
        <Route
            path="/methodological_support"
            exact={true}
            element={<MethodologicalSupportPage/>}
        />
        <Route
            path="/methodological_support_old"
            exact={true}
            element={<OldMethodologicalSupportPage/>}
        />
        <Route
            path="/assistance_mentor"
            exact={true}
            element={<AssistanceMentorPage/>}
        />
        <Route
            path="/scenario_bank"
            exact={true}
            element={<ScenariosBankPage/>}
        />
        <Route
            path="/scenario_bank/:id"
            exact={true}
            element={<ScenarioBankPage/>}
        />
    </Route>;

    React.useEffect(() => {

        //console.log(user);

    }, [user]);

    if (user && (user.role === "admin" || user.role === "superadmin")) {
        return (
            <Routes>
                <Route
                    path="/admin"
                    element={<AdminLayout/>}
                >
                    <Route path="users">
                        <Route
                            index
                            element={<UsersPage/>}
                        />
                        <Route
                            path="admin/:id"
                            element={<AdminUsersPage/>}
                        />
                        <Route
                            path="admin/new"
                            element={<AdminUsersPage/>}
                        />
                        <Route
                            path="user/:id"
                            element={<UserUsersPage/>}
                        />
                        <Route
                            path="user/new"
                            element={<UserUsersPage/>}
                        />
                    </Route>
                    <Route path="schools">
                        <Route
                            index
                            element={<SchoolsPage/>}
                        />
                        <Route
                            path=":id"
                            element={<SchoolPage/>}
                        />
                        <Route
                            path="new"
                            element={<SchoolPage/>}
                        />
                    </Route>
                    <Route path="theatres">
                        <Route
                            index
                            element={<TheatresPage/>}
                        />
                        <Route
                            path=":id"
                            element={<TheatrePage/>}
                        />
                        <Route
                            path="new"
                            element={<TheatrePage/>}
                        />
                    </Route>
                    <Route path="theatreRequests">
                        <Route
                            index
                            element={<TheatreRequestsPage/>}
                        />
                        <Route
                            path=":id"
                            element={<TheatreRequestPage/>}
                        />
                        <Route
                            path="new"
                            element={<TheatreRequestPage/>}
                        />
                    </Route>
                    <Route path="teachers">
                        <Route
                            index
                            element={<AdminTeachersPage/>}
                        />
                        <Route
                            path=":id"
                            element={<AdminTeacherPage/>}
                        />
                        <Route
                            path="new"
                            element={<AdminTeacherPage/>}
                        />
                    </Route>
                    <Route path="spectacles">
                        <Route
                            index
                            element={<AdminSpectaclesPage/>}
                        />
                        <Route
                            path=":id"
                            element={<AdminSpectaclePage/>}
                        />
                    </Route>
                    <Route path="spectacleSchedules">
                        <Route
                            index
                            element={<AdminSpectacleSchedulesPage/>}
                        />
                        <Route
                            path=":id"
                            element={<AdminSpectacleSchedulePage/>}
                        />
                    </Route>
                    <Route path="festivals">
                        <Route
                            index
                            element={<AdminFestivalsPage/>}
                        />
                        <Route
                            path=":id"
                            element={<AdminFestivalPage/>}
                        />
                    </Route>
                    <Route path="festivalRequests">
                        <Route
                            index
                            element={<AdminFestivalRequestsPage/>}
                        />
                        <Route
                            path=":id"
                            element={<AdminFestivalRequestPage/>}
                        />
                    </Route>
                    <Route path="news">
                        <Route
                            index
                            element={<AdminAllNewsPage/>}
                        />
                        <Route
                            path=":id"
                            element={<AdminNewsPage/>}
                        />
                        <Route
                            path="new"
                            element={<AdminNewsPage/>}
                        />
                    </Route>
                    <Route path="activity">
                        <Route
                            index
                            element={<AdminActivitiesPage/>}
                        />
                        <Route
                            path="cityClub/:id"
                            element={<AdminCityClubPage/>}
                        />
                        <Route
                            path="cityClub/new"
                            element={<AdminCityClubPage/>}
                        />
                        <Route
                            path="methodologicalSupport/:id"
                            element={<AdminMethodologicalSupportPage/>}
                        />
                        <Route
                            path="methodologicalSupport/new"
                            element={<AdminMethodologicalSupportPage/>}
                        />
                    </Route>
                    <Route path="scenarioBank">
                        <Route
                            index
                            element={<ScenariosPage/>}
                        />
                        <Route
                            path=":id"
                            element={<ScenarioPage/>}
                        />
                        <Route
                            path="new"
                            element={<ScenarioPage/>}
                        />
                    </Route>
                </Route>
                <Route
                    path="/profile"
                    exact={true}
                    element={<AdminLayout/>}
                >
                    <Route
                        index
                        element={<ProfilePage/>}
                    />
                </Route>
                <Route
                    path="/test"
                    exact={true}
                    element={<TestPage/>}
                />
                <Route
                    path="/login"
                    exact={true}
                    element={<Navigate to="/admin/users"/>}
                />
                {publicRoutes}
                <Route
                    path="*"
                    element={<Page404/>}
                />
            </Routes>
        );
    }

    if (user && user.role === "user") {
        return (
            <Routes>
                <Route
                    path="/user"
                    element={<UserLayout/>}
                >
                    <Route
                        path="my_school"
                        index
                        element={<MySchoolPage/>}
                    />
                    <Route path="theatres">
                        <Route
                            index
                            element={<MyTheatresPage/>}
                        />
                        <Route
                            path=":id"
                            element={<MyTheatrePage/>}
                        />
                        <Route
                            path="edit/:id"
                            element={<MyTheatreEditPage/>}
                        />
                        <Route
                            path="new"
                            element={<MyTheatreEditPage/>}
                        />
                    </Route>
                    <Route path="theatreRequests">
                        <Route
                            index
                            element={<MyTheatreRequestsPage/>}
                        />
                        <Route
                            path=":id"
                            element={<MyTheatreRequestPage/>}
                        />
                        <Route
                            path="new"
                            element={<MyTheatreRequestPage/>}
                        />
                    </Route>
                    <Route path="teachers">
                        <Route
                            index
                            element={<UserTeachersPage/>}
                        />
                        <Route
                            path=":id"
                            element={<UserTeacherPage/>}
                        />
                        <Route
                            path="new"
                            element={<UserTeacherPage/>}
                        />
                    </Route>
                    <Route path="spectacles">
                        <Route
                            index
                            element={<MySpectaclesPage/>}
                        />
                        <Route
                            path=":id"
                            element={<MySpectaclePage/>}
                        />
                        <Route
                            path="new"
                            element={<MySpectaclePage/>}
                        />
                    </Route>
                    <Route path="spectacleSchedules">
                        <Route
                            index
                            element={<UserSpectacleSchedulesPage/>}
                        />
                        <Route
                            path=":id"
                            element={<UserSpectacleSchedulePage/>}
                        />
                        <Route
                            path="new"
                            element={<UserSpectacleSchedulePage/>}
                        />
                    </Route>
                    <Route path="festivals">
                        <Route
                            index
                            element={<UserFestivalsPage/>}
                        />
                        <Route
                            path=":id"
                            element={<UserFestivalPage/>}
                        />
                    </Route>
                    <Route path="festivalRequests">
                        <Route
                            index
                            element={<UserFestivalRequestsPage/>}
                        />
                        <Route
                            path=":id"
                            element={<UserFestivalRequestPage/>}
                        />
                        <Route
                            path="new"
                            element={<UserFestivalRequestPage/>}
                        />
                    </Route>
                </Route>
                <Route
                    path="/profile"
                    exact={true}
                    element={<UserLayout/>}
                >
                    <Route
                        index
                        element={<ProfilePage/>}
                    />
                </Route>
                <Route
                    path="/login"
                    exact={true}
                    element={<Navigate to="/user/my_school"/>}
                />
                {publicRoutes}
                <Route
                    path="*"
                    element={<Page404/>}
                />
            </Routes>
        );
    }

    return (
        <Routes>
            {publicRoutes}
            <Route
                path="/login"
                exact={true}
                element={<LoginPage/>}
            />
            <Route
                path="*"
                element={<Navigate to="/login"/>}
            />
        </Routes>
    );
};

export default RoutesList;
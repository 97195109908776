import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import moment from "moment";

import useActivitiesStore from "../../../store/admin/activitiesStore";

import Button from "../../../components/button/button.component";
import Notif from "../../../components/notif/notif.component";
import FieldInput from "../../../components/field/field.input.component";
import Table from "../../../components/table/table.component";

import { AdminIcons } from "../../../components/svgs";
import commonStyles from "../../common.module.scss";
import MultiSelect from "../../../components/multi_select/multi_select.component";
import { isArray } from "lodash";

const AdminCityClubPage = () => {
    let { id } = useParams();
    const navigate = useNavigate();
    const back = () => navigate("/admin/activity");

    const activitiesStore = useActivitiesStore();

    const fetchData = async () => {
        if (id) await activitiesStore.loadCityClubMeeting({ id });
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    const Loading = () => {
        if (activitiesStore.loading) {
            return (
                <div className={commonStyles.title_block}>
                    <h1 className={commonStyles.title}>Загрузка...</h1>
                </div>
            );
        }
    };

    const NotFound = () => {
        if (id && !activitiesStore.loading && Object.keys(activitiesStore.cityClubMeeting).length === 0) {
            return (
                <div className={commonStyles.title_block}>
                    <Button
                        type='button'
                        theme='text'
                        size='small'
                        iconClass={"mdi mdi-arrow-left"}
                        isIconBtn={true}
                        aria-label='Назад'
                        onClick={back}
                    />
                    <h1 className={commonStyles.title}>Встреча не найдена</h1>
                </div>
            );
        }
    };

    const MainBlock = () => {
        const [edit, setEdit] = React.useState(false);
        const { register, handleSubmit, reset, control, setValue, getValues } = useForm();

        const getStatus = (status) => {
            switch (status) {
                case 2:
                    return "Регистрация идет";
                case 3:
                    return "Регистрация завершена";
                default:
                    return "Регистрация скоро начнется";
            }
        };

        const New = () => {
            const [popup, setPopup] = React.useState(<></>);
            const [sending, setSending] = React.useState(false);
            const [external, setExternal] = React.useState(false);

            const checkForComplete = (item) => {
                if (item.datetime === "") {
                    setPopup(
                        <Notif
                            title='Ошибка!'
                            text={"Вы не заполнили поле: Дата и время"}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                const date = moment(item.datetime);
                const dateEnd = moment().add(30, "years");

                if (date.isAfter(dateEnd)) {
                    setPopup(
                        <Notif
                            title='Ошибка!'
                            text={"Вы ввели некорректную дату"}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                if (item.title === "") {
                    setPopup(
                        <Notif
                            title='Ошибка!'
                            text={"Вы не заполнили поле: Название"}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                if (item.external && item.external_link === "") {
                    setPopup(
                        <Notif
                            title='Ошибка!'
                            text={"Вы не заполнили поле: Ссылка на внешнюю систему регистрации"}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                if (item.theme === "") {
                    setPopup(
                        <Notif
                            title='Ошибка!'
                            text={"Вы не заполнили поле: Тема"}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                if (item.duration === "") {
                    setPopup(
                        <Notif
                            title='Ошибка!'
                            text={"Вы не заполнили поле: Продолжительность"}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                if (item.place === "") {
                    setPopup(
                        <Notif
                            title='Ошибка!'
                            text={"Вы не заполнили поле: Площадка"}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                if (item.address === "") {
                    setPopup(
                        <Notif
                            title='Ошибка!'
                            text={"Вы не заполнили поле: Адрес"}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                if (parseInt(item.ticketsCount) < 0) {
                    setPopup(
                        <Notif
                            title='Ошибка!'
                            text={"Мест в зале должно быть больше 0"}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                return true;
            };

            const onAdd = async (params) => {
                setSending(true);
                const data = getValues();

                if (isArray(data.status)) data.status = data.status[0].value;
                else data.status = getValues().status.value;

                data.meeting_type = getValues().meeting_type.value;

                let sendObject = { ...data };

                if (!checkForComplete(sendObject)) {
                    setSending(false);
                    return;
                }

                const result = await activitiesStore.addCityClubMeeting(sendObject);

                setSending(false);

                if (!result.error) {
                    setPopup(
                        <Notif
                            title=''
                            text={"Встреча клуба успешно добавлена"}
                            opened={true}
                            onClose={() => {
                                activitiesStore.clearErrorText();
                                setPopup(<></>);
                                back();
                            }}
                        />
                    );
                }
            };

            if (!id) {
                return (
                    <>
                        <div className={commonStyles.title_block}>
                            <Button
                                type='button'
                                theme='text'
                                size='small'
                                iconClass={"mdi mdi-arrow-left"}
                                isIconBtn={true}
                                aria-label='Назад'
                                onClick={() => {
                                    reset();
                                    back();
                                }}
                            />
                            <h1 className={commonStyles.title}>Создание встречи клуба</h1>
                        </div>
                        <form onSubmit={handleSubmit(onAdd)} className='form'>
                            <fieldset className='form__section'>
                                <div className='form__multy-block'>
                                    <p className='form__label'>Статус встречи</p>
                                    <MultiSelect
                                        required={true}
                                        control={control}
                                        isMulti={false}
                                        name={"status"}
                                        closeMenuOnSelect={true}
                                        values={{
                                            label: "Регистрация скоро начнется",
                                            value: 1,
                                        }}
                                        options={[
                                            {
                                                label: "Регистрация скоро начнется",
                                                value: 1,
                                            },
                                            {
                                                label: "Регистрация идет",
                                                value: 2,
                                            },
                                            {
                                                label: "Регистрация завершена",
                                                value: 3,
                                            },
                                        ]}
                                    />
                                </div>
                                <FieldInput
                                    label='Название встречи'
                                    layout='flex'
                                    type='textarea'
                                    placeholder='Введите название...'
                                    required={true}
                                    {...register("title")}
                                />
                                <div className='form__multy-block'>
                                    <p className='form__label'>Тип встречи</p>
                                    <MultiSelect
                                        required={true}
                                        control={control}
                                        isMulti={false}
                                        name={"meeting_type"}
                                        closeMenuOnSelect={true}
                                        options={[
                                            {
                                                label: "Мастерская Содружества",
                                                value: "Мастерская Содружества",
                                            },
                                            {
                                                label: "Культура Театра",
                                                value: "Культура Театра",
                                            },
                                            {
                                                label: "Академический лекторий",
                                                value: "Академический лекторий",
                                            },
                                        ]}
                                    />
                                </div>
                                <FieldInput
                                    label='Тема встречи'
                                    layout='flex'
                                    type='textarea'
                                    placeholder='Введите тему...'
                                    required={true}
                                    {...register("theme")}
                                />
                                <FieldInput
                                    label='Дата и время'
                                    type='datetime-local'
                                    layout='flex'
                                    required={true}
                                    {...register("datetime")}
                                />
                                <FieldInput
                                    label='Продолжительность'
                                    type='text'
                                    layout='flex'
                                    placeholder='1 час или 40 минут...'
                                    required={true}
                                    {...register("duration")}
                                />
                                <FieldInput
                                    label='Площадка'
                                    layout='flex'
                                    type='textarea'
                                    placeholder='Введите площадку...'
                                    required={true}
                                    {...register("place")}
                                />
                                <FieldInput
                                    label='Адрес'
                                    layout='flex'
                                    type='textarea'
                                    placeholder='Введите адрес...'
                                    required={true}
                                    {...register("address")}
                                />
                                <FieldInput
                                    label='Кол-во мест'
                                    layout='flex'
                                    type='number'
                                    min={0}
                                    required={true}
                                    {...register("ticketsCount", {
                                        value: 0,
                                    })}
                                />
                                <FieldInput
                                    label='Использовать внешнюю систему регистрации?'
                                    type='checkbox_variant'
                                    {...register("external")}
                                    onChange={(e) => {
                                        setExternal(e.target.checked);
                                        setValue("external_link", e.target.checked ? getValues().external_link : "");
                                    }}
                                />
                                {external && (
                                    <FieldInput
                                        label='Ссылка на внешнюю систему регистрации'
                                        layout='flex'
                                        type='textarea'
                                        placeholder='Введите название...'
                                        required={true}
                                        {...register("external_link")}
                                    />
                                )}
                            </fieldset>
                            <div className='form__controls'>
                                <Button type='submit' theme='primary' text='Сохранить' spinnerActive={sending} />
                                <Button
                                    type='button'
                                    theme='text'
                                    text='Отмена'
                                    onClick={() => {
                                        reset();
                                        back();
                                    }}
                                    spinnerActive={sending}
                                />
                            </div>
                        </form>
                        {popup}
                    </>
                );
            }
        };

        const Edit = () => {
            const [popup, setPopup] = React.useState(<></>);
            const [sending, setSending] = React.useState(false);
            const [external, setExternal] = React.useState(activitiesStore.cityClubMeeting.external === 1);

            React.useEffect(() => {
                if (edit) {
                    setValue("title", activitiesStore.cityClubMeeting.title);
                    setValue("meeting_type", {
                        label: activitiesStore.cityClubMeeting.meeting_type,
                        value: activitiesStore.cityClubMeeting.meeting_type,
                    });
                    setValue("theme", activitiesStore.cityClubMeeting.theme);
                    setValue("datetime", activitiesStore.cityClubMeeting.datetime);
                    setValue("duration", activitiesStore.cityClubMeeting.duration);
                    setValue("place", activitiesStore.cityClubMeeting.place);
                    setValue("address", activitiesStore.cityClubMeeting.address);
                    setValue("ticketsCount", activitiesStore.cityClubMeeting.ticketsCount);
                    setValue("external", activitiesStore.cityClubMeeting.external === 1);
                    setValue("external_link", activitiesStore.cityClubMeeting.external_link);
                    setValue("status", {
                        label: getStatus(activitiesStore.cityClubMeeting.status),
                        value: activitiesStore.cityClubMeeting.status,
                    });
                }
            }, []);

            const checkForComplete = (item) => {
                if (item.datetime === "") {
                    setPopup(
                        <Notif
                            title='Ошибка!'
                            text={"Вы не заполнили поле: Дата и время"}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                const date = moment(item.datetime);
                const dateEnd = moment().add(30, "years");

                if (date.isAfter(dateEnd)) {
                    setPopup(
                        <Notif
                            title='Ошибка!'
                            text={"Вы ввели некорректную дату"}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                if (item.title === "") {
                    setPopup(
                        <Notif
                            title='Ошибка!'
                            text={"Вы не заполнили поле: Название"}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                if (item.theme === "") {
                    setPopup(
                        <Notif
                            title='Ошибка!'
                            text={"Вы не заполнили поле: Тема"}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                if (item.duration === "") {
                    setPopup(
                        <Notif
                            title='Ошибка!'
                            text={"Вы не заполнили поле: Продолжительность"}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                if (item.place === "") {
                    setPopup(
                        <Notif
                            title='Ошибка!'
                            text={"Вы не заполнили поле: Площадка"}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                if (item.address === "") {
                    setPopup(
                        <Notif
                            title='Ошибка!'
                            text={"Вы не заполнили поле: Адрес"}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                if (parseInt(item.ticketsCount) < 0) {
                    setPopup(
                        <Notif
                            title='Ошибка!'
                            text={"Мест в зале должно быть больше 0"}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                return true;
            };

            const onEdit = async (params) => {
                setSending(true);
                const data = getValues();

                if (isArray(data.status)) data.status = data.status[0].value;
                else data.status = getValues().status.value;

                data.meeting_type = getValues().meeting_type.value;

                let sendObject = { ...data };
                sendObject["id"] = id;

                if (!checkForComplete(sendObject)) {
                    setSending(false);
                    return;
                }

                const result = await activitiesStore.editCityClubMeeting(sendObject);

                setSending(false);

                if (!result.error) {
                    setPopup(
                        <Notif
                            title=''
                            text={"Встреча клуба успешно отредактирована"}
                            opened={true}
                            onClose={() => {
                                activitiesStore.clearErrorText();
                                setPopup(<></>);
                                back();
                            }}
                        />
                    );
                }
            };

            const onDelete = async () => {
                setPopup(
                    <Notif
                        text={"Вы уверены что хотите отправить в архив?"}
                        opened={true}
                        onClose={() => setPopup(<></>)}
                        buttons={
                            <>
                                <Button
                                    type='button'
                                    size={"small"}
                                    text={"Нет"}
                                    theme='text'
                                    onClick={() => setPopup(<></>)}
                                />
                                <Button
                                    type='button'
                                    size={"small"}
                                    theme='info'
                                    text={"Да"}
                                    onClick={async () => {
                                        let sendObject = {};

                                        sendObject["id"] = id;
                                        sendObject["archive"] = 1;

                                        const result = await activitiesStore.removeCityClubMeeting(sendObject);

                                        if (!result.error) {
                                            setPopup(
                                                <Notif
                                                    title=''
                                                    text={"Встреча клуба отправлена в архив"}
                                                    opened={true}
                                                    onClose={() => {
                                                        setPopup(<></>);
                                                        back();
                                                    }}
                                                />
                                            );
                                        } else {
                                            setPopup(
                                                <Notif
                                                    title='Ошибка'
                                                    text={result.errorText}
                                                    opened={true}
                                                    onClose={() => {
                                                        setPopup(<></>);
                                                    }}
                                                />
                                            );
                                        }
                                    }}
                                />
                            </>
                        }
                    />
                );
            };

            if (id && edit) {
                return (
                    <>
                        <div className={commonStyles.title_block}>
                            <Button
                                type='button'
                                theme='text'
                                size='small'
                                iconClass={"mdi mdi-arrow-left"}
                                isIconBtn={true}
                                aria-label='Назад'
                                onClick={() => {
                                    reset();
                                    setEdit(false);
                                }}
                            />
                            <h1 className={commonStyles.title}>Редактирование встречи клуба {id}</h1>
                        </div>
                        <form onSubmit={handleSubmit(onEdit)} className='form'>
                            <fieldset className='form__section'>
                                <div className='form__multy-block'>
                                    <p className='form__label'>Статус встречи</p>
                                    <MultiSelect
                                        required={true}
                                        control={control}
                                        isMulti={false}
                                        name={"status"}
                                        closeMenuOnSelect={true}
                                        values={{
                                            label: getStatus(activitiesStore.cityClubMeeting.status),
                                            value: activitiesStore.cityClubMeeting.status,
                                        }}
                                        options={[
                                            {
                                                label: "Регистрация скоро начнется",
                                                value: 1,
                                            },
                                            {
                                                label: "Регистрация идет",
                                                value: 2,
                                            },
                                            {
                                                label: "Регистрация завершена",
                                                value: 3,
                                            },
                                        ]}
                                    />
                                </div>
                                <FieldInput
                                    label='Название встречи'
                                    layout='flex'
                                    type='textarea'
                                    placeholder='Введите название...'
                                    required={true}
                                    {...register("title", { value: activitiesStore.cityClubMeeting.title })}
                                />
                                <div className='form__multy-block'>
                                    <p className='form__label'>Тип встречи</p>
                                    <MultiSelect
                                        required={true}
                                        control={control}
                                        isMulti={false}
                                        name={"meeting_type"}
                                        closeMenuOnSelect={true}
                                        values={{
                                            label: activitiesStore.cityClubMeeting.meeting_type,
                                            value: activitiesStore.cityClubMeeting.meeting_type,
                                        }}
                                        options={[
                                            {
                                                label: "Мастерская Содружества",
                                                value: "Мастерская Содружества",
                                            },
                                            {
                                                label: "Культура Театра",
                                                value: "Культура Театра",
                                            },
                                            {
                                                label: "Академический лекторий",
                                                value: "Академический лекторий",
                                            },
                                        ]}
                                    />
                                </div>
                                <FieldInput
                                    label='Тема встречи'
                                    layout='flex'
                                    type='textarea'
                                    placeholder='Введите тему...'
                                    required={true}
                                    {...register("theme", { value: activitiesStore.cityClubMeeting.theme })}
                                />
                                <FieldInput
                                    label='Дата и время'
                                    type='datetime-local'
                                    layout='flex'
                                    required={true}
                                    {...register("datetime", { value: activitiesStore.cityClubMeeting.datetime })}
                                />
                                <FieldInput
                                    label='Продолжительность'
                                    type='text'
                                    layout='flex'
                                    placeholder='1 час или 40 минут...'
                                    required={true}
                                    {...register("duration", { value: activitiesStore.cityClubMeeting.duration })}
                                />
                                <FieldInput
                                    label='Площадка'
                                    layout='flex'
                                    type='textarea'
                                    placeholder='Введите площадку...'
                                    required={true}
                                    {...register("place", { value: activitiesStore.cityClubMeeting.place })}
                                />
                                <FieldInput
                                    label='Адрес'
                                    layout='flex'
                                    type='textarea'
                                    placeholder='Введите адрес...'
                                    required={true}
                                    {...register("address", { value: activitiesStore.cityClubMeeting.address })}
                                />
                                <FieldInput
                                    label='Кол-во мест'
                                    layout='flex'
                                    type='number'
                                    min={0}
                                    required={true}
                                    {...register("ticketsCount", {
                                        value: activitiesStore.cityClubMeeting.ticketsCount,
                                    })}
                                />
                                <FieldInput
                                    label='Использовать внешнюю систему регистрации?'
                                    type='checkbox_variant'
                                    {...register("external", { value: activitiesStore.cityClubMeeting.external })}
                                    onChange={(e) => {
                                        setExternal(e.target.checked);
                                        setValue("external_link", e.target.checked ? getValues().external_link : "");
                                    }}
                                />
                                {external && (
                                    <FieldInput
                                        label='Ссылка на внешнюю систему регистрации'
                                        layout='flex'
                                        type='textarea'
                                        placeholder='Введите название...'
                                        required={true}
                                        {...register("external_link", {
                                            value: activitiesStore.cityClubMeeting.external_link,
                                        })}
                                    />
                                )}
                            </fieldset>
                            <div className='form__controls'>
                                <Button type='submit' theme='primary' text='Сохранить' spinnerActive={sending} />
                                <Button
                                    type='button'
                                    theme='text'
                                    text='В архив'
                                    onClick={onDelete}
                                    spinnerActive={sending}
                                />
                                <Button
                                    type='button'
                                    theme='text'
                                    text='Отмена'
                                    onClick={() => {
                                        setEdit(false);
                                    }}
                                    spinnerActive={sending}
                                />
                            </div>
                        </form>
                        {popup}
                    </>
                );
            }
        };

        const View = () => {
            const itemConfig = [
                {
                    header: "Образовательная организация",
                    key: "school",
                    type: "string",
                    filter: "string",
                    sorting: true,
                },
                {
                    header: "Должность",
                    key: "position",
                    type: "string",
                    filter: "string",
                    sorting: true,
                },
                {
                    header: "Наличие школьного театра",
                    key: "theatre",
                    type: "string",
                    filter: "select",
                    sorting: true,
                },
                {
                    header: "Телефон",
                    key: "phone",
                    type: "string",
                    filter: "string",
                    sorting: true,
                },
                {
                    header: "Email",
                    key: "email",
                    type: "string",
                    filter: "string",
                    sorting: true,
                },
                {
                    header: "Список гостей",
                    key: "people",
                    type: "array",
                    filter: "array",
                    arrayKey: "fio",
                },
            ];

            const onItemClick = (props) => {};

            if (id && !edit && !activitiesStore.loading && Object.keys(activitiesStore.cityClubMeeting).length > 0) {
                return (
                    <>
                        <div className={commonStyles.title_block}>
                            <Button
                                type='button'
                                theme='text'
                                size='small'
                                iconClass={"mdi mdi-arrow-left"}
                                isIconBtn={true}
                                aria-label='Назад'
                                onClick={back}
                            />
                            <h1 className={commonStyles.title}>
                                Встреча клуба ID: {activitiesStore.cityClubMeeting.ID}
                            </h1>
                            <Button
                                size='smaller'
                                type='submit'
                                isIconBtn='true'
                                iconClass={"mdi mdi-pencil"}
                                theme='outline'
                                aria-label='Редактировать встречу'
                                onClick={() => {
                                    setEdit(true);
                                }}
                            />
                        </div>
                        <article className={commonStyles.spectacleCard}>
                            <p
                                className={`${commonStyles.spectacleCardElement} ${commonStyles.spectacleCardStatus}`}
                                aria-label='Статус'
                                title='Статус'
                            >
                                {getStatus(activitiesStore.cityClubMeeting.status)}
                            </p>
                            <div className={commonStyles.spectacleCardElementsRow}>
                                <p
                                    className={`${commonStyles.spectacleCardElement} ${commonStyles.spectacleCardCaption}`}
                                    aria-label='Время/дата'
                                    title='Время/дата'
                                >
                                    {moment(activitiesStore.cityClubMeeting.datetime).format("DD.MM.YYYY HH:mm")}
                                </p>
                                <p
                                    className={`${commonStyles.spectacleCardElement} ${commonStyles.spectacleCardCaption}`}
                                    aria-label='Продолжительность'
                                    title='Продолжительность'
                                >
                                    {AdminIcons.timelapse}
                                    {activitiesStore.cityClubMeeting.duration}
                                </p>
                            </div>
                            <div className={commonStyles.spectacleCardMainText}>
                                <p className={`${commonStyles.spectacleCardCaption}`}>
                                    {activitiesStore.cityClubMeeting.meeting_type}
                                </p>
                                <h2 className={commonStyles.spectacleCardTitle}>
                                    {activitiesStore.cityClubMeeting.title}
                                </h2>
                                <p className={commonStyles.spectacleCardSubtitle} aria-label='Тема' title='Тема'>
                                    {activitiesStore.cityClubMeeting.theme}
                                </p>
                            </div>
                            <div className={commonStyles.spectacleCardElementsColumn}>
                                <p className={commonStyles.spectacleCardElement} aria-label='Площадка' title='Площадка'>
                                    {AdminIcons.homeWork}
                                    {activitiesStore.cityClubMeeting.place}
                                </p>
                                <p
                                    className={commonStyles.spectacleCardElement}
                                    aria-label='Адрес площадки'
                                    title='Адрес площадки'
                                >
                                    {AdminIcons.location}
                                    {activitiesStore.cityClubMeeting.address}
                                </p>
                                <p
                                    className={commonStyles.spectacleCardElement}
                                    aria-label='Кол-во оставшихся билетов/Всего мест'
                                    title='Кол-во оставшихся билетов/Всего мест'
                                >
                                    {AdminIcons.tiket}
                                    {activitiesStore.cityClubMeeting.ticketsLeft}/
                                    {activitiesStore.cityClubMeeting.ticketsCount}
                                </p>
                            </div>
                        </article>
                        <h2 className={commonStyles.title}>Информация о билетах</h2>
                        <Table
                            title={"Таблица билетов встречи клуба" + activitiesStore.cityClubMeeting.ID}
                            loading={activitiesStore.loading}
                            items={activitiesStore.cityClubMeeting.tickets}
                            itemsConfig={itemConfig}
                            onItemClick={onItemClick}
                            withFilter={true}
                        />
                    </>
                );
            }
        };

        return (
            <>
                <New />
                <Edit />
                <View />
            </>
        );
    };

    return (
        <>
            <Loading />
            <MainBlock />
            <NotFound />
        </>
    );
};

export default AdminCityClubPage;

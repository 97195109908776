import React from "react";
import { motion } from "framer-motion";

import Breadcrumbs from "../../../components/breadcrumbs/breadcrumbs.component";

import commonStyles from "../common.module.scss";
import styles from "./festivals.module.scss";
import banner from "../../../images/events/girl-with-clown-costume-and-candy.png";

import junior from "../../../images/events/icons/junior group.svg";
import mixed from "../../../images/events/icons/mixed group.svg";
import senior from "../../../images/events/icons/senior group.svg";
import schools from "../../../images/events/icons/shcool teatres.svg";
import udo from "../../../images/events/icons/theaters based on udo.svg";
import colledge from "../../../images/events/icons/college-based theaters.svg";
import melpomene from "../../../images/events/icons/school melpomene.svg";
import waist from "../../../images/events/icons/supportive waist.svg";

const FestivalsPage = () => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 0.2, duration: 1 }}
        >
            <Breadcrumbs
                items={[
                    {
                        title: "Главная",
                        url: "/",
                    },
                    {
                        title: "Фестиваль «Живая сцена»",
                        url: "",
                    },
                ]}
            />
            <section
                className={[commonStyles.wrap, commonStyles.wrap_shadow, styles.banner].join(" ")}
                style={{ backgroundImage: `url(${banner})` }}
            >
                <h2 className={[commonStyles.title, styles.topTitle].join(" ")}>
                    Московский театральный <br />
                    фестиваль-конкурс <br />
                    «Живая сцена»
                </h2>
            </section>
            <section className={[commonStyles.wrap, commonStyles.wrap_bubble].join(" ")}>
                <h2 className={[commonStyles.title, commonStyles.title_underline].join(" ")}>
                    кто может стать участником?
                </h2>
                <p className={styles.description}>
                    Участниками Фестиваля-конкурса являются детские и молодежные театры на базе образовательных
                    организаций города Москвы, входящие в «Содружество Школьных театров города Москвы»
                </p>
                <ul className={styles.cardDeck}>
                    <li className={styles.card_with_icons}>
                        <img className={styles.cardIcon} src={schools} alt='Иконка школьные театры' />
                        <h3 className={styles.cardTitle_pr}>Школьные театры</h3>
                    </li>
                    <li className={styles.card_with_icons}>
                        <img className={styles.cardIcon} src={colledge} alt='Иконка театры на базе колледжа' />
                        <h3 className={styles.cardTitle_pr}>театры на базе колледжа</h3>
                    </li>
                    <li className={styles.card_with_icons}>
                        <img className={styles.cardIcon} src={udo} alt='Иконка театры на базе УДО' />
                        <h3 className={styles.cardTitle_pr}>театры на базе УДО</h3>
                    </li>
                </ul>
            </section>
            <section className={[commonStyles.wrap, commonStyles.wrap_shadow, styles.blueSection].join(" ")}>
                <h2 className={[commonStyles.title, commonStyles.title_underline].join(" ")}>
                    в каких направлениях может принять участие театр?
                </h2>
                <ul className={styles.cardDeck}>
                    <li className={styles.card_with_fest_icons}>
                        <img className={styles.cardIcon_fest} src={waist} alt='Иконка Благосклонная Талия' />
                        <div>
                            <h3 className={styles.cardTitle}>Благосклонная Талия</h3>
                            <p className={styles.cardText}>
                                <span>Ноябрь 2024&nbsp;г. &mdash;&nbsp;Апрель 2025&nbsp;г.</span>
                                Определяет наиболее активные театральные образовательные пространства участников
                            </p>
                        </div>
                    </li>
                    <li className={styles.card_with_fest_icons}>
                        <img className={styles.cardIcon_fest} src={melpomene} alt='Иконка Школьная Мельпомена' />
                        <div>
                            <h3 className={styles.cardTitle}>Школьная Мельпомена</h3>
                            <p className={styles.cardText}>
                                <span>Ноябрь 2024&nbsp;г. &mdash;&nbsp;Май 2025&nbsp;г.</span>
                                Включает открытый показ спектаклей финалистов на профессиональной сцене
                            </p>
                        </div>
                    </li>
                </ul>
            </section>
            <section className={[commonStyles.wrap, commonStyles.wrap_bubble].join(" ")}>
                <h2 className={[commonStyles.title, commonStyles.title_underline].join(" ")}>благосклонная талия</h2>
                <div className={styles.cardDeck}>
                    <div className={styles.card_br_ac}>
                        <h3 className={styles.cardTitle_bg_ac}>один этап</h3>
                        <p className={styles.cardText}>
                            Оценивается активная деятельность школьного театра внутри школы, во&nbsp;внешней среде,
                            показы спектаклей, фотографии, видео, афиши, и&nbsp;многое другое. Стань театром-лидером
                            фестиваля-конкурса &laquo;Живая сцена&raquo;.
                        </p>
                    </div>
                    <div className={styles.card_br_ac}>
                        <h3 className={styles.cardTitle_bg_ac}>размещение заявки</h3>
                        <p className={styles.cardText}>
                            В&nbsp;направлении принимают участие все театры, зарегистрированные в&nbsp;Содружестве.
                            Оцениваются материалы и&nbsp;достижения, полученные и&nbsp;добавленные в&nbsp;личный кабинет
                            театра в&nbsp;период
                            <span>с&nbsp;21.04.2024 по&nbsp;20.04.2025</span>
                        </p>
                    </div>
                    <div className={styles.card_br_ac}>
                        <h3 className={styles.cardTitle_bg_ac}>Подведение итогов</h3>
                        <p className={styles.bigText}>июнь 2025&nbsp;г.</p>
                    </div>
                </div>
            </section>
            <section className={[commonStyles.wrap, commonStyles.wrap_bubble].join(" ")}>
                <h2 className={[commonStyles.title, commonStyles.title_underline].join(" ")}>Школьная Мельпомена</h2>
                <div className={styles.cardDeck}>
                    <div className={styles.card_br_ac}>
                        <h3 className={styles.cardTitle_bg_pr}>4 этапа</h3>
                        <ul className={styles.cardList}>
                            <li>
                                <span>1 этап</span>
                                Размещение заявок и&nbsp;конкурсных материалов на&nbsp;портале
                                <span>с&nbsp;15.11.2024 по&nbsp;15.01.2025</span>
                                (&laquo;Историческая память&raquo;&nbsp;&mdash; по&nbsp;<strong>11.04.2025</strong>)
                            </li>
                            <li>
                                <span>2 этап (заочный)</span>
                                проведение заочного отбора спектаклей участников Фестиваля
                                <span>с&nbsp;15.01.2025 по&nbsp;02.02.2025</span>
                                (&laquo;Историческая память&raquo;&nbsp;&mdash; по&nbsp;<strong>15.04.2025</strong>)
                            </li>
                            <li>
                                <span>3 этап</span>
                                показ спектаклей на&nbsp;сцене образовательной площадки
                                <span>с&nbsp;05.02.2025 по&nbsp;20.03.2025</span>
                                (&laquo;Историческая память&raquo;&nbsp;&mdash;{" "}
                                <strong>с&nbsp;15.04.2025 по&nbsp;15.05.2025</strong>)
                            </li>
                            <li>
                                <span>4 этап</span>
                                (только для номинаций Драматический и&nbsp;Музыкальный спектакль) показ спектаклей
                                участников, прошедших в&nbsp;финал конкурса
                                <span>с&nbsp;01.04.2025 по&nbsp;20.04.2025</span>
                            </li>
                            <li>
                                <span>Церемония награждения фестиваля Июнь 2025</span>
                            </li>
                        </ul>
                    </div>
                    <div className={styles.card_br_ac}>
                        <h3 className={styles.cardTitle_bg_pr}>Размещение заявки</h3>
                        <p className={styles.cardText}>
                            Одна заявка от&nbsp;Школьного театра в&nbsp;рамках одной номинации <br /> <br />
                            В&nbsp;финал выйдет один театр от&nbsp;Образовательной организации в&nbsp;рамках одной
                            номинации <br /> <br />
                            Заявка и&nbsp;конкурсные материалы размещаются в&nbsp;личном кабинете на&nbsp;Портале
                            Содружества
                        </p>
                    </div>
                    <div className={styles.card_br_ac}>
                        <h3 className={styles.cardTitle_bg_pr}>Четыре номинации</h3>
                        <p className={styles.bigText}>«Драматический спектакль» </p>
                        <p className={styles.bigText}>«Музыкальный спектакль»</p>
                        <p className={styles.bigText}>«Театральный Олимп»</p>
                        <p className={styles.bigText}>«Историческая память»</p>
                    </div>
                </div>
            </section>
            <section className={[commonStyles.wrap, commonStyles.wrap_shadow, styles.blueSection].join(" ")}>
                <h2 className={[commonStyles.title, commonStyles.title_underline, styles.title].join(" ")}>
                    Возрастные группы* <span>Актуально для направления “Школьная Мельпомена”</span>
                </h2>
                <ul className={styles.cardDeck}>
                    <li className={styles.card_with_icons}>
                        <img className={styles.cardIcon} src={junior} alt='Иконка Младшая Обучающиеся' />
                        <h3 className={styles.cardTitle_ws}>
                            Младшая
                            <span>обучающиеся c 1 по 5 класс</span>
                        </h3>
                    </li>
                    <li className={styles.card_with_icons}>
                        <img className={styles.cardIcon} src={senior} alt='Иконка Старшая Обучающиеся' />
                        <h3 className={styles.cardTitle_ws}>
                            Старшая
                            <span>обучающиеся с 6 по 11 класс и Студенты колледжей</span>
                        </h3>
                    </li>
                    <li className={styles.card_with_icons}>
                        <img className={styles.cardIcon} src={mixed} alt='Иконка Смешанная' />
                        <h3 className={styles.cardTitle_ws}>
                            Смешанная <span>обучающиеся из разных возрастных групп</span>
                        </h3>
                    </li>
                </ul>
            </section>
            <section className={[commonStyles.wrap, commonStyles.wrap_shadow].join(" ")}>
                <h2 className={[commonStyles.title, commonStyles.title_underline].join(" ")}>
                    Хочешь узнать подробнее о фестивале?
                </h2>
                <p className={styles.feedback}>
                    Подробную информацию о проведении Фестиваля-конкурса <span>«Живая сцена»</span> вы можете найти в{" "}
                    <a
                        href={process.env.REACT_APP_BASE_URL + "docs/festivals/Положение_Живая_сцена_2024.pdf"}
                        target={"_blank"}
                        download
                        rel='noopener nofollow noreferrer'
                    >
                        положении
                    </a>{" "}
                    или написать нам на почту{" "}
                    <a href='mailto:sodruzhestvotheatre@edu.mos.ru' rel='noopener nofollow noreferrer'>
                        sodruzhestvo&shy;theatre@edu.mos.ru
                    </a>
                </p>
            </section>
        </motion.div>
    );
};

export default FestivalsPage;

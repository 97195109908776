import React from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import createDOMPurify from "dompurify";
import _uniqueId from "lodash/uniqueId";

import useAuthStore from "../../../store/authStore";
import useSpectaclesStore from "../../../store/user/spectaclesStore";
import useTheatresStore from "../../../store/user/theatresStore";
import useTeachersStore from "../../../store/admin/teachersStore";

import Button from "../../../components/button/button.component";
import FieldInput from "../../../components/field/field.input.component";
import Tabs from "../../../components/tabs/tabs.component";
import Tab from "../../../components/tabs/tab.component";
import MultiSelect from "../../../components/multi_select/multi_select.component";
import Editor from "../../../components/reach_editor/editor.component";
import ImageSelector from "../../../components/image_selector/image.selector.component";
import FileSelector from "../../../components/file_selector/file.selector.component";
import ImageGallery from "../../../components/image_gallery/image.gallery.component";
import FileGallery from "../../../components/file_gallery/file.gallery.component";
import VideoPlayer from "../../../components/video_player/video.player.component";
import Notif from "../../../components/notif/notif.component";

import commonStyles from "../../common.module.scss";
import styles from "../../../components/page_components/theatre/theatre.module.scss";
import no_photo_man from "../../../images/no_photo_man.png";
import Score from "../../../components/score/score.component";

const MySpectaclePage = () => {
    let { id } = useParams();
    const navigate = useNavigate();
    const DOMPurify = createDOMPurify(window);

    const { user } = useAuthStore();
    const spectaclesStore = useSpectaclesStore();
    const theatresStore = useTheatresStore();
    const teachersStore = useTeachersStore();

    const {
        register,
        handleSubmit,
        reset,
        control,
        setValue,
        getValues } =
        useForm();
    const scoreForm = useForm();

    const [edit, setEdit] = React.useState(false);
    const [popup, setPopup] = React.useState(<></>);
    const [photo, setPhoto] = React.useState([]);
    const [posters, setPosters] = React.useState([]);
    const [files, setFiles] = React.useState([]);
    const [video, setVideo] = React.useState([{ id: "", url: "" }]);

    const fetchData = async () => {
        await theatresStore.loadTheatres({ schoolID: user.schoolID });
        await teachersStore.loadTeachers({ schoolID: user.schoolID });

        if (id) await spectaclesStore.loadSpectacle({ id });
    };

    React.useEffect(() => {
        fetchData();
    }, [id]);

    React.useEffect(() => {
        if (spectaclesStore.error) {
            setPopup(
                <Notif
                    title="Ошибка!"
                    text={spectaclesStore.errorText}
                    opened={true}
                    onClose={() => {
                        spectaclesStore.clearErrorText();
                        setPopup(<></>);
                    }}
                />
            );
        }
    }, [spectaclesStore.error]);

    React.useEffect(() => {
        //console.log(spectaclesStore.spectacle);
    }, [spectaclesStore.spectacle]);

    React.useEffect(() => {
        if (edit) {
            setValue("editorReview", spectaclesStore.spectacle.review);

            setPhoto(
                spectaclesStore.spectacle.photo
                    ? spectaclesStore.spectacle.photo
                    : []
            );
            setPosters(
                spectaclesStore.spectacle.posters
                    ? spectaclesStore.spectacle.posters
                    : []
            );
            setFiles(
                spectaclesStore.spectacle.files
                    ? spectaclesStore.spectacle.files
                    : []
            );

            let videoLinksArray = spectaclesStore.spectacle.video?.map(
                (link) => {
                    return { id: window.global.makeid(12), url: link };
                }
            );

            setVideo(videoLinksArray ? videoLinksArray : []);
        }
    }, [edit]);

    const handleVideoLink = () => {
        setVideo([...video, { id: "", url: "" }]);
    };

    const checkForComplete = (item) => {

        if(item.title === "")
        {
            setPopup(
                <Notif
                    title="Ошибка!"
                    text={"Вы не заполнили поле: Название постановки"}
                    opened={true}
                    onClose={() => {
                        spectaclesStore.clearErrorText();
                        setPopup(<></>);
                    }}
                />
            );
            return false;
        }

        if(item.theatre_select.length === 0 || Object.keys(item.theatre_select).length === 0)
        {
            setPopup(
                <Notif
                    title="Ошибка!"
                    text={"Вы не заполнили поле: Школьный театр"}
                    opened={true}
                    onClose={() => {
                        spectaclesStore.clearErrorText();
                        setPopup(<></>);
                    }}
                />
            );
            return false;
        }

        if(item.teachers_select.length === 0)
        {
            setPopup(
                <Notif
                    title="Ошибка!"
                    text={"Вы не заполнили поле: Педагоги"}
                    opened={true}
                    onClose={() => {
                        spectaclesStore.clearErrorText();
                        setPopup(<></>);
                    }}
                />
            );
            return false;
        }

        if(item.age_members_select.length === 0)
        {
            setPopup(
                <Notif
                    title="Ошибка!"
                    text={"Вы не заполнили поле: Возрастной состав участников спектакля"}
                    opened={true}
                    onClose={() => {
                        spectaclesStore.clearErrorText();
                        setPopup(<></>);
                    }}
                />
            );
            return false;
        }

        if(item.duration === "")
        {
            setPopup(
                <Notif
                    title="Ошибка!"
                    text={"Вы не заполнили поле: Планируемая продолжительность"}
                    opened={true}
                    onClose={() => {
                        spectaclesStore.clearErrorText();
                        setPopup(<></>);
                    }}
                />
            );
            return false;
        }

        return true;

    };

    const onAddNewSpectacle = async (params) => {
        const data = getValues();

        let sendObject = { ...data };

        sendObject["schoolID"] = user.schoolID;
        sendObject["theatreID"] = sendObject.theatre_select.value;

        if (data.age_members_select && data.age_members_select.length > 0)
            sendObject["ageMembers"] = Array.from(
                data.age_members_select.map((item) => item.value)
            );

        if (data.teachers_select && data.teachers_select.length > 0)
            sendObject["teachers"] = Array.from(
                data.teachers_select.map((item) => item.value)
            );

        if (data.editorReview) sendObject["editorReview"] = data.editorReview;

        sendObject["photo"] = photo;
        sendObject["posters"] = posters;
        sendObject["files"] = files;

        if (video.length > 0)
            sendObject["video"] = Array.from(
                video.filter((link) => link.url !== "").map((link) => link.url)
            );

        if(!checkForComplete(sendObject))
            return;

        const result = await spectaclesStore.addSpectacle(sendObject);

        if (!result.error) {
            setPopup(
                <Notif
                    title=""
                    text={"Постановка успешно добавлена"}
                    opened={true}
                    onClose={() => {
                        spectaclesStore.clearErrorText();
                        setPopup(<></>);
                        navigate("/user/spectacles");
                    }}
                />
            );
        }
    };

    const onEditSpectacle = async (params) => {
        const data = getValues();

        let sendObject = { ...data };

        sendObject["id"] = id;
        sendObject["schoolID"] = user.schoolID;
        sendObject["theatreID"] = sendObject.theatre_select.value;

        if (data.age_members_select && data.age_members_select.length > 0)
            sendObject["ageMembers"] = Array.from(
                data.age_members_select.map((item) => item.value)
            );

        if (data.teachers_select && data.teachers_select.length > 0)
            sendObject["teachers"] = Array.from(
                data.teachers_select.map((item) => item.value)
            );

        if (data.editorReview) sendObject["editorReview"] = data.editorReview;

        sendObject["photo"] = photo;
        sendObject["posters"] = posters;
        sendObject["files"] = files;

        if (video.length > 0)
            sendObject["video"] = Array.from(
                video.filter((link) => link.url !== "").map((link) => link.url)
            );

        if(!checkForComplete(sendObject))
            return;

        const result = await spectaclesStore.editSpectacle(sendObject);

        if (!result.error) {
            setPopup(
                <Notif
                    title=""
                    text={"Постановка успешно отредактирована"}
                    opened={true}
                    onClose={() => {
                        spectaclesStore.clearErrorText();
                        setPopup(<></>);
                        navigate("/user/spectacles");
                    }}
                />
            );
        }
    };

    const handleDelete = async () => {
        setPopup(
            <Notif
                text={"Вы уверены что хотите удалить?"}
                opened={true}
                onClose={() => setPopup(<></>)}
                buttons={
                    <>
                        <Button
                            type="button"
                            size={"small"}
                            text={"Нет"}
                            theme="text"
                            onClick={() => setPopup(<></>)}
                        />
                        <Button
                            type="button"
                            size={"small"}
                            theme="info"
                            text={"Да"}
                            onClick={async () => {
                                const result =
                                    await spectaclesStore.removeSpectacle({id});

                                if (!result.error) {
                                    setPopup(<></>);
                                    navigate("/user/spectacles");
                                } else {
                                    setPopup(
                                        <Notif
                                            title="Ошибка"
                                            text={result.errorText}
                                            opened={true}
                                            onClose={() => {
                                                spectaclesStore.clearErrorText();
                                                setPopup(<></>);
                                            }}
                                        />
                                    );
                                }
                            }}
                        />
                    </>
                }
            />
        );
    };

    const handleDeletePhoto = async (item) => {
        let sendObject = { ...item };

        sendObject["place"] = "photo";
        sendObject["schoolID"] = spectaclesStore.spectacle.schoolID;
        sendObject["theatreID"] = spectaclesStore.spectacle.theatreID;

        const result = await spectaclesStore.removeFile(sendObject);
    };

    const handleDeletePosterPhoto = async (item) => {
        let sendObject = { ...item };

        sendObject["place"] = "poster";
        sendObject["schoolID"] = spectaclesStore.spectacle.schoolID;
        sendObject["theatreID"] = spectaclesStore.spectacle.theatreID;

        const result = await spectaclesStore.removeFile(sendObject);
    };

    const handleDeletePosterFile = async (item) => {
        let sendObject = { ...item };

        sendObject["place"] = "file";
        sendObject["schoolID"] = spectaclesStore.spectacle.schoolID;
        sendObject["theatreID"] = spectaclesStore.spectacle.theatreID;

        const result = await spectaclesStore.removeFile(sendObject);
    };

    if (
        spectaclesStore.loading ||
        theatresStore.loading ||
        teachersStore.loading
    )
        return (
            <div className={commonStyles.title_block}>
                <h1 className={commonStyles.title}>Загрузка...</h1>
            </div>
        );

    return (
        <>
            {/* Просмотр спектакля */}
            {id &&
                !edit &&
                spectaclesStore.spectacle &&
                Object.keys(spectaclesStore.spectacle).length > 0 && (
                    <>
                        <div className={commonStyles.title_block}>
                            <Button
                                type="button"
                                theme="text"
                                size="small"
                                iconClass={"mdi mdi-arrow-left"}
                                isIconBtn={true}
                                aria-label="Назад"
                                onClick={() => navigate("/user/spectacles")}
                            />
                            <h1 className={commonStyles.title}>
                                Постановка {spectaclesStore.spectacle.title}
                            </h1>
                            <Button
                                size="smaller"
                                type="submit"
                                isIconBtn="true"
                                iconClass={"mdi mdi-pencil"}
                                theme="outline"
                                aria-label="Редактировать постановку"
                                onClick={() => {
                                    setEdit(true);
                                }}
                            />
                        </div>
                        <Tabs>
                            <Tab title={"Основные сведения"}>
                                <ul className={styles.list}>
                                    <li className={styles.item}>
                                        <h3 className={styles.label}>
                                            Публичная страница
                                        </h3>
                                        <p className={styles.description}>
                                            <NavLink
                                                className={commonStyles.link}
                                                to={"/spectacles/" + id}
                                                target={"_blank"}
                                                rel="noopener nofollow noreferrer"
                                            >
                                                На страницу{" "}
                                                <span className="mdi mdi-open-in-new" />
                                            </NavLink>
                                        </p>
                                    </li>
                                    <li className={styles.item}>
                                        <h3 className={styles.label}>
                                            Название постановки
                                        </h3>
                                        <p className={styles.description}>
                                            {spectaclesStore.spectacle.title}
                                        </p>
                                    </li>
                                    <li className={styles.item}>
                                        <h3 className={styles.label}>Театр</h3>
                                        <p className={styles.description}>
                                            {
                                                spectaclesStore.spectacle
                                                    .theatreTitle
                                            }
                                        </p>
                                    </li>
                                    <li className={styles.item}>
                                        <h3 className={styles.label}>
                                            Возрастной состав участников
                                            постановки
                                        </h3>
                                        <p className={styles.description}>
                                            {spectaclesStore.spectacle.age_members
                                                .map((item) => item.age)
                                                .join(", ")}
                                        </p>
                                    </li>
                                    <li className={styles.item}>
                                        <h3 className={styles.label}>
                                            Планируемая продолжительность, минут
                                        </h3>
                                        <p className={styles.description}>
                                            {spectaclesStore.spectacle.duration}
                                        </p>
                                    </li>
                                </ul>
                                <h2 className={styles.title}>Педагоги</h2>
                                {spectaclesStore.spectacle.teachers.length ===
                                0 ? (
                                    <p className={styles.description}>
                                        Данные не указаны
                                    </p>
                                ) : (
                                    <div className="teacher-list">
                                        {spectaclesStore.spectacle.teachers.map(
                                            (item) => {
                                                let teacher =
                                                    teachersStore.teachers.find(
                                                        (teacherInStore) =>
                                                            item.ID ===
                                                            teacherInStore.ID
                                                    );

                                                return (
                                                    <NavLink
                                                        key={item.ID}
                                                        className="teacher-list__item"
                                                        to={
                                                            "/" +
                                                            (user?.role ===
                                                            "user"
                                                                ? "user"
                                                                : "admin") +
                                                            "/teachers/" +
                                                            item.ID
                                                        }
                                                    >
                                                        <img
                                                            className="teacher-list__img"
                                                            src={
                                                                teacher?.photo
                                                                    ? window
                                                                          .global
                                                                          .baseUrl +
                                                                      teacher.photo
                                                                    : no_photo_man
                                                            }
                                                            alt=""
                                                        />
                                                        <div className="teacher-list__info">
                                                            <h3 className="teacher-list__title">
                                                                <span className="teacher-list__span-accent">
                                                                    {teacher?.f}{" "}
                                                                </span>
                                                                {teacher?.i}{" "}
                                                                {teacher?.o}
                                                            </h3>
                                                            <p className="teacher-list__text"></p>
                                                            <p className="teacher-list__description">
                                                                {
                                                                    teacher?.position
                                                                }
                                                            </p>
                                                        </div>
                                                    </NavLink>
                                                );
                                            }
                                        )}
                                    </div>
                                )}
                                <h2 className={styles.title}>Описание</h2>
                                <div
                                    className={styles.editor}
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                            spectaclesStore.spectacle.review
                                        ),
                                    }}
                                />
                            </Tab>
                            <Tab
                                title={"Афиша и программка"}
                                event={"supportive_waist"}
                            >
                                <h2 className={styles.title}>Афиша</h2>
                                <ImageGallery
                                    items={spectaclesStore.spectacle.posters}
                                    front={false}
                                />
                                <br/>
                                <Score
                                    place={"poster"}
                                    score={spectaclesStore.spectacle.score.poster}
                                    season={spectaclesStore.spectacle.score.poster_season}
                                    getValues={scoreForm.getValues}
                                />
                                <br/>
                                <br/>
                                <FileGallery
                                    title={"Программка"}
                                    items={spectaclesStore.spectacle.files}
                                    front={false}
                                />
                                <br/>
                                <Score
                                    place={"programm"}
                                    score={spectaclesStore.spectacle.score.programm}
                                    season={spectaclesStore.spectacle.score.programm_season}
                                    getValues={scoreForm.getValues}
                                />
                            </Tab>
                            <Tab
                                title={"Фотографии"}
                                event={"supportive_waist"}
                            >
                                <Score
                                    place={"photo"}
                                    score={spectaclesStore.spectacle.score.photo}
                                    season={spectaclesStore.spectacle.score.photo_season}
                                    getValues={scoreForm.getValues}
                                />
                                <ImageGallery
                                    items={spectaclesStore.spectacle.photo}
                                    front={false}
                                />
                            </Tab>
                            <Tab
                                title={"Видео"}
                                event={"supportive_waist"}
                            >
                                <h2 className={styles.title}>
                                    Видео визитка
                                    <small> (для публичной страницы)</small>
                                </h2>

                                {spectaclesStore.spectacle.video &&
                                spectaclesStore.spectacle.video.length > 0 ? (
                                    <ul className="gallery-form">
                                        {spectaclesStore.spectacle.video.map(
                                            (item) => (
                                                <li
                                                    key={item}
                                                    className="gallery-form__item"
                                                >
                                                    <VideoPlayer
                                                        source={item}
                                                    />
                                                </li>
                                            )
                                        )}
                                    </ul>
                                ) : (
                                    <p>Нет видео</p>
                                )}
                                <br/>
                                <Score
                                    place={"visit_video"}
                                    score={spectaclesStore.spectacle.score.visit_video}
                                    season={spectaclesStore.spectacle.score.visit_video_season}
                                    getValues={scoreForm.getValues}
                                />
                                <br/>
                                <h2 className={styles.title}>
                                    Видео фрагмент
                                    <small> (для участия в фестивалях)</small>
                                </h2>
                                <ul className="gallery-form">
                                    <li className="gallery-form__item">
                                        <VideoPlayer
                                            source={spectaclesStore.spectacle.videoFragment}
                                        />
                                    </li>
                                </ul>
                            </Tab>
                        </Tabs>
                    </>
                )}
            {/* Не найден */}
            {id &&
                (!spectaclesStore.spectacle ||
                    (spectaclesStore.spectacle &&
                        Object.keys(spectaclesStore.spectacle).length ===
                            0)) && (
                    <>
                        <div className={commonStyles.title_block}>
                            <Button
                                type="button"
                                theme="text"
                                size="small"
                                iconClass={"mdi mdi-arrow-left"}
                                isIconBtn={true}
                                aria-label="Назад"
                                onClick={() => navigate("/user/spectacles")}
                            />
                            <h1 className={commonStyles.title}>
                                Постановки не найдено
                            </h1>
                        </div>
                    </>
                )}
            {/* Новый спектакль */}
            {!id && (
                <>
                    <div className={commonStyles.title_block}>
                        <Button
                            type="button"
                            theme="text"
                            size="small"
                            iconClass={"mdi mdi-arrow-left"}
                            isIconBtn={true}
                            aria-label="Назад"
                            onClick={() => navigate("/user/spectacles")}
                        />
                        <h1 className={commonStyles.title}>
                            Создание постановки
                        </h1>
                    </div>
                    <form
                        onSubmit={handleSubmit(onAddNewSpectacle)}
                        className="form"
                    >
                        <Tabs>
                            <Tab title={"Основная информация"}>
                                <fieldset className="form__section">
                                    <FieldInput
                                        label={"Доступна для показа?"}
                                        type={"checkbox_variant"}
                                        {...register("active", {
                                            value: true,
                                        })}
                                    />
                                    <FieldInput
                                        label={"Название постановки*"}
                                        type="text"
                                        layout="flex"
                                        required={true}
                                        placeholder={"Введите название"}
                                        {...register("title")}
                                    />
                                    <div className="form__multy-block">
                                        <p className="form__label">
                                            Школьный театр*
                                        </p>
                                        <MultiSelect
                                            required={true}
                                            control={control}
                                            isMulti={false}
                                            name={"theatre_select"}
                                            closeMenuOnSelect={true}
                                            options={theatresStore.theatres.map(
                                                (item) => {
                                                    return {
                                                        label: item.title,
                                                        value: item.ID,
                                                    };
                                                }
                                            )}
                                        />
                                    </div>
                                    <div className="form__multy-block">
                                        <p className="form__label">
                                            Педагоги*
                                        </p>
                                        <MultiSelect
                                            required={true}
                                            control={control}
                                            isMulti={true}
                                            name={"teachers_select"}
                                            closeMenuOnSelect={false}
                                            options={teachersStore.teachers?.map(
                                                (item) => {
                                                    return {
                                                        label: `${item.f} ${item.i} ${item.o}`,
                                                        value: item.ID,
                                                    };
                                                }
                                            )}
                                        />
                                    </div>
                                    <div className="form__multy-block">
                                        <p className="form__label">
                                            Возрастной состав участников
                                            спектакля*
                                        </p>
                                        <MultiSelect
                                            required={true}
                                            control={control}
                                            isMulti={true}
                                            name={"age_members_select"}
                                            closeMenuOnSelect={false}
                                            options={theatresStore.ageMembers.map(
                                                (item) => {
                                                    return {
                                                        label: item,
                                                        value: item,
                                                    };
                                                }
                                            )}
                                        />
                                    </div>
                                    <FieldInput
                                        label={
                                            "Планируемая продолжительность, минут*"
                                        }
                                        type="number"
                                        layout="flex"
                                        min={0}
                                        required={true}
                                        {...register("duration")}
                                    />
                                    <div className="form__multy-block">
                                        <p className="form__label">Описание</p>
                                        <Editor
                                            control={control}
                                            name="editorReview"
                                            minHeight={250}
                                        />
                                    </div>
                                </fieldset>
                            </Tab>
                            <Tab
                                title={"Афиша и программка"}
                                event={"supportive_waist"}
                            >
                                <fieldset className="form__section">
                                    <FileSelector
                                        title="Афиша"
                                        items={posters}
                                        multiFiles={true}
                                        portrait={true}
                                        accept={"image/*"}
                                        onChange={(items) => setPosters(items)}
                                    />
                                    <FileSelector
                                        title="Программка"
                                        items={files}
                                        multiFiles={true}
                                        portrait={true}
                                        accept={"image/*,application/pdf"}
                                        onChange={(items) => setFiles(items)}
                                    />
                                </fieldset>
                            </Tab>
                            <Tab
                                title={"Фотографии"}
                                event={"supportive_waist"}
                            >
                                <fieldset className="form__section">
                                    <ImageSelector
                                        items={photo}
                                        multiFiles={true}
                                        onChange={(items) => setPhoto(items)}
                                    />
                                </fieldset>
                            </Tab>
                            <Tab
                                title={"Видео"}
                                event={"supportive_waist"}
                            >
                                <h2 className={styles.title}>
                                    Видео визитка
                                    <small> (для публичной страницы)</small>
                                </h2>
                                <fieldset className="form__section">
                                    {video.map((item) => (
                                        <div
                                            className="form__group-block"
                                            key={_uniqueId('prefix-')}
                                        >
                                            <FieldInput
                                                type="text"
                                                extraClass="form__field"
                                                placeholder="Введите url-адрес..."
                                                {...register(
                                                    "video_" + _uniqueId('prefix-'),
                                                    {
                                                        value: item.url,
                                                    }
                                                )}
                                                onBlur={(event) => {
                                                    setVideo(
                                                        video.map((link) => {
                                                            if (
                                                                link.id ===
                                                                item.id
                                                            ) {
                                                                link.url =
                                                                    event.target.value;
                                                            }

                                                            return link;
                                                        })
                                                    );
                                                    setValue(
                                                        "video_" + item.id,
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                            {item.url && (
                                                <a
                                                    className="form__social-link"
                                                    href={
                                                        item.url.includes(
                                                            "http"
                                                        )
                                                            ? item.url
                                                            : "http://" +
                                                            item.url
                                                    }
                                                    aria-label="Открыть в новой вкладке"
                                                    title="Открыть в новой вкладке"
                                                    target={"_blank"}
                                                    rel="nofollow noreferrer noopener"
                                                >
                                                    <span className="mdi mdi-open-in-new" />
                                                </a>
                                            )}
                                            <Button
                                                type="button"
                                                theme="text"
                                                size="smaller"
                                                extraClass="form__icon-btn"
                                                iconClass={"mdi mdi-close"}
                                                isIconBtn="true"
                                                aria-label="Удалить поле"
                                                onClick={() => {
                                                    setVideo(
                                                        video.filter(
                                                            (link) =>
                                                                link.id !==
                                                                item.id
                                                        )
                                                    );
                                                }}
                                            />
                                        </div>
                                    ))}
                                    <Button
                                        type="button"
                                        theme="text"
                                        size="small"
                                        extraClass="form__icon-btn"
                                        iconClass={"mdi mdi-plus"}
                                        isIconBtn="true"
                                        aria-label="Добавить поле"
                                        onClick={handleVideoLink}
                                    />
                                </fieldset>

                                <h2 className={styles.title}>
                                    Видео фрагмент
                                    <small> (для участия в фестивалях)</small>
                                </h2>
                                <fieldset className="form__section">
                                    <FieldInput
                                        type="text"
                                        extraClass="form__field"
                                        placeholder={"Введите url-адрес..."}
                                        {...register("videoFragment")}
                                    />
                                </fieldset>
                            </Tab>
                        </Tabs>
                        <div className="form__controls">
                            <Button
                                type="submit"
                                theme="primary"
                                text="Сохранить"
                                spinnerActive={spectaclesStore.sending}
                            />
                            <Button
                                type="button"
                                theme="text"
                                text="Отмена"
                                onClick={() => navigate("/user/spectacles")}
                                spinnerActive={spectaclesStore.sending}
                            />
                        </div>
                    </form>
                </>
            )}
            {/* Редактировать спектакль */}
            {id && edit && (
                <>
                    <div className={commonStyles.title_block}>
                        <Button
                            type="button"
                            theme="text"
                            size="small"
                            iconClass={"mdi mdi-arrow-left"}
                            isIconBtn={true}
                            aria-label="Назад"
                            onClick={() => setEdit(false)}
                        />
                        <h1 className={commonStyles.title}>
                            Редактирование постановки
                        </h1>
                    </div>
                    <form
                        onSubmit={handleSubmit(onEditSpectacle)}
                        className="form"
                    >
                        <Tabs>
                            <Tab title={"Основная информация"}>
                                <fieldset className="form__section">
                                    <FieldInput
                                        label={"Доступна для показа?"}
                                        type={"checkbox_variant"}
                                        {...register("active", {
                                            value:
                                                spectaclesStore.spectacle
                                                    .active === "Активен",
                                        })}
                                    />
                                    <FieldInput
                                        label={"Название постановки*"}
                                        type="text"
                                        layout="flex"
                                        required={true}
                                        placeholder={"Введите название"}
                                        {...register("title", {
                                            value: spectaclesStore.spectacle
                                                .title,
                                        })}
                                    />
                                    <div className="form__multy-block">
                                        <p className="form__label">
                                            Школьный театр*
                                        </p>
                                        <MultiSelect
                                            required={true}
                                            control={control}
                                            isMulti={false}
                                            name={"theatre_select"}
                                            closeMenuOnSelect={true}
                                            options={theatresStore.theatres.map(
                                                (item) => {
                                                    return {
                                                        label: item.title,
                                                        value: item.ID,
                                                    };
                                                }
                                            )}
                                            values={{
                                                label: spectaclesStore.spectacle
                                                    .theatreTitle,
                                                value: spectaclesStore.spectacle
                                                    .theatreID,
                                            }}
                                        />
                                    </div>
                                    <div className="form__multy-block">
                                        <p className="form__label">
                                            Педагоги*
                                        </p>
                                        <MultiSelect
                                            required={true}
                                            control={control}
                                            isMulti={true}
                                            name={"teachers_select"}
                                            closeMenuOnSelect={false}
                                            options={teachersStore.teachers?.map(
                                                (item) => {
                                                    return {
                                                        label: `${item.f} ${item.i} ${item.o}`,
                                                        value: item.ID,
                                                    };
                                                }
                                            )}
                                            values={spectaclesStore.spectacle.teachers?.map(
                                                (item) => {
                                                    return {
                                                        label: item.fio,
                                                        value: item.ID,
                                                    };
                                                }
                                            )}
                                        />
                                    </div>
                                    <div className="form__multy-block">
                                        <p className="form__label">
                                            Возрастной состав участников
                                            спектакля*
                                        </p>
                                        <MultiSelect
                                            required={true}
                                            control={control}
                                            isMulti={true}
                                            name={"age_members_select"}
                                            closeMenuOnSelect={false}
                                            options={theatresStore.ageMembers.map(
                                                (item) => {
                                                    return {
                                                        label: item,
                                                        value: item,
                                                    };
                                                }
                                            )}
                                            values={spectaclesStore.spectacle.age_members?.map(
                                                (item) => {
                                                    return {
                                                        label: item.age,
                                                        value: item.age,
                                                    };
                                                }
                                            )}
                                        />
                                    </div>
                                    <FieldInput
                                        label={
                                            "Планируемая продолжительность, минут*"
                                        }
                                        type="number"
                                        layout="flex"
                                        min={0}
                                        required={true}
                                        {...register("duration", {
                                            value: spectaclesStore.spectacle
                                                .duration,
                                        })}
                                    />
                                    <div className="form__multy-block">
                                        <p className="form__label">Описание</p>
                                        <Editor
                                            control={control}
                                            name="editorReview"
                                            minHeight={250}
                                        />
                                    </div>
                                </fieldset>
                            </Tab>
                            <Tab
                                title={"Афиша и программка"}
                                event={"supportive_waist"}
                            >
                                <fieldset className="form__section">
                                    <FileSelector
                                        title="Афиша"
                                        items={posters}
                                        multiFiles={true}
                                        portrait={true}
                                        accept={"image/*"}
                                        onChange={(items) => setPosters(items)}
                                        onDelete={handleDeletePosterPhoto}
                                    />
                                    <FileSelector
                                        title="Программка"
                                        items={files}
                                        multiFiles={true}
                                        portrait={true}
                                        accept={"image/*,application/pdf"}
                                        onChange={(items) => setFiles(items)}
                                        onDelete={handleDeletePosterFile}
                                    />
                                </fieldset>
                            </Tab>
                            <Tab
                                title={"Фотографии"}
                                event={"supportive_waist"}
                            >
                                <fieldset className="form__section">
                                    <ImageSelector
                                        items={photo}
                                        multiFiles={true}
                                        onChange={(items) => setPhoto(items)}
                                        onDelete={handleDeletePhoto}
                                    />
                                </fieldset>
                            </Tab>
                            <Tab
                                title={"Видео"}
                                event={"supportive_waist"}
                            >
                                <h2 className={styles.title}>
                                    Видео визитка
                                    <small> (для публичной страницы)</small>
                                </h2>
                                <fieldset className="form__section">
                                    {video.map((item) => (
                                        <div
                                            className="form__group-block"
                                            key={item.id}
                                        >
                                            <FieldInput
                                                type="text"
                                                extraClass="form__field"
                                                placeholder="Введите url-адрес..."
                                                {...register(
                                                    "video_" + item.id,
                                                    {
                                                        value: item.url,
                                                    }
                                                )}
                                                onBlur={(event) => {
                                                    setVideo(
                                                        video.map((link) => {
                                                            if (
                                                                link.id ===
                                                                item.id
                                                            ) {
                                                                link.url =
                                                                    event.target.value;
                                                            }

                                                            return link;
                                                        })
                                                    );
                                                    setValue(
                                                        "video_" + item.id,
                                                        event.target.value
                                                    );
                                                }}
                                                required={true}
                                            />
                                            {item.url && (
                                                <a
                                                    className="form__social-link"
                                                    href={
                                                        item.url.includes(
                                                            "http"
                                                        )
                                                            ? item.url
                                                            : "http://" +
                                                              item.url
                                                    }
                                                    aria-label="Открыть в новой вкладке"
                                                    title="Открыть в новой вкладке"
                                                    target={"_blank"}
                                                    rel="nofollow noreferrer noopener"
                                                >
                                                    <span className="mdi mdi-open-in-new" />
                                                </a>
                                            )}
                                            <Button
                                                type="button"
                                                theme="text"
                                                size="smaller"
                                                extraClass="form__icon-btn"
                                                iconClass={"mdi mdi-close"}
                                                isIconBtn="true"
                                                aria-label="Удалить поле"
                                                onClick={() => {
                                                    setVideo(
                                                        video.filter(
                                                            (link) =>
                                                                link.id !==
                                                                item.id
                                                        )
                                                    );
                                                }}
                                            />
                                        </div>
                                    ))}
                                    <Button
                                        type="button"
                                        theme="text"
                                        size="small"
                                        extraClass="form__icon-btn"
                                        iconClass={"mdi mdi-plus"}
                                        isIconBtn="true"
                                        aria-label="Добавить поле"
                                        onClick={handleVideoLink}
                                    />
                                </fieldset>
                                <h2 className={styles.title}>
                                    Видео фрагмент
                                    <small> (для участия в фестивалях)</small>
                                </h2>
                                <fieldset className="form__section">
                                    <FieldInput
                                        type="text"
                                        extraClass="form__field"
                                        placeholder={"Введите url-адрес..."}
                                        {...register("videoFragment", {
                                            value: spectaclesStore.spectacle
                                                .videoFragment,
                                        })}
                                    />
                                </fieldset>
                            </Tab>
                        </Tabs>
                        <div className="form__controls">
                            <Button
                                type="submit"
                                theme="primary"
                                text="Сохранить"
                                spinnerActive={spectaclesStore.sending}
                            />
                            <Button
                                type="button"
                                theme="text"
                                text="Удалить"
                                onClick={handleDelete}
                                spinnerActive={spectaclesStore.sending}
                            />
                            <Button
                                type="button"
                                theme="text"
                                text="Отмена"
                                onClick={() => setEdit(false)}
                                spinnerActive={spectaclesStore.sending}
                            />
                        </div>
                    </form>
                </>
            )}
            {popup}
        </>
    );
};

export default MySpectaclePage;

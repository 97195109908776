import React from 'react';
import {useNavigate} from "react-router-dom";

import useAuthStore from "../../../store/authStore";
import useFestivalsStore from "../../../store/user/festivalsStore";

import Table from "../../../components/table/table.component";

const UserFestivalRequestsPage = () => {

    const navigate = useNavigate();
    const { user } = useAuthStore();
    const festivalStore = useFestivalsStore();

    const onItemClick = (props) => {
        navigate(`/user/festivalRequests/${props}`);
    };

    const fetchData = async () => {
        await festivalStore.loadFestivalRequests({id: user.schoolID});
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    const itemConfig = [
        {
            header: "ID",
            key: "ID",
            type: "int",
            filter: "number",
            sorting: true,
        },
        {
            header: "Фестиваль",
            key: "festivalTitle",
            type: "string",
            filter: "string",
            sorting: true,
        },
        {
            header: "Направление",
            key: "directionTitle",
            type: "string",
            filter: "string",
            sorting: true,
        },
        {
            header: "Название театра",
            key: "theatreTitle",
            type: "string",
            filter: "string",
            sorting: true,
        },
        {
            header: "Название постановки",
            key: "spectacleTitle",
            type: "string",
            filter: "string",
            sorting: true,
        },
        {
            header: "Дата подачи",
            key: "create_time",
            type: "datetime",
            filter: "date",
            sorting: true,
        },
        {
            header: "Дата обновления",
            key: "update_time",
            type: "datetime",
            filter: "date",
            sorting: true,
        },
        {
            header: "Статус",
            key: "status",
            type: "string",
            filter: "select",
            sorting: true,
        },
    ];

    return (
        <Table
            title={"Таблица заявок на фестивали пользователя" + user.ID}
            loading={festivalStore.loading}
            items={festivalStore.festivalRequests}
            itemsConfig={itemConfig}
            onItemClick={onItemClick}
            withFilter={true}
        >
            {/*<Button*/}
            {/*    type="button"*/}
            {/*    iconClass={"mdi mdi-plus"}*/}
            {/*    size="small"*/}
            {/*    text="Подать заявку"*/}
            {/*    aria-label="Подать заявку"*/}
            {/*    onClick={() => navigate("/user/festivalRequests/new")}*/}
            {/*/>*/}
        </Table>
    );
};

export default UserFestivalRequestsPage;
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import JoditEditor from "jodit-react";
import moment from "moment";

import useFestivalsStore from "../../../store/admin/festivalsStore";
import useSpectaclesStore from "../../../store/admin/spectaclesStore";

import Notif from "../../../components/notif/notif.component";
import Button from "../../../components/button/button.component";
import Accordion from "../../../components/accordion/accordion.component";
import ImageGallery from "../../../components/image_gallery/image.gallery.component";
import VideoPlayer from "../../../components/video_player/video.player.component";
import Editor from "../../../components/reach_editor/editor.component";
import Popup from "../../../components/popup/popup.component";

import commonStyles from "../../common.module.scss";

const AdminFestivalRequestPage = () => {
    let { id } = useParams();
    const navigate = useNavigate();
    const {handleSubmit, control} = useForm();

    const festivalStore = useFestivalsStore();
    const spectaclesStore = useSpectaclesStore();

    const [popup, setPopup] = React.useState(<></>);

    const fetchData = async () => {
        const festivalRequest = await festivalStore.loadFestivalRequest({ id });
        const spectacle = await spectaclesStore.loadSpectacle({ id: festivalRequest.spectacleID });

        console.clear();
        console.log(festivalRequest);
        // console.log(spectacle);

        if (festivalRequest.status === "Новая")
            await festivalStore.festivalRequestChangeNew({ id });

    };

    React.useEffect(() => {
        if (festivalStore.error) {
            setPopup(
                <Notif
                    title='Ошибка!'
                    text={festivalStore.errorText}
                    opened={true}
                    onClose={() => {
                        festivalStore.clearErrorText();
                        setPopup(<></>);
                    }}
                />
            );
        }
    }, [festivalStore.error]);

    React.useEffect(() => {
        fetchData();
    }, [id]);

    const back = () => navigate("/admin/festivalRequests");

    const getStatusText = (value) => {
        switch (value) {
            case "Новая":
                return <p className='request-status --status-new'>Новая</p>;
            case "Принята":
                return <p className='request-status --status-accept'>Принята</p>;
            case "Отклонена":
                return <p className='request-status --status-decline'>Отклонена</p>;
            case "Отозвана":
                return <p className='request-status --status-callback'>Отозвана</p>;
            case "Рассмотрение":
                return <p className='request-status --status-review'>Рассмотрение</p>;
            default:
                return <p className='request-status --status-accept'>{value}</p>;
        }
    };

    const onRequestStatusChangeWithComment = async (status, title) => {
        const onSendSubmit = async (text) => {
            const params = {
                id: id,
                status: status,
                text: text.editorText
            };

            const result = await festivalStore.setRequestStatus(params);

            if (!result.error) {
                back();
            }
        };

        setPopup(
            <Popup
                title={title}
                opened={true}
                onClose={() => {
                    setPopup(<></>);
                }}
            >
                <form onSubmit={handleSubmit(onSendSubmit)} className='form'>
                    <fieldset className='form__section --content-info'>
                        <Editor required={true} control={control} name='editorText' />
                    </fieldset>
                    <div className='form__controls'>
                        <Button
                            type='submit'
                            text='Отправить'
                            spinnerActive={festivalStore.sending}
                            style={{ marginLeft: "auto", display: "block" }}
                        />
                    </div>
                </form>
            </Popup>
        );
    };

    const onRequestStatusChange = async (status, text) => {
        const params = {
            id: id,
            status: status,
            text: text,
        };

        const result = await festivalStore.setRequestStatus(params);

        if (!result.error) {
            back();
        }
    };

    if (festivalStore.loading || spectaclesStore.loading) return <p>Загрузка...</p>;

    return (
        <>
            {id && festivalStore.festivalRequest && Object.keys(festivalStore.festivalRequest).length > 0 && (
                <>
                    <div className={commonStyles.title_block}>
                        <Button
                            type='button'
                            theme='text'
                            size='small'
                            iconClass={"mdi mdi-arrow-left"}
                            isIconBtn='true'
                            aria-label='Назад'
                            onClick={back}
                        />
                        <h1 className={commonStyles.title}>Заявка ID: {id}</h1>
                    </div>
                    <div className='request-card'>
                        <div className='request-card__section --content-main-info'>
                            <>{getStatusText(festivalStore.festivalRequest.status)}</>
                            {festivalStore.festivalRequest.decline_text !== "" && (
                                <Accordion title={"Комментарий"}>
                                    <JoditEditor
                                        config={{
                                            readonly: true,
                                            toolbar: false,
                                        }}
                                        value={festivalStore.festivalRequest.decline_text}
                                    />
                                </Accordion>
                            )}
                            <ul className='request-card__dates'>
                                <li>
                                    <p className='request-card__date'>
                                        Год проведения фестиваля (сезон):
                                        <span className='request-card__date-accent'>
                                            {" "}
                                            {festivalStore.festivalRequest.performance_season}
                                        </span>
                                    </p>
                                </li>
                                <li>
                                    <p className='request-card__date'>
                                        Дата подачи:
                                        <span className='request-card__date-accent'>
                                            {" "}
                                            {moment(festivalStore.festivalRequest.create_time).format(
                                                "HH:mm DD.MM.YYYY"
                                            )}
                                        </span>
                                    </p>
                                </li>
                                <li>
                                    <p className='request-card__date'>
                                        Дата обновления:
                                        <span className='request-card__date-accent'>
                                            {" "}
                                            {moment(festivalStore.festivalRequest.update_time).format(
                                                "HH:mm DD.MM.YYYY"
                                            )}
                                        </span>
                                    </p>
                                </li>
                            </ul>
                            <div>
                                <p>
                                    Фестиваль: <b>{festivalStore.festivalRequest.festivalTitle}</b>
                                </p>
                                <p>
                                    Направление: <b>{festivalStore.festivalRequest.directionTitle}</b>
                                </p>
                                <p>
                                    Театр: <b>{festivalStore.festivalRequest.theatreTitle}</b>
                                </p>
                                <br/>
                                {festivalStore.festivalRequest.spectacleTitle && (
                                    <p>
                                        Постановка: <b>{festivalStore.festivalRequest.spectacleTitle}</b>
                                    </p>
                                )}
                                {festivalStore.festivalRequest.performance_count > 0 && (
                                    <p>
                                        Количество участников творческого коллектива:{" "}
                                        <b>{festivalStore.festivalRequest.performance_count}</b>
                                    </p>
                                )}
                                {festivalStore.festivalRequest.performance_author && (
                                    <p>
                                        Автор: <b>{festivalStore.festivalRequest.performance_author}</b>
                                    </p>
                                )}
                                {festivalStore.festivalRequest.performance_book && (
                                    <p>
                                        Название литературного материала:{" "}
                                        <b>{festivalStore.festivalRequest.performance_book}</b>
                                    </p>
                                )}
                                {festivalStore.festivalRequest.performance_producer && (
                                    <p>
                                        ФИО режиссера-постановщика:{" "}
                                        <b>{festivalStore.festivalRequest.performance_producer}</b>
                                    </p>
                                )}
                                {festivalStore.festivalRequest.performance_age && (
                                    <p>
                                        Возрастная категория: <b>{festivalStore.festivalRequest.performance_age}</b>
                                    </p>
                                )}
                                {festivalStore.festivalRequest.performance_nomination && (
                                    <p>
                                        Номинация: <b>{festivalStore.festivalRequest.performance_nomination}</b>
                                    </p>
                                )}
                                {spectaclesStore.spectacle.duration > 0 && (
                                    <p>
                                        Планируемая продолжительность, минут:{" "}
                                        <b>{spectaclesStore.spectacle.duration}</b>
                                    </p>
                                )}
                                {festivalStore.festivalRequest.performance_file && (
                                    <p>
                                        Ответы на вопросы:{" "}
                                        <a
                                            target={"_blank"}
                                            href={
                                                process.env.REACT_APP_BASE_URL +
                                                festivalStore.festivalRequest.performance_file
                                            }
                                        >
                                            <b>Ссылка</b>
                                        </a>
                                    </p>
                                )}
                            </div>
                            {
                                festivalStore.festivalRequest.directionTitle === "Школьная Мельпомена" && (
                                    <div className='request-card__controls'>
                                        <Button
                                            type="button"
                                            theme="outline"
                                            text="Лауреат"
                                            spinnerActive={festivalStore.sending}
                                            onClick={() => onRequestStatusChange(6, '')}
                                        />
                                        <Button
                                            type="button"
                                            theme="outline"
                                            text="Финалист"
                                            spinnerActive={festivalStore.sending}
                                            onClick={() => onRequestStatusChange(7, '')}
                                        />
                                        <Button
                                            type="button"
                                            theme="outline"
                                            text="Победитель"
                                            spinnerActive={festivalStore.sending}
                                            onClick={() => onRequestStatusChangeWithComment(8, 'Укажите комментарий')}
                                        />
                                        <Button
                                            type="button"
                                            theme="outline"
                                            text="Спецприз"
                                            spinnerActive={festivalStore.sending}
                                            onClick={() => onRequestStatusChangeWithComment(9, 'Укажите комментарий')}
                                        />
                                    </div>
                                )
                            }
                            <div className='request-card__controls'>
                                <Button
                                    type="button"
                                    theme="primary"
                                    text="Принять"
                                    spinnerActive={festivalStore.sending}
                                    onClick={() => onRequestStatusChange(3, '')}
                                />
                                <Button
                                    type="button"
                                    theme="primary"
                                    text="Отклонить"
                                    spinnerActive={festivalStore.sending}
                                    onClick={() => onRequestStatusChangeWithComment(4, 'Укажите причину отклонения заявки')}
                                />
                            </div>
                        </div>
                        {festivalStore.festivalRequest.spectacleID !== 0 && (
                            <div className='request-card__section --content-video'>
                                <h2 className='request-card__subtitle'>Афиша</h2>
                                <ImageGallery items={spectaclesStore.spectacle.posters} front={false}/>
                                <h2 className='request-card__subtitle'>Видео фрагмент</h2>
                                {spectaclesStore.spectacle.videoFragment ? (
                                    <ul className='gallery-form'>
                                        <li className='gallery-form__item'>
                                        <VideoPlayer source={spectaclesStore.spectacle.videoFragment}/>
                                        </li>
                                    </ul>
                                ) : (
                                    <p>Нет видео</p>
                                )}
                            </div>
                        )}
                    </div>
                </>
            )}
            {id &&
                (!festivalStore.festivalRequest ||
                    (festivalStore.festivalRequest && Object.keys(festivalStore.festivalRequest).length === 0)) && (
                    <>
                        <div className={commonStyles.title_block}>
                            <Button
                                type='button'
                                theme='text'
                                size='small'
                                iconClass={"mdi mdi-arrow-left"}
                                isIconBtn={true}
                                aria-label='Назад'
                                onClick={back}
                            />
                            <h1 className={commonStyles.title}>Не удалось найти заявку № {id}</h1>
                        </div>
                    </>
                )}
            {popup}
        </>
    );
};

export default AdminFestivalRequestPage;

import React from "react";
import { Outlet } from "react-router-dom";

import Header from "../components/header/header.component";
import ProfileHeader from "../components/header/profile.header.component";
import SupportHeaderComponent from "../components/header/support.header.component";
import Menu from "../components/menu/menu.component";

import { MenuIcons } from "../components/svgs.js";
import styles from "./admin.module.scss";

const UserLayout = () => {
    const [burgerOpened, setBurgerOpened] = React.useState(false);

    const menu = [
        {
            title: "Моя школа",
            icon: MenuIcons.my_school,
            link: "/user/my_school",
        },
        {
            title: "Мой театр",
            icon: MenuIcons.theatres,
            link: "/user/theatres",
        },
        {
            title: "Мои постановки",
            icon: MenuIcons.spectacles,
            link: "/user/spectacles",
        },
        {
            title: "Расписания постановок",
            icon: MenuIcons.spectacles_schedules,
            link: "/user/spectacleSchedules",
        },
        {
            title: "Заявки на театры",
            icon: MenuIcons.requests,
            link: "/user/theatreRequests",
        },
        // {
        //     title: "Фестивали",
        //     icon: MenuIcons.festival,
        //     link: "/user/festivals",
        // },
        {
            title: "Заявки на фестивали",
            icon: MenuIcons.festivals_requests,
            link: "/user/festivalRequests",
        },
        {
            title: "Педагоги",
            icon: MenuIcons.teachers,
            link: "/user/teachers",
        },
    ];

    const handleBurgerMenu = () => {
        setBurgerOpened(!burgerOpened);
    };

    return (
        <div className={styles.content}>
            <Header handleBurger={handleBurgerMenu}>
                <SupportHeaderComponent />
                <ProfileHeader />
            </Header>
            <Menu
                menu={menu}
                burgerOpened={burgerOpened}
                setBurgerOpened={handleBurgerMenu}
            />
            <main className={styles.main}>
                <Outlet />
            </main>
        </div>
    );
};

export default UserLayout;

import React from "react";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumbs.component";
import {motion} from "framer-motion";
import {Splide, SplideSlide} from "@splidejs/react-splide";
import Skeleton from "react-loading-skeleton";
import createDOMPurify from "dompurify";
import {useParams} from "react-router-dom";

import useAuthStore from "../../../store/authStore";
import useScenarioBankStore from "../../../store/public/scenarioBankStore";

import Button from "../../../components/button/button.component";
import Utils from "../../../components/utils.component";
import ImagePreview from "../../../components/image_preview/image.preview.component";

import styles from "./common.module.scss";
import commonStyles from "../common.module.scss";

const ScenarioBankPage = () => {
    const DOMPurify = createDOMPurify(window);

    const {id} = useParams();

    const {user} = useAuthStore();
    const scenarioBankStore = useScenarioBankStore();

    const fetchData = async () => {
        let result = await scenarioBankStore.load({id, auth: user.ID ? 1 : 0});
    };

    React.useEffect(() => {
        fetchData();
    }, [id]);

    // Private components
    const Loading = () => {
        return (
            <>
                {(scenarioBankStore.loading) && (
                    <section className={[commonStyles.wrap, commonStyles.wrap_bubble].join(" ")}>
                        <Skeleton className={`${commonStyles.title} ${commonStyles.title_underline}`} width={"50%"}/>
                        <div className={styles.columns}>
                            <div className={[styles.column, styles.column_description].join(" ")}>
                                <Skeleton className={styles.title} width={"60%"}/>
                                <ul className={`${styles.list} ${styles.list_column}`}>
                                    <Skeleton width={"60%"}/>
                                    <Skeleton width={"70%"}/>
                                    <Skeleton width={"40%"}/>
                                    <Skeleton width={"80%"}/>
                                    <Skeleton width={"80%"}/>
                                </ul>
                                <br/>
                                <Skeleton count={3}/>
                            </div>
                            <div className={[styles.column, styles.column_asside].join(" ")}>
                                <Skeleton className={styles.poster} height={"25vmax"}/>
                            </div>
                        </div>
                    </section>
                )}
            </>
        );
    };

    const NotFound = () => {
        return (
            <>
                {scenarioBankStore.loading === false &&
                    (scenarioBankStore.scenario == false || Object.keys(scenarioBankStore.scenario).length === 0) && (
                        <section className={[commonStyles.wrap, commonStyles.wrap_bubble].join(" ")}>
                            <div className={styles.section}>
                                <h2 className={[commonStyles.title, commonStyles.title_underline].join(" ")}>
                                    Сценарий не найден
                                </h2>
                            </div>
                        </section>
                    )}
            </>
        );
    };

    const Scenario = () => {

        const [preview, setPreview] = React.useState(<></>);

        const handleImagePreview = (images, index = 0) => {
            setPreview(<ImagePreview open={true} index={index} items={images} onClose={() => setPreview(<></>)}/>);
        };

        return (
            <>
                {scenarioBankStore.loading === false && scenarioBankStore.scenario &&
                    Object.keys(scenarioBankStore.scenario).length > 0 && (
                        <section className={`${commonStyles.wrap} ${commonStyles.wrap_bubble}`}>
                            <h2 className={`${commonStyles.title} ${commonStyles.title_underline}`}>
                                {scenarioBankStore.scenario.title}
                            </h2>
                            <div className={styles.columns}>
                                <div className={[styles.column, styles.column_description].join(" ")}>
                                    <h3 className={styles.title}>{scenarioBankStore.scenario.author} в
                                        адаптации {scenarioBankStore.scenario.adaptation_author}</h3>
                                    <ul className={[styles.list, styles.list_column, ""].join(" ")}>
                                        <li>
                                            Жанр:{" "} <span
                                            className={styles.listAccent}>{scenarioBankStore.scenario.genres.map(item => item.title).join(", ")}</span>
                                        </li>
                                        <li>
                                            Продолжительность: <span
                                            className={styles.listAccent}>{scenarioBankStore.scenario.duration}&nbsp;мин</span>
                                        </li>
                                        <li>
                                            Возраст актеров: <span
                                            className={styles.listAccent}>от {scenarioBankStore.scenario.actor_from} до {scenarioBankStore.scenario.actor_to}&nbsp;лет</span>
                                        </li>
                                        <li>
                                            Возраст аудитории: <span
                                            className={styles.listAccent}>от {scenarioBankStore.scenario.audience_age_from}&nbsp;лет</span>
                                        </li>
                                        <li>
                                            Кол-во ролей, персонажей: <span
                                            className={styles.listAccent}>{scenarioBankStore.scenario.roles_count}</span>
                                        </li>
                                    </ul>
                                    <div
                                        className={styles.description}
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(scenarioBankStore.scenario.short_description),
                                        }}
                                    />
                                    {
                                        scenarioBankStore.scenario.files.length > 0
                                        &&
                                        <>
                                            <ul className={styles.list}>
                                                {
                                                    scenarioBankStore.scenario.files.map(file => (
                                                        <li key={Utils.makeid(10)}>
                                                            <a className={styles.fileLink}
                                                               href={file.url.includes("http") ? file.url : process.env.REACT_APP_BASE_URL + file.url}
                                                               target={"_blank"} rel='noreferrer noopener nofollow'>
                                                                {Utils.getFileIcon(file.title)} {file.description ? file.description : file.title}
                                                            </a>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                            <Button
                                                type='button'
                                                theme={"info_outline"}
                                                style={{alignSelf: "flex-start", marginTop: "1em"}}
                                                text={"Скачать все файлы"}
                                                onClick={() => {
                                                    scenarioBankStore.loadAllFiles({
                                                        id,
                                                        auth: user.ID ? 1 : 0,
                                                        title: scenarioBankStore.scenario.title
                                                    });
                                                }}
                                            />
                                        </>
                                    }
                                </div>
                                {
                                    scenarioBankStore.scenario.images.length > 0
                                    &&
                                    <div className={[styles.column, styles.column_asside].join(" ")}>
                                        <Splide
                                            className='my-splide my-splide_border-radius-sm my-splide_handle-preview'
                                            options={{
                                                type: scenarioBankStore.scenario.images.length > 1 ? "loop" : "slide",
                                                arrows: false,
                                                perPage: 1,
                                                perMove: 1,
                                                gap: "1.25em",
                                                rewind: true,
                                                pauseOnHover: true,
                                                autoplay: true,
                                                mediaQuery: "min",
                                            }}
                                        >
                                            {
                                                scenarioBankStore.scenario.images.map((image, index) => (
                                                    <SplideSlide
                                                        key={Utils.makeid(10)}
                                                        data-splide-interval='5000'
                                                        onClick={() => {
                                                            handleImagePreview(scenarioBankStore.scenario.images, index);
                                                        }}
                                                    >
                                                        <img
                                                            className={styles.poster}
                                                            src={Utils.getUrl(image.url)}
                                                            alt={"Изображение " + image.ID}
                                                        />
                                                    </SplideSlide>
                                                ))
                                            }
                                        </Splide>
                                    </div>
                                }
                            </div>
                        </section>
                    )}
                {preview}
            </>
        );
    };

    return (
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{delay: 0.2, duration: 1}}
        >
            <Breadcrumbs
                items={[
                    {
                        title: "Главная",
                        url: "/",
                    },
                    {
                        title: "Банк сценариев",
                        url: "/scenario_bank",
                    },
                    {
                        title:
                            "Сценарий " +
                            (Object.keys(scenarioBankStore.scenario).length > 0
                                ? scenarioBankStore.scenario.title
                                : " не найден"),
                        url: "",
                    },
                ]}
            />
            <Loading/>
            <NotFound/>
            <Scenario/>
        </motion.div>
    );
};

export default ScenarioBankPage;

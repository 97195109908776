import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import useSchoolStore from "../../../store/admin/schoolsStore";
import useTheatresStore from "../../../store/admin/theatresStore";
import useTeachersStore from "../../../store/admin/teachersStore";

import Theatre from "../../../components/page_components/theatre/theatre.component";

const TheatrePage = () => {
    let { id } = useParams();
    const navigate = useNavigate();

    const {
        theatre,
        theatreScore,
        loadTheatre,
        loadTheatreScore,
        loading,
        editTheatreScore
    } = useTheatresStore();
    const schoolStore = useSchoolStore();
    const teachersStore = useTeachersStore();

    React.useEffect(() => {
        fetchData();
    }, [id]);

    const fetchData = async () => {
        if (id) {
            let tempTheatre = await loadTheatre({ id });

            if (tempTheatre) {
                await schoolStore.loadSchool({ id: tempTheatre.schoolID });
                await teachersStore.loadTeachers({
                    schoolID: tempTheatre.schoolID,
                });
                await fetchScore();
            }
        }
    };

    const fetchScore = async () => {
        let score = await loadTheatreScore({ id });
    };

    const back = () => navigate("/admin/theatres/");

    const scoreSave = async (place, input) => {
        await editTheatreScore({ id, place, score: input, schoolID: theatre.schoolID });
        await fetchData();
    }

    if (loading || schoolStore.loading || teachersStore.loading)
        return <p>Загрузка...</p>;

    if (id && !theatre) {
        return <p>Театр не найден</p>;
    }

    return (
        <>
            {id && theatre && (
                <Theatre
                    id={id}
                    theatre={theatre}
                    teachersStore={teachersStore}
                    onBack={back}
                    totalScore={theatreScore}
                    scoreEdit={true}
                    onScoreSave={scoreSave}
                />
            )}
        </>
    );
};

export default TheatrePage;

import React from 'react';
import {useNavigate} from "react-router-dom";

import useSpectaclesStore from "../../../store/user/spectaclesStore";

import Table from "../../../components/table/table.component";
import Button from "../../../components/button/button.component";
import useAuthStore from "../../../store/authStore";

const UserSpectacleSchedulesPage = () => {

    const navigate = useNavigate();
    const { user } = useAuthStore();
    const spectaclesStore = useSpectaclesStore();

    const onItemClick = (props) => {
        navigate(`/user/spectacleSchedules/${props}`);
    };

    const fetchData = async () => {
        await spectaclesStore.loadSpectacleSchedules({schoolID: user.schoolID});
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    const itemConfig = [
        {
            header: "ID",
            key: "ID",
            type: "int",
            filter: "number",
            sorting: true,
        },
        {
            header: "Название театра",
            key: "theatreTitle",
            type: "string",
            filter: "string",
            sorting: true,
        },
        {
            header: "Название постановки",
            key: "spectacleTitle",
            type: "string",
            filter: "string",
            sorting: true,
        },
        {
            header: "Кол-во мест всего",
            key: "placeCount",
            type: "int",
            filter: "number",
            sorting: true,
        },
        {
            header: "Кол-во билетов всего",
            key: "ticketsCount",
            type: "int",
            filter: "number",
            sorting: true,
        },
        {
            header: "Кол-во оставшихся билетов",
            key: "ticketsLeft",
            type: "int",
            filter: "number",
            sorting: true,
        },
        {
            header: "Дата события",
            key: "date",
            type: "datetime",
            filter: "date",
            sorting: true,
        },
        {
            header: "В рамках фестиваля",
            key: "festival",
            type: "string",
            filter: "select",
            sorting: true,
        },
    ];

    return (
        <Table
            title={"Таблица расписаний на постановки пользователя" + user.ID}
            loading={spectaclesStore.loading}
            items={spectaclesStore.spectacleSchedules}
            itemsConfig={itemConfig}
            onItemClick={onItemClick}
            withFilter={true}
        >
            <Button
                type="button"
                iconClass={"mdi mdi-plus"}
                size="small"
                text="Добавить событие"
                aria-label="Добавить событие"
                onClick={() => navigate("/user/spectacleSchedules/new")}
            />
        </Table>
    );
};

export default UserSpectacleSchedulesPage;
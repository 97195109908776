import React from 'react';
import { useNavigate } from "react-router-dom";

import useFestivalsStore from "../../../store/user/festivalsStore";
import useAuthStore from "../../../store/authStore";

import Tabs from "../../../components/tabs/tabs.component";
import Tab from "../../../components/tabs/tab.component";
import Table from "../../../components/table/table.component";

const UserFestivalsPage = () => {

    const { user } = useAuthStore();

    const navigate = useNavigate();
    const festivalStore = useFestivalsStore();

    const onItemClick = (props) => {
        //navigate(`/user/festivals/${props}`);
    };

    const fetchData = async () => {

        await festivalStore.loadFestivals({});

    };

    React.useEffect(() => {

        fetchData();

    }, []);

    const itemConfig = [
        {
            header: "ID",
            key: "ID",
            type: "int",
            filter: "number",
            sorting: true,
        },
        {
            header: "Название фестиваля",
            key: "title",
            type: "string",
            filter: "string",
            sorting: true,
        },
        {
            header: "Статус",
            key: "active",
            type: "string",
            filter: "select",
            sorting: true,
        },
    ];

    return (<>
            <Tabs>
                <Tab title={"Фестивали"}>
                    <Table
                        title={"Таблица фестивалей пользователя" + user.ID}
                        loading={festivalStore.loading}
                        items={festivalStore.festivals}
                        itemsConfig={itemConfig}
                        onItemClick={onItemClick}
                        withFilter={true}
                    />
                </Tab>
            </Tabs>
        </>
    );
};

export default UserFestivalsPage;
import React from "react";
import { useNavigate } from "react-router-dom";

import useAuthStore from "../../../store/authStore";
import useSpectaclesStore from "../../../store/user/spectaclesStore";

import Button from "../../../components/button/button.component";
import Table from "../../../components/table/table.component";

const MySpectaclesPage = () => {

    const navigate = useNavigate();

    const { user } = useAuthStore();
    const { loading, spectacles, loadSpectacles } = useSpectaclesStore();

    const onItemClick = (props) => {
        navigate(`/user/spectacles/${props}`);
    };

    const fetchData = async () => {

        await loadSpectacles({schoolID: user.schoolID});

    };

    React.useEffect(() => {
        fetchData();
    }, []);

    const itemConfig = [
        {
            header: "ID",
            key: "ID",
            type: "int",
            filter: "number",
            sorting: true,
        },
        {
            header: "Театр",
            key: "theatreTitle",
            type: "string",
            filter: "select",
            sorting: true,
        },
        {
            header: "Спектакль",
            key: "title",
            type: "string",
            filter: "string",
            sorting: true,
        },
        {
            header: "Статус",
            key: "active",
            type: "string",
            filter: "select",
            sorting: true,
        },
    ];

    return (
        <>
            <Table
                title={"Таблица постановок пользователя" + user.ID}
                loading={loading}
                items={spectacles}
                itemsConfig={itemConfig}
                onItemClick={onItemClick}
                withFilter={true}
            >
                <Button
                    type="button"
                    iconClass={"mdi mdi-plus"}
                    text="Создать"
                    size="small"
                    aria-label="Создать"
                    onClick={() => navigate("/user/spectacles/new")}
                />
            </Table>
        </>
    );
};

export default MySpectaclesPage;

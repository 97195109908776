import React from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import createDOMPurify from "dompurify";
import moment from "moment";

import useScenarioBankStore from "../../../store/admin/scenarioBankStore";

import Button from "../../../components/button/button.component";
import Tabs from "../../../components/tabs/tabs.component";
import Tab from "../../../components/tabs/tab.component";
import ImageGallery from "../../../components/image_gallery/image.gallery.component";
import Notif from "../../../components/notif/notif.component";
import FieldInput from "../../../components/field/field.input.component";
import Editor from "../../../components/reach_editor/editor.component";
import FileSelector from "../../../components/file_selector/file.selector.component";
import ImageSelector from "../../../components/image_selector/image.selector.component";

import commonStyles from "../../common.module.scss";
import styles from "../../../components/page_components/theatre/theatre.module.scss";
import FileGallery from "../../../components/file_gallery/file.gallery.component";
import MultiSelect from "../../../components/multi_select/multi_select.component";

const ScenarioPage = () => {
    let { id } = useParams();
    const navigate = useNavigate();
    const DOMPurify = createDOMPurify(window);
    const { register, handleSubmit, reset, control, setValue, getValues } = useForm();

    const scenarioBankStore = useScenarioBankStore();

    const [edit, setEdit] = React.useState(false);

    const genres = [
        {
            label: "Водевиль",
            value: "Водевиль",
        },
        {
            label: "Драма",
            value: "Драма",
        },
        {
            label: "Комедия",
            value: "Комедия",
        },
        {
            label: "Мелодрама",
            value: "Мелодрама",
        },
        {
            label: "Мим",
            value: "Мим",
        },
        {
            label: "Мистерия",
            value: "Мистерия",
        },
        {
            label: "Монодрама",
            value: "Монодрама",
        },
        {
            label: "Моралите",
            value: "Моралите",
        },
        {
            label: "Мюзикл",
            value: "Мюзикл",
        },
        {
            label: "Пародия",
            value: "Пародия",
        },
        {
            label: "Пастораль",
            value: "Пастораль",
        },
        {
            label: "Соти",
            value: "Соти",
        },
        {
            label: "Трагедия",
            value: "Трагедия",
        },
        {
            label: "Трагикомедия",
            value: "Трагикомедия",
        },
        {
            label: "Фарс",
            value: "Фарс",
        },
        {
            label: "Феерия",
            value: "Феерия",
        },
        {
            label: "Флиаки",
            value: "Флиаки",
        },
    ];

    const fetchData = async () => {
        await scenarioBankStore.load({ id });
    };

    React.useEffect(() => {
        fetchData();
    }, [id]);

    const back = () => navigate("/admin/scenarioBank");

    //Private component
    const Loading = () => {
        if (scenarioBankStore.loading) {
            return (
                <div className={commonStyles.title_block}>
                    <h1 className={commonStyles.title}>Загрузка...</h1>
                </div>
            );
        }
    };

    const NotFound = () => {
        if (id && !scenarioBankStore.loading && Object.keys(scenarioBankStore.scenario).length === 0) {
            return (
                <div className={commonStyles.title_block}>
                    <Button
                        type='button'
                        theme='text'
                        size='small'
                        iconClass={"mdi mdi-arrow-left"}
                        isIconBtn={true}
                        aria-label='Назад'
                        onClick={back}
                    />
                    <h1 className={commonStyles.title}>Сценарий не найдена</h1>
                </div>
            );
        }
    };

    const MainBlock = () => {
        const NewScenario = () => {
            const [photo, setPhoto] = React.useState([]);
            const [files, setFiles] = React.useState([]);
            const [popup, setPopup] = React.useState(<></>);
            const [sending, setSending] = React.useState(false);

            const checkForComplete = (sendObject) => {
                if (!sendObject.title) {
                    setPopup(
                        <Notif
                            title='Ошибка'
                            text={"Поле 'Название' должно быть заполнено."}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                if (!sendObject.author) {
                    setPopup(
                        <Notif
                            title='Ошибка'
                            text={"Поле 'Автор' должно быть заполнено."}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                if (!sendObject.authorAdaptation) {
                    setPopup(
                        <Notif
                            title='Ошибка'
                            text={"Поле 'Автор адаптации' должно быть заполнено."}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                if (!sendObject.roleCount) {
                    setPopup(
                        <Notif
                            title='Ошибка'
                            text={"Поле 'Кол-во ролей' должно быть заполнено."}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                if (!sendObject.actorAgeFrom || !sendObject.actorAgeTo) {
                    setPopup(
                        <Notif
                            title='Ошибка'
                            text={"Поле 'Возраст актеров' должно быть заполнено."}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                if (!sendObject.audienceAgeFrom || !sendObject.audienceAgeTo) {
                    setPopup(
                        <Notif
                            title='Ошибка'
                            text={"Поле 'Возраст аудитории' должно быть заполнено."}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                if (!sendObject.duration) {
                    setPopup(
                        <Notif
                            title='Ошибка'
                            text={"Поле 'Продолжительность пьесы' должно быть заполнено."}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                if (!sendObject.genres_select || sendObject.genres_select.length === 0) {
                    setPopup(
                        <Notif
                            title='Ошибка'
                            text={"Поле 'Жанр' должно быть заполнено."}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                if (!sendObject.description || sendObject.description === "<p><br></p>") {
                    setPopup(
                        <Notif
                            title='Ошибка'
                            text={"Описание должно быть заполнено."}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                return true;
            };

            const onAdd = async (params) => {
                const data = getValues();

                let sendObject = { ...data };

                sendObject["images"] = photo;
                sendObject["files"] = files;

                if (data.description)
                    sendObject["description"] = data.description;

                if (data.genres_select && data.genres_select.length > 0)
                    sendObject["genres"] = Array.from(
                        data.genres_select.map((item) => item.value)
                    );

                if (!checkForComplete(sendObject)) return;

                setSending(true);

                const result = await scenarioBankStore.add(sendObject);

                setSending(false);

                console.log(result);

                if (!result.error) {
                    setPopup(
                        <Notif
                            title=''
                            text={"Сценарий успешно добавлен"}
                            opened={true}
                            onClose={() => {
                                back();
                            }}
                        />
                    );
                } else {
                    setPopup(
                        <Notif
                            title='Ошибка'
                            text={result.errorText}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                }
            };

            if (!id) {
                return (
                    <>
                        <div className={commonStyles.title_block}>
                            <Button
                                type='button'
                                theme='text'
                                size='small'
                                iconClass={"mdi mdi-arrow-left"}
                                isIconBtn={true}
                                aria-label='Назад'
                                onClick={back}
                            />
                            <h1 className={commonStyles.title}>Создание сценария</h1>
                        </div>
                        <form onSubmit={handleSubmit(onAdd)} className='form'>
                            <Tabs>
                                <Tab title={"Основная информация"}>
                                    <fieldset className='form__section'>
                                        <FieldInput
                                            label={"Доступен для показа?"}
                                            type={"checkbox_variant"}
                                            {...register("active", {
                                                value: true,
                                            })}
                                        />
                                        <FieldInput
                                            label={"Только для авторизованных?"}
                                            type={"checkbox_variant"}
                                            {...register("needAuth", {
                                                value: true,
                                            })}
                                        />
                                        <FieldInput
                                            label={"Название сценария*"}
                                            type='text'
                                            layout='flex'
                                            required={true}
                                            placeholder={"Введите название"}
                                            {...register("title")}
                                        />
                                        <FieldInput
                                            label={"Автор*"}
                                            type='text'
                                            layout='flex'
                                            required={true}
                                            placeholder={"Введите автора"}
                                            {...register("author")}
                                        />
                                        <FieldInput
                                            label={"Автор адаптации*"}
                                            type='text'
                                            layout='flex'
                                            required={true}
                                            placeholder={"Введите автора адаптации"}
                                            {...register("authorAdaptation")}
                                        />
                                        <FieldInput
                                            label='Кол-во ролей, персонажей*'
                                            layout='flex'
                                            type='number'
                                            min={0}
                                            required={true}
                                            {...register("roleCount")}
                                        />
                                        <div className='form__multy-block'>
                                            <p className='form__label'>Возраст актеров*</p>
                                            <div className='form__range-block'>
                                                <FieldInput
                                                    label={"От"}
                                                    type='number'
                                                    layout="flex"
                                                    min={0}
                                                    extraClass={"form__field"}
                                                    required={true}
                                                    {...register("actorAgeFrom")}
                                                />
                                                <FieldInput
                                                    label={"До"}
                                                    type='number'
                                                    layout="flex"
                                                    extraClass={"form__field"}
                                                    min={0}
                                                    required={true}
                                                    {...register("actorAgeTo")}
                                                />
                                            </div>
                                        </div>
                                        <div className='form__multy-block'>
                                            <p className='form__label'>Возраст аудитории*</p>
                                            <div className='form__range-block'>
                                                <FieldInput
                                                    label={"От"}
                                                    type='number'
                                                    layout="flex"
                                                    min={0}
                                                    extraClass={"form__field"}
                                                    required={true}
                                                    {...register("audienceAgeFrom")}
                                                />
                                                <FieldInput
                                                    label={"До"}
                                                    type='number'
                                                    layout="flex"
                                                    extraClass={"form__field"}
                                                    min={0}
                                                    required={true}
                                                    {...register("audienceAgeTo")}
                                                />
                                            </div>
                                        </div>
                                        <FieldInput
                                            label={"Продолжительность пьесы, минут*"}
                                            type='number'
                                            layout='flex'
                                            min={0}
                                            required={true}
                                            {...register("duration")}
                                        />
                                        <div className='form__multy-block'>
                                            <p className='form__label'>Жанр *</p>
                                            <MultiSelect
                                                required={true}
                                                control={control}
                                                isMulti={true}
                                                name={"genres_select"}
                                                closeMenuOnSelect={false}
                                                options={genres}
                                            />
                                        </div>
                                        <div className='form__multy-block'>
                                            <p className='form__label'>Краткое описание</p>
                                            <Editor
                                                control={control}
                                                name='description'
                                                minHeight={250}
                                                buttons={{ link: true }}
                                            />
                                        </div>
                                    </fieldset>
                                </Tab>
                                <Tab title={"Фотографии"}>
                                    <fieldset className='form__section'>
                                        <ImageSelector
                                            items={photo}
                                            multiFiles={true}
                                            onChange={(items) => setPhoto(items)}
                                        />
                                    </fieldset>
                                </Tab>
                                <Tab title={"Файлы"}>
                                    <fieldset className='form__section'>
                                        <FileSelector
                                            items={files}
                                            multiFiles={true}
                                            withDescription={true}
                                            portrait={true}
                                            onChange={(items) => setFiles(items)}
                                        />
                                    </fieldset>
                                </Tab>
                            </Tabs>
                            <div className='form__controls'>
                                <Button type='submit' theme='primary' text='Сохранить' spinnerActive={sending} />
                                <Button
                                    type='button'
                                    theme='text'
                                    text='Отмена'
                                    onClick={back}
                                    spinnerActive={sending}
                                />
                            </div>
                        </form>
                        {popup}
                    </>
                );
            }
        };

        const EditScenario = () => {
            const [photo, setPhoto] = React.useState([]);
            const [files, setFiles] = React.useState([]);
            const [popup, setPopup] = React.useState(<></>);
            const [sending, setSending] = React.useState(false);

            React.useEffect(() => {
                if (edit) {
                    setValue("description", scenarioBankStore.scenario.short_description);

                    setPhoto(scenarioBankStore.scenario.images ? scenarioBankStore.scenario.images : []);
                    setFiles(scenarioBankStore.scenario.files ? scenarioBankStore.scenario.files : []);
                }
            }, [edit]);

            const checkForComplete = (sendObject) => {
                if (!sendObject.title) {
                    setPopup(
                        <Notif
                            title='Ошибка'
                            text={"Поле 'Название' должно быть заполнено."}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                if (!sendObject.author) {
                    setPopup(
                        <Notif
                            title='Ошибка'
                            text={"Поле 'Автор' должно быть заполнено."}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                if (!sendObject.authorAdaptation) {
                    setPopup(
                        <Notif
                            title='Ошибка'
                            text={"Поле 'Автор адаптации' должно быть заполнено."}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                if (!sendObject.roleCount) {
                    setPopup(
                        <Notif
                            title='Ошибка'
                            text={"Поле 'Кол-во ролей' должно быть заполнено."}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                if (!sendObject.actorAgeFrom || !sendObject.actorAgeTo) {
                    setPopup(
                        <Notif
                            title='Ошибка'
                            text={"Поле 'Возраст актеров' должно быть заполнено."}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                if (!sendObject.audienceAgeFrom || !sendObject.audienceAgeTo) {
                    setPopup(
                        <Notif
                            title='Ошибка'
                            text={"Поле 'Возраст аудитории' должно быть заполнено."}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                if (!sendObject.duration) {
                    setPopup(
                        <Notif
                            title='Ошибка'
                            text={"Поле 'Продолжительность пьесы' должно быть заполнено."}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                if (!sendObject.genres_select || sendObject.genres_select.length === 0) {
                    setPopup(
                        <Notif
                            title='Ошибка'
                            text={"Поле 'Жанр' должно быть заполнено."}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                if (!sendObject.description || sendObject.description === "<p><br></p>") {
                    setPopup(
                        <Notif
                            title='Ошибка'
                            text={"Описание должно быть заполнено."}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                return true;
            };

            const onEdit = async (params) => {
                const data = getValues();

                let sendObject = { ...data };

                sendObject["id"] = id;
                sendObject["images"] = photo;
                sendObject["files"] = files;

                if (data.description)
                    sendObject["description"] = data.description;

                if (data.genres_select && data.genres_select.length > 0)
                    sendObject["genres"] = Array.from(
                        data.genres_select.map((item) => item.value)
                    );

                if (!checkForComplete(sendObject)) return;

                setSending(true);

                console.log(files);

                const result = await scenarioBankStore.edit(sendObject);

                setSending(false);

                console.log(result);

                if (!result.error) {
                    setPopup(
                        <Notif
                            title=''
                            text={"Сценарий успешно отредактирован"}
                            opened={true}
                            onClose={() => {
                                back();
                            }}
                        />
                    );
                } else {
                    setPopup(
                        <Notif
                            title='Ошибка'
                            text={result.errorText}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                }
            };

            const onDelete = async () => {
                setPopup(
                    <Notif
                        text={"Вы уверены что хотите удалить?"}
                        opened={true}
                        onClose={() => setPopup(<></>)}
                        buttons={
                            <>
                                <Button
                                    type='button'
                                    size={"small"}
                                    text={"Нет"}
                                    theme='text'
                                    onClick={() => setPopup(<></>)}
                                />
                                <Button
                                    type='button'
                                    size={"small"}
                                    theme='info'
                                    text={"Да"}
                                    onClick={async () => {
                                        const result = await scenarioBankStore.remove({
                                            id,
                                        });

                                        if (!result.error) {
                                            setPopup(<></>);
                                            back();
                                        } else {
                                            setPopup(
                                                <Notif
                                                    title=''
                                                    text={scenarioBankStore.errorText}
                                                    opened={true}
                                                    onClose={() => {
                                                        scenarioBankStore.clearErrorText();
                                                        setPopup(<></>);
                                                    }}
                                                />
                                            );
                                        }
                                    }}
                                />
                            </>
                        }
                    />
                );
            };

            const onDeletePhoto = async (item) => {
                let sendObject = { ...item };

                sendObject["place"] = "images";

                const result = await scenarioBankStore.removeFile(sendObject);
            };

            const onDeleteFile = async (item) => {
                let sendObject = { ...item };

                sendObject["place"] = "files";

                const result = await scenarioBankStore.removeFile(sendObject);
            };

            if (id && edit) {
                return (
                    <>
                        <div className={commonStyles.title_block}>
                            <Button
                                type='button'
                                theme='text'
                                size='small'
                                iconClass={"mdi mdi-arrow-left"}
                                isIconBtn={true}
                                aria-label='Назад'
                                onClick={() => {
                                    setEdit(false);
                                    fetchData();
                                }}
                            />
                            <h1 className={commonStyles.title}>Редактирование сценария ID: {id}</h1>
                        </div>
                        <form onSubmit={handleSubmit(onEdit)} className='form'>
                            <Tabs>
                                <Tab title={"Основная информация"}>
                                    <fieldset className='form__section'>
                                        <FieldInput
                                            label={"Доступен для показа?"}
                                            type={"checkbox_variant"}
                                            {...register("active", {
                                                value: scenarioBankStore.scenario.active === "Активен",
                                            })}
                                        />
                                        <FieldInput
                                            label={"Только для авторизованных?"}
                                            type={"checkbox_variant"}
                                            {...register("needAuth", {
                                                value: scenarioBankStore.scenario.need_auth === "Активен",
                                            })}
                                        />
                                        <FieldInput
                                            label={"Название сценария*"}
                                            type='text'
                                            layout='flex'
                                            required={true}
                                            placeholder={"Введите название"}
                                            {...register("title", {
                                                value: scenarioBankStore.scenario.title,
                                            })}
                                        />
                                        <FieldInput
                                            label={"Автор*"}
                                            type='text'
                                            layout='flex'
                                            required={true}
                                            placeholder={"Введите автора"}
                                            {...register("author", {
                                                value: scenarioBankStore.scenario.author,
                                            })}
                                        />
                                        <FieldInput
                                            label={"Автор адаптации*"}
                                            type='text'
                                            layout='flex'
                                            required={true}
                                            placeholder={"Введите автора адаптации"}
                                            {...register("authorAdaptation", {
                                                value: scenarioBankStore.scenario.adaptation_author,
                                            })}
                                        />
                                        <FieldInput
                                            label='Кол-во ролей, персонажей*'
                                            layout='flex'
                                            type='number'
                                            min={0}
                                            required={true}
                                            {...register("roleCount", {
                                                value: scenarioBankStore.scenario.roles_count,
                                            })}
                                        />
                                        <div className='form__multy-block'>
                                            <p className='form__label'>Возраст актеров*</p>
                                            <div className='form__range-block'>
                                                <FieldInput
                                                    label={"От"}
                                                    type='number'
                                                    layout="flex"
                                                    min={0}
                                                    extraClass={"form__field"}
                                                    required={true}
                                                    {...register("actorAgeFrom", {
                                                        value: scenarioBankStore.scenario.actor_from,
                                                    })}
                                                />
                                                <FieldInput
                                                    label={"До"}
                                                    type='number'
                                                    layout="flex"
                                                    extraClass={"form__field"}
                                                    min={0}
                                                    required={true}
                                                    {...register("actorAgeTo", {
                                                        value: scenarioBankStore.scenario.actor_to,
                                                    })}
                                                />
                                            </div>
                                        </div>
                                        <div className='form__multy-block'>
                                            <p className='form__label'>Возраст аудитории*</p>
                                            <div className='form__range-block'>
                                                <FieldInput
                                                    label={"От"}
                                                    type='number'
                                                    layout="flex"
                                                    min={0}
                                                    extraClass={"form__field"}
                                                    required={true}
                                                    {...register("audienceAgeFrom", {
                                                        value: scenarioBankStore.scenario.audience_age_from,
                                                    })}
                                                />
                                                <FieldInput
                                                    label={"До"}
                                                    type='number'
                                                    layout="flex"
                                                    extraClass={"form__field"}
                                                    min={0}
                                                    required={true}
                                                    {...register("audienceAgeTo", {
                                                        value: scenarioBankStore.scenario.audience_age_to,
                                                    })}
                                                />
                                            </div>
                                        </div>
                                        <FieldInput
                                            label={"Продолжительность пьесы, минут*"}
                                            type='number'
                                            layout='flex'
                                            min={0}
                                            required={true}
                                            {...register("duration", {
                                                value: scenarioBankStore.scenario.duration,
                                            })}
                                        />
                                        <div className='form__multy-block'>
                                            <p className='form__label'>Жанр *</p>
                                            <MultiSelect
                                                required={true}
                                                control={control}
                                                isMulti={true}
                                                name={"genres_select"}
                                                closeMenuOnSelect={false}
                                                options={genres}
                                                values={scenarioBankStore.scenario?.genres?.map((item) => {
                                                    return {
                                                        label: item.title,
                                                        value: item.title,
                                                    };
                                                })}
                                            />
                                        </div>
                                        <div className='form__multy-block'>
                                            <p className='form__label'>Краткое описание</p>
                                            <Editor
                                                control={control}
                                                name='description'
                                                minHeight={250}
                                                buttons={{ link: true }}
                                            />
                                        </div>
                                    </fieldset>
                                </Tab>
                                <Tab title={"Фотографии"}>
                                    <fieldset className='form__section'>
                                        <ImageSelector
                                            items={photo}
                                            multiFiles={true}
                                            onChange={(items) => setPhoto(items)}
                                            onDelete={onDeletePhoto}
                                        />
                                    </fieldset>
                                </Tab>
                                <Tab title={"Файлы"}>
                                    <fieldset className='form__section'>
                                        <FileSelector
                                            items={files}
                                            multiFiles={true}
                                            portrait={true}
                                            withDescription={true}
                                            onChange={(items) => setFiles(items)}
                                            onDelete={onDeleteFile}
                                        />
                                    </fieldset>
                                </Tab>
                            </Tabs>
                            <div className='form__controls'>
                                <Button type='submit' theme='primary' text='Сохранить' spinnerActive={sending} />
                                <Button
                                    type='button'
                                    theme='text'
                                    text='Удалить'
                                    onClick={onDelete}
                                    spinnerActive={sending}
                                />
                                <Button
                                    type='button'
                                    theme='text'
                                    text='Отмена'
                                    onClick={() => {
                                        setEdit(false);
                                        fetchData();
                                    }}
                                    spinnerActive={sending}
                                />
                            </div>
                        </form>
                        {popup}
                    </>
                );
            }
        };

        const ViewScenario = () => {
            if (id && !edit && !scenarioBankStore.loading && Object.keys(scenarioBankStore.scenario).length > 0) {
                return (
                    <>
                        <div className={commonStyles.title_block}>
                            <Button
                                type='button'
                                theme='text'
                                size='small'
                                iconClass={"mdi mdi-arrow-left"}
                                isIconBtn={true}
                                aria-label='Назад'
                                onClick={back}
                            />
                            <h1 className={commonStyles.title}>Сценарий ID: {scenarioBankStore.scenario.ID}</h1>
                            <Button
                                size='smaller'
                                type='submit'
                                isIconBtn='true'
                                iconClass={"mdi mdi-pencil"}
                                theme='outline'
                                aria-label='Редактировать сценарий'
                                onClick={() => {
                                    setEdit(true);
                                }}
                            />
                        </div>
                        <Tabs>
                            <Tab title={"Основные сведения"}>
                                <ul className={styles.list}>
                                    <li className={styles.item}>
                                        <h3 className={styles.label}>Доступен для показа?</h3>
                                        <p className={styles.description}>
                                            {scenarioBankStore.scenario.active === "Активен" ? "Да" : "Нет"}
                                        </p>
                                    </li>
                                    <li className={styles.item}>
                                        <h3 className={styles.label}>Только для авторизованных?</h3>
                                        <p className={styles.description}>
                                            {scenarioBankStore.scenario.need_auth === "Активен" ? "Да" : "Нет"}
                                        </p>
                                    </li>
                                    <li className={styles.item}>
                                        <h3 className={styles.label}>Название</h3>
                                        <p className={styles.description}>{scenarioBankStore.scenario.title}</p>
                                    </li>
                                    <li className={styles.item}>
                                        <h3 className={styles.label}>Автор</h3>
                                        <p className={styles.description}>{scenarioBankStore.scenario.author}</p>
                                    </li>
                                    <li className={styles.item}>
                                        <h3 className={styles.label}>Автор адаптации</h3>
                                        <p className={styles.description}>{scenarioBankStore.scenario.adaptation_author}</p>
                                    </li>
                                    <li className={styles.item}>
                                        <h3 className={styles.label}>Кол-во ролей, персонажей</h3>
                                        <p className={styles.description}>{scenarioBankStore.scenario.roles_count}</p>
                                    </li>
                                    <li className={styles.item}>
                                        <h3 className={styles.label}>Возраст актеров</h3>
                                        <p className={styles.description}>от {scenarioBankStore.scenario.actor_from} до {scenarioBankStore.scenario.actor_to}</p>
                                    </li>
                                    <li className={styles.item}>
                                        <h3 className={styles.label}>Возраст аудитории</h3>
                                        <p className={styles.description}>от {scenarioBankStore.scenario.audience_age_from} до {scenarioBankStore.scenario.audience_age_to}</p>
                                    </li>
                                    <li className={styles.item}>
                                        <h3 className={styles.label}>Продолжительность пьесы, минут</h3>
                                        <p className={styles.description}>{scenarioBankStore.scenario.duration}</p>
                                    </li>
                                    <li className={styles.item}>
                                        <h3 className={styles.label}>Жанр</h3>
                                        <p className={styles.description}>{scenarioBankStore.scenario.genres.map(item => item.title).join(", ")}</p>
                                    </li>
                                </ul>
                                <h2 className={styles.title}>Описание</h2>
                                <div
                                    className={styles.editor}
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(scenarioBankStore.scenario.short_description),
                                    }}
                                />
                            </Tab>
                            <Tab title={"Фотографии"}>
                                <ImageGallery items={scenarioBankStore.scenario.images} front={false} />
                            </Tab>
                            <Tab title={"Файлы"}>
                                <FileGallery items={scenarioBankStore.scenario.files} front={false} withDescription={true} />
                            </Tab>
                        </Tabs>
                    </>
                );
            }
        };

        return (
            <>
                <NewScenario />
                <EditScenario />
                <ViewScenario />
            </>
        );
    };

    return (
        <>
            <Loading />
            <NotFound />
            <MainBlock />
        </>
    );
};

export default ScenarioPage;

import React from "react";
import { motion } from "framer-motion";
import { useForm } from "react-hook-form";
import axios from "axios";

import useAuthStore from "../../../store/authStore";
import useSchoolStore from "../../../store/public/schoolsStore";

import Breadcrumbs from "../../../components/breadcrumbs/breadcrumbs.component";
import Button from "../../../components/button/button.component";
import FieldInputComponent from "../../../components/field/field.input.component";
import Notif from "../../../components/notif/notif.component";
import MultiSelect from "../../../components/multi_select/multi_select.component";

import commonStyles from "../common.module.scss";
import styles from "./common.module.scss";

const AssistanceMentorPage = () => {
    const schoolStore = useSchoolStore();
    const { register, handleSubmit, reset, control } = useForm();

    const [notif, setNotif] = React.useState(<></>);
    const [sending, setSending] = React.useState(false);

    const peoples = [
        {
            name: "Муриев Александр",
            position: "Режиссер драматического театра",
            photo: "Муриев Александр.jpg",
        },
        {
            name: "Карсанова Изабелла",
            position: "Актриса театра и кино, преподаватель актерского мастерства и сценической речи",
            photo: "Карсанова Изабелла чб.jpg",
        },
        {
            name: "Портнягин Алексей",
            position: "Режиссер драматического театра, преподаватель импровизации, сценической речи, актерского мастерства и пластики",
            photo: "Портнягин Алексей чб.jpg",
        },
        {
            name: "Шашуро Полина",
            position: "Актриса театра и кино, преподаватель актерского мастерства, сценической речи и импровизации",
            photo: "Шашуро Полина чб.jpg",
        },
        {
            name: "Засеев Батраз",
            position: "Актер театра и кино, каскадер, преподаватель сценического движения",
            photo: "Засеев Батраз чб.jpg",
        },
        {
            name: "Несвяченая Ксения",
            position: "Режиссер драматического театра, актриса театра и кино, сценарист",
            photo: "Несвяченая Ксения.jpeg",
        },
        {
            name: "Лукин Михаил",
            position: "Режиссер драматического театра, актер театра и кино",
            photo: "Лукин Михаил.jpeg",
        },
        {
            name: "Ганькина Кристина",
            position: "Режиссер драматического театра, преподаватель актерского мастерства и сценической речи, мастер актерско-речевого курса",
            photo: "Ганькина Кристина чб.jpg",
        },
        {
            name: "Гагиев Руслан",
            position: "Актер театра и кино, преподаватель актерского мастерства, сценического движения и фехтования",
            photo: "Гагиев Руслан.PNG",
        },
        {
            name: "Моисеев Денис",
            position: "Актер театра и кино, преподаватель актерского мастерства и клоунады",
            photo: "Моисеев Денис чб.jpg",
        },
        {
            name: "Пенушкина Анастасия",
            position: "Актриса театра и кино, преподаватель актерского мастерства и сценической речи",
            photo: "Пенушкина Анастасия.JPG",
        },
        {
            name: "Ляхов Юрий",
            position: "Режиссер драматического театра, актер театра и кино",
            photo: "Ляхов Юрий чб.jpg",
        },
    ];

    React.useEffect(() => {
        schoolStore.loadSchools();
    }, []);

    const onSubmit = async (params) => {
        let sendObject = { ...params };

        sendObject["school"] = params.school_select.value;

        setSending(true);

        let form = new FormData();
        window.global.buildFormData(form, sendObject);

        const response = await axios.postForm("https://theatre.shkolamoskva.ru/php/email/mentor.php", form);

        console.log(response);

        if (response?.data?.mail_result) {
            setNotif(
                <Notif
                    text='Запрос успешно отправлен'
                    state='success'
                    timerInSeconds={3}
                    opened={true}
                    onClose={() => setNotif(<></>)}
                />
            );

            reset();
        } else {
            setNotif(
                <Notif
                    title='Ошибка!'
                    text={"Не удалось отправить сообщение, попробуйте позже."}
                    opened={true}
                    onClose={() => {
                        setNotif(<></>);
                    }}
                />
            );
        }

        setSending(false);
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 0.2, duration: 1 }}
        >
            <Breadcrumbs
                items={[
                    {
                        title: "Главная",
                        url: "/",
                    },
                    {
                        title: "Помощь консультантов наставников",
                        url: "",
                    },
                ]}
            />
            <section className={[commonStyles.wrap, commonStyles.wrap_shadow].join(" ")}>
                <h2 className={[commonStyles.title, commonStyles.title_underline].join(" ")}>
                    Помощь консультантов наставников
                </h2>
                <div className={styles.section}>
                    <h3 className={styles.title}>О проекте</h3>
                    <div>
                        <p className={styles.text}>
                            Профессиональные педагоги и мастера театрального искусства помогут в вопросах организации и
                            педагогического сопровождения театральных постановок в Школьных театрах, подборе пьес,
                            согласно возрастным особенностям детского коллектива, смогут проконсультировать по
                            творческим решениям при постановке детских спектаклей.
                        </p>
                        <p className={styles.text}>
                            Каждая образовательная организация может получить помощь консультанта — наставника, но не
                            более 3-х консультаций в течение учебного года, длительность одной консультации составляет 3
                            часа.
                        </p>
                        <p className={styles.text}>Консультации проводятся по следующим направлениям:</p>
                        <ul className={styles.text}>
                            <li>
                                организационные вопросы — помощь в создании Школьного театра, как выстроить работу в
                                Школьном театре, подбор пьес для постановки,
                            </li>
                            <li>
                                вопросы содержания деятельности Школьного театра и выезд на репетицию — профессиональные
                                консультации с участием в репетиционном процессе.
                            </li>
                        </ul>
                        <p className={styles.text}>Порядок получения консультации:</p>
                        <ol className={styles.text}>
                            <li>Выберите направление, по которому Вы хотите получить консультацию. Оставьте заявку.</li>
                            <li>С Вами свяжется ответственный сотрудник для уточнения даты и времени консультации.</li>
                            <li>Ожидайте приезда консультанта — наставника.</li>
                        </ol>
                    </div>
                </div>
                <div className={styles.section}>
                    <h3 className={styles.title}>Консультанты — наставники</h3>
                    <ul className={styles.cardDeck}>
                        {peoples.map((item, index) => (
                            <li key={index}>
                                <img
                                    className={styles.cardPhoto}
                                    src={process.env.REACT_APP_BASE_URL + "images/people/assistance/" + item.photo}
                                    alt={"Фотография " + item.name}
                                />
                                <h4 className={styles.cardTitle}>{item.name}</h4>
                                <p className={styles.cardSubtitle}>{item.position}</p>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={styles.section}>
                    <h3 className={styles.title}>Регистрация на консультации и мастер-классы</h3>
                    <div className={commonStyles.formWrapper}>
                        <form onSubmit={handleSubmit(onSubmit)} className={commonStyles.form}>
                            <fieldset className={commonStyles.formFieldset}>
                                <MultiSelect
                                    placeholder={"Образовательная организация"}
                                    required={true}
                                    control={control}
                                    name={"school_select"}
                                    className={commonStyles.formRow}
                                    options={schoolStore.schools.map((item) => {
                                        return {
                                            label: item.org_short_name,
                                            value: item.org_short_name,
                                        };
                                    })}
                                />
                                <FieldInputComponent
                                    required={true}
                                    type={"text"}
                                    extraClass={commonStyles.formRow}
                                    placeholder={"ФИО (полностью)"}
                                    {...register("fio")}
                                />
                                <FieldInputComponent
                                    required={true}
                                    type={"email"}
                                    placeholder={"E-mail"}
                                    {...register("email")}
                                />
                                <FieldInputComponent
                                    required={true}
                                    type={"tel"}
                                    placeholder={"Телефон"}
                                    {...register("phone")}
                                />
                                <FieldInputComponent
                                    type={"select"}
                                    extraClass={commonStyles.formRow}
                                    placeholder={"Запись на консультацию или мастер-класс"}
                                    defaultSelectItem={{
                                        title: "---",
                                        value: "",
                                        disabled: false,
                                    }}
                                    selectItems={[
                                        {
                                            title: "Консультация по организационным вопросам",
                                            value: "Консультация по организационным вопросам",
                                        },
                                        {
                                            title: "Консультация по вопросам сопровождения деятельности Школьного театра",
                                            value: "Консультация по вопросам сопровождения деятельности Школьного театра",
                                        },
                                        {
                                            title: "Мастер-класс «Пластическая выразительность»",
                                            value: "Мастер-класс «Пластическая выразительность»",
                                        },
                                        {
                                            title: "Мастер-класс «Режиссура»",
                                            value: "Мастер-класс «Режиссура»",
                                        },
                                        {
                                            title: "Мастер-класс «Разбор художественного текста",
                                            value: "Мастер-класс «Разбор художественного текста",
                                        },
                                        {
                                            title: "Мастер-класс «Музыкальное оформление»",
                                            value: "Мастер-класс «Музыкальное оформление»",
                                        },
                                        {
                                            title: "Мастер-класс «Сценическая речь»",
                                            value: "Мастер-класс «Сценическая речь»",
                                        },
                                        {
                                            title: "Мастер-класс «Актерское мастерство»",
                                            value: "Мастер-класс «Актерское мастерство»",
                                        },
                                    ]}
                                    {...register("type")}
                                />
                                <FieldInputComponent
                                    required={true}
                                    label={`Нажимая на кнопку «Отправить», я подтверждаю, что ознакомлен
                                с политикой конфиденциальности и даю согласие ГБОУ ДПО МЦПС
                                на обработку персональных данных`}
                                    type={"checkbox"}
                                    extraClass={commonStyles.formRow}
                                />
                                <Button
                                    type='submit'
                                    theme='public_primary'
                                    extraClass={`[${styles.submitBtn} ${commonStyles.formRow}`}
                                    text={"Оставить заявку"}
                                    spinnerActive={sending}
                                    disabled={schoolStore.loading}
                                />
                                <span className={commonStyles.formRow}>
                                    По вопросам работы консультантов — наставников вы можете обратиться по телефону:
                                    <br />
                                    Кузнецова Александра Сергеевна{" "}
                                    <a className={commonStyles.link} href='tel:89629158213'>
                                        +7 962 915 82 13
                                    </a>
                                </span>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </section>
            {notif}
        </motion.div>
    );
};

export default AssistanceMentorPage;
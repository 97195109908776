import React from 'react';

const AdminFestivalPage = () => {
    return (
        <div>
            Фестиваль.
        </div>
    );
};

export default AdminFestivalPage;
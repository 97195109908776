import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useForm} from "react-hook-form";

import useActivitiesStore from "../../../store/admin/activitiesStore";

import Button from "../../../components/button/button.component";
import Notif from "../../../components/notif/notif.component";
import FieldInput from "../../../components/field/field.input.component";

import commonStyles from "../../common.module.scss";
import styles from "../../../components/page_components/theatre/theatre.module.scss";

const MethodologicalSupportPage = () => {
    let { id } = useParams();
    const navigate = useNavigate();
    const back = () => navigate("/admin/activity");

    const activitiesStore = useActivitiesStore();

    const fetchData = async () => {
        if (id) await activitiesStore.loadMethodologicalSupport({ id });
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    const Loading = () => {
        if (activitiesStore.loading) {
            return (
                <div className={commonStyles.title_block}>
                    <h1 className={commonStyles.title}>Загрузка...</h1>
                </div>
            );
        }
    };

    const NotFound = () => {
        if (id && !activitiesStore.loading && Object.keys(activitiesStore.methodologicalSupport).length === 0) {
            return (
                <div className={commonStyles.title_block}>
                    <Button
                        type='button'
                        theme='text'
                        size='small'
                        iconClass={"mdi mdi-arrow-left"}
                        isIconBtn={true}
                        aria-label='Назад'
                        onClick={back}
                    />
                    <h1 className={commonStyles.title}>Запись не найдена</h1>
                </div>
            );
        }
    };

    const MainBlock = () => {
        const [edit, setEdit] = React.useState(false);
        const { register, handleSubmit, reset, control, setValue, getValues } = useForm();

        const New = () => {
            const [popup, setPopup] = React.useState(<></>);
            const [sending, setSending] = React.useState(false);

            const checkForComplete = (item) => {
                if (item.title === "") {
                    setPopup(
                        <Notif
                            title='Ошибка!'
                            text={"Вы не заполнили поле: Название"}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                if (item.url && item.url === "") {
                    setPopup(
                        <Notif
                            title='Ошибка!'
                            text={"Вы не заполнили поле: Ссылка на видео"}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                if (item.text === "") {
                    setPopup(
                        <Notif
                            title='Ошибка!'
                            text={"Вы не заполнили поле: Описание"}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                return true;
            };

            const onAdd = async (params) => {
                setSending(true);
                const data = getValues();

                let sendObject = { ...data };

                if (!checkForComplete(sendObject)) {
                    setSending(false);
                    return;
                }

                const result = await activitiesStore.addMethodologicalSupport(sendObject);

                setSending(false);

                if (!result.error) {
                    setPopup(
                        <Notif
                            title=''
                            text={"Запись  успешно добавлена"}
                            opened={true}
                            onClose={() => {
                                activitiesStore.clearErrorText();
                                setPopup(<></>);
                                back();
                            }}
                        />
                    );
                }
            };

            if (!id) {
                return (
                    <>
                        <div className={commonStyles.title_block}>
                            <Button
                                type='button'
                                theme='text'
                                size='small'
                                iconClass={"mdi mdi-arrow-left"}
                                isIconBtn={true}
                                aria-label='Назад'
                                onClick={() => {
                                    reset();
                                    back();
                                }}
                            />
                            <h1 className={commonStyles.title}>Создание записи</h1>
                        </div>
                        <form onSubmit={handleSubmit(onAdd)} className='form'>
                            <fieldset className='form__section'>
                                <FieldInput
                                    label='Название'
                                    layout='flex'
                                    type='textarea'
                                    placeholder='Введите название...'
                                    required={true}
                                    {...register("title")}
                                />
                                <FieldInput
                                    label='Описание'
                                    layout='flex'
                                    type='textarea'
                                    rows={6}
                                    placeholder='Введите описание...'
                                    required={true}
                                    {...register("text")}
                                />
                                <FieldInput
                                    label='Сортировка'
                                    layout='flex'
                                    type='number'
                                    min={0}
                                    required={true}
                                    {...register("sorting", { value: 0})}
                                />
                                <FieldInput
                                    label='Ссылка на видео'
                                    layout='flex'
                                    type='textarea'
                                    rows={3}
                                    placeholder='Введите ссылку...'
                                    required={true}
                                    {...register("url")}
                                />
                            </fieldset>
                            <div className='form__controls'>
                                <Button type='submit' theme='primary' text='Сохранить' spinnerActive={sending} />
                                <Button
                                    type='button'
                                    theme='text'
                                    text='Отмена'
                                    onClick={() => {
                                        reset();
                                        back();
                                    }}
                                    spinnerActive={sending}
                                />
                            </div>
                        </form>
                        {popup}
                    </>
                );
            }
        };

        const Edit = () => {
            const [popup, setPopup] = React.useState(<></>);
            const [sending, setSending] = React.useState(false);

            React.useEffect(() => {
                if (edit) {
                    setValue("title", activitiesStore.methodologicalSupport.title);
                    setValue("text", activitiesStore.methodologicalSupport.text);
                    setValue("url", activitiesStore.methodologicalSupport.url);
                    setValue("sorting", activitiesStore.methodologicalSupport.sorting);
                }
            }, []);

            const checkForComplete = (item) => {
                if (item.title === "") {
                    setPopup(
                        <Notif
                            title='Ошибка!'
                            text={"Вы не заполнили поле: Название"}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                if (item.url && item.url === "") {
                    setPopup(
                        <Notif
                            title='Ошибка!'
                            text={"Вы не заполнили поле: Ссылка на видео"}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                if (item.text === "") {
                    setPopup(
                        <Notif
                            title='Ошибка!'
                            text={"Вы не заполнили поле: Описание"}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                return true;
            };

            const onEdit = async (params) => {
                setSending(true);
                const data = getValues();

                let sendObject = { ...data };
                sendObject["id"] = id;

                if (!checkForComplete(sendObject)) {
                    setSending(false);
                    return;
                }

                const result = await activitiesStore.editMethodologicalSupport(sendObject);

                setSending(false);

                if (!result.error) {
                    setPopup(
                        <Notif
                            title=''
                            text={"Запись успешно отредактирована"}
                            opened={true}
                            onClose={() => {
                                activitiesStore.clearErrorText();
                                setPopup(<></>);
                                back();
                            }}
                        />
                    );
                }
            };

            const onDelete = async () => {
                setPopup(
                    <Notif
                        text={"Вы уверены что хотите удалить?"}
                        opened={true}
                        onClose={() => setPopup(<></>)}
                        buttons={
                            <>
                                <Button
                                    type='button'
                                    size={"small"}
                                    text={"Нет"}
                                    theme='text'
                                    onClick={() => setPopup(<></>)}
                                />
                                <Button
                                    type='button'
                                    size={"small"}
                                    theme='info'
                                    text={"Да"}
                                    onClick={async () => {
                                        let sendObject = {};

                                        sendObject["id"] = id;

                                        const result = await activitiesStore.removeMethodologicalSupport(sendObject);

                                        if (!result.error) {
                                            setPopup(
                                                <Notif
                                                    title=''
                                                    text={"Запись удалена"}
                                                    opened={true}
                                                    onClose={() => {
                                                        setPopup(<></>);
                                                        back();
                                                    }}
                                                />
                                            );
                                        } else {
                                            setPopup(
                                                <Notif
                                                    title='Ошибка'
                                                    text={result.errorText}
                                                    opened={true}
                                                    onClose={() => {
                                                        setPopup(<></>);
                                                    }}
                                                />
                                            );
                                        }
                                    }}
                                />
                            </>
                        }
                    />
                );
            };

            if (id && edit) {
                return (
                    <>
                        <div className={commonStyles.title_block}>
                            <Button
                                type='button'
                                theme='text'
                                size='small'
                                iconClass={"mdi mdi-arrow-left"}
                                isIconBtn={true}
                                aria-label='Назад'
                                onClick={() => {
                                    reset();
                                    setEdit(false);
                                }}
                            />
                            <h1 className={commonStyles.title}>Редактирование записи {id}</h1>
                        </div>
                        <form onSubmit={handleSubmit(onEdit)} className='form'>
                            <fieldset className='form__section'>
                                <FieldInput
                                    label='Название'
                                    layout='flex'
                                    type='textarea'
                                    placeholder='Введите название...'
                                    required={true}
                                    {...register("title", { value: activitiesStore.methodologicalSupport.title })}
                                />
                                <FieldInput
                                    label='Описание'
                                    layout='flex'
                                    rows={6}
                                    type='textarea'
                                    placeholder='Введите описание...'
                                    required={true}
                                    {...register("text", { value: activitiesStore.methodologicalSupport.text })}
                                />
                                <FieldInput
                                    label='Сортировка'
                                    layout='flex'
                                    type='number'
                                    min={0}
                                    required={true}
                                    {...register("sorting", {
                                        value: activitiesStore.methodologicalSupport.sorting,
                                    })}
                                />
                                <FieldInput
                                    label='Ссылка на видео'
                                    layout='flex'
                                    type='textarea'
                                    rows={3}
                                    placeholder='Введите ссылку...'
                                    required={true}
                                    {...register("url", {
                                        value: activitiesStore.methodologicalSupport.url,
                                    })}
                                />
                            </fieldset>
                            <div className='form__controls'>
                                <Button type='submit' theme='primary' text='Сохранить' spinnerActive={sending} />
                                <Button
                                    type='button'
                                    theme='text'
                                    text='Удалить'
                                    onClick={onDelete}
                                    spinnerActive={sending}
                                />
                                <Button
                                    type='button'
                                    theme='text'
                                    text='Отмена'
                                    onClick={() => {
                                        setEdit(false);
                                    }}
                                    spinnerActive={sending}
                                />
                            </div>
                        </form>
                        {popup}
                    </>
                );
            }
        };

        const View = () => {
            const iframeStyle = {
                width: "100%",
                border: "none",
                "object-fit": "cover",
                "object-position": "center",
                "aspect-ratio": "389/220",
                "border-radius": "5px",
            };

            if (id && !edit && !activitiesStore.loading && Object.keys(activitiesStore.methodologicalSupport).length > 0) {
                return (
                    <>
                        <div className={commonStyles.title_block}>
                            <Button
                                type='button'
                                theme='text'
                                size='small'
                                iconClass={"mdi mdi-arrow-left"}
                                isIconBtn={true}
                                aria-label='Назад'
                                onClick={back}
                            />
                            <h1 className={commonStyles.title}>
                                Запись ID: {activitiesStore.loadMethodologicalSupport.ID}
                            </h1>
                            <Button
                                size='smaller'
                                type='submit'
                                isIconBtn='true'
                                iconClass={"mdi mdi-pencil"}
                                theme='outline'
                                aria-label='Редактировать'
                                onClick={() => {
                                    setEdit(true);
                                }}
                            />
                        </div>
                        <ul className={styles.list}>
                            <li className={styles.item}>
                                <h3 className={styles.label}>Название</h3>
                                <p className={styles.description}>{activitiesStore.methodologicalSupport.title}</p>
                            </li>
                            <li className={styles.item}>
                                <h3 className={styles.label}>Описание</h3>
                                <p className={styles.description}>{activitiesStore.methodologicalSupport.text}</p>
                            </li>
                            <li className={styles.item}>
                                <h3 className={styles.label}>Сортировка</h3>
                                <p className={styles.description}>{activitiesStore.methodologicalSupport.sorting}</p>
                            </li>
                            <li className={styles.item}>
                                <h3 className={styles.label}>Ссылка на видео</h3>
                                <p className={styles.description}>{activitiesStore.methodologicalSupport.url}</p>
                            </li>
                            <iframe title={activitiesStore.methodologicalSupport.title} style={iframeStyle} src={activitiesStore.methodologicalSupport.url}></iframe>
                        </ul>
                    </>
                );
            }
        };

        return (
            <>
                <New />
                <Edit />
                <View />
            </>
        );
    };

    return (
        <>
            <Loading />
            <MainBlock />
            <NotFound />
        </>
    );
};

export default MethodologicalSupportPage;
import React from "react";
import {useParams} from "react-router-dom";
import {motion} from "framer-motion";
import moment from "moment";
import {useForm} from "react-hook-form";

import useSpectaclesStore from "../../../store/public/spectaclesStore";

import Notif from "../../../components/notif/notif.component";
import Button from "../../../components/button/button.component";
import FieldInputComponent from "../../../components/field/field.input.component";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumbs.component";

import commonStyles from "../common.module.scss";
import styles from "./cancel.booking.module.scss";

const CancelSpectacleBookingPage = () => {
    const {id} = useParams();
    const spectaclesStore = useSpectaclesStore();

    React.useEffect(() => {
        spectaclesStore.loadTicket({token: id});
    }, [id]);

    console.log(spectaclesStore.ticket);

    // Private components
    const Loading = () => {
        return (
            <>
                {spectaclesStore.loading && (
                    <section className={[commonStyles.wrap, commonStyles.wrap_bubble].join(" ")}>
                        <div className={styles.section}>
                            <h2 className={[commonStyles.title, commonStyles.title_underline].join(" ")}>
                                загрузка...
                            </h2>
                        </div>
                    </section>
                )}
            </>
        );
    };

    const MainBlock = () => {

        const {register, handleSubmit, reset} = useForm();

        const [notif, setNotif] = React.useState(<></>);
        const [sending, setSending] = React.useState(false);

        const onSendAllRequest = async () => {
            setSending(true);

            let sendObject = {};

            sendObject['type'] = "all";
            sendObject['token'] = id;

            //console.log(sendObject);

            const result = await spectaclesStore.sendBookingCancel(sendObject);

            if (!result.error) {
                reset();
                setNotif(<Notif
                    title="Внимание"
                    text={`Подтверждение отмены отправлено Вам на почту.`}
                    opened={true}
                    onClose={() => {
                        setNotif(<></>);
                    }}
                />);
            } else {
                setNotif(
                    <Notif
                        title="Ошибка"
                        text={`${result.errorText}`}
                        opened={true}
                        onClose={() => {
                            setNotif(<></>);
                        }}
                    />
                );
            }

            setSending(false);
        };

        const onSendRequest = async (params) => {

            if(Object.values(params).findIndex(value => value === true) === -1)
                return;

            if(Object.values(params).filter(value => value === true).length === Object.values(params).length)
            {
                onSendAllRequest();
                return;
            }

            setSending(true);

            let sendObject = {};

            sendObject['type'] = "one";
            sendObject['token'] = id;

            let people = [];
            for (let key in params) {
                if (params.hasOwnProperty(key)) {
                    if(params[key] === true)
                        people.push(key);
                }
            }

            sendObject['people'] = people;

            const result = await spectaclesStore.sendBookingCancel(sendObject);

            if (!result.error) {
                reset();
                setNotif(<Notif
                    title="Внимание"
                    text={`Подтверждение отмены отправлено Вам на почту.`}
                    opened={true}
                    onClose={() => {
                        setNotif(<></>);
                    }}
                />);
            } else {
                setNotif(
                    <Notif
                        title="Ошибка"
                        text={`${result.errorText}`}
                        opened={true}
                        onClose={() => {
                            setNotif(<></>);
                        }}
                    />
                );
            }

            setSending(false);
        };

        return (
            <>
                {!spectaclesStore.loading && Object.keys(spectaclesStore.ticket).length > 0 && (
                    <section className={[commonStyles.wrap, commonStyles.wrap_shadow].join(" ")}>
                        <div className={styles.section}>
                            <h2 className={[commonStyles.title, commonStyles.title_underline].join(" ")}>
                                Отмена бронирования билетов
                            </h2>
                            <p className={styles.info}>
                                <b>ВНИМАНИЕ!</b> <br/> Вы можете отменить бронирование для всех гостей нажав на кнопку
                                "Полная отмена бронирования" или воспользоваться "Частичной отменой", выбрав из списка
                                гостей, для которых следует отменить бронирование билетов.
                            </p>
                            <h3 className={`${commonStyles.subtitle} ${styles.caption}`}>Информация о спектакле:</h3>
                            <form onSubmit={handleSubmit(onSendRequest)} name='cancel-booking'>
                                <ul className={styles.list}>
                                    <li>
                                        <b>Постановка:</b> {spectaclesStore.ticket.spectacleTitle}
                                    </li>
                                    <li>
                                        <b>Дата:</b> {moment(spectaclesStore.ticket.date).format("DD MMMM YYYY HH:mm")}
                                    </li>
                                    <li>
                                        <b>Адрес:</b> {spectaclesStore.ticket.address}
                                    </li>
                                    <li>
                                        <b>Email:</b> {spectaclesStore.ticket.email}
                                    </li>
                                    <li>
                                        <b>Телефон:</b> {spectaclesStore.ticket.phone}
                                    </li>
                                    <li>
                                        <b>Кол-во билетов:</b> {spectaclesStore.ticket.count}
                                    </li>
                                    {
                                        spectaclesStore.ticket.people
                                        &&
                                        spectaclesStore.ticket.people.length === 1
                                        &&
                                        <li>
                                            <b>Гость:</b>{" "}
                                            {spectaclesStore.ticket.people.map((person) => (
                                                <span className={styles.listSpan} key={person.ID}>
                                                {person.fio}
                                            </span>
                                            ))}
                                        </li>
                                    }
                                </ul>
                                <Button
                                    type={'button'}
                                    theme={"public_primary"}
                                    text={"Полная отмена бронирования"}
                                    spinnerActive={sending}
                                    onClick={onSendAllRequest}
                                />
                                {
                                    spectaclesStore.ticket.people
                                    &&
                                    spectaclesStore.ticket.people.length > 1
                                    &&
                                    <fieldset className={styles.fieldset}>
                                        <h3 className={`${commonStyles.subtitle} ${styles.caption}`}>Частичная
                                            отмена:</h3>
                                        {spectaclesStore.ticket.people.map(person => (
                                            <FieldInputComponent
                                                key={person.ID}
                                                id={person.ID}
                                                label={person.fio}
                                                type={"checkbox"}
                                                {...register("" + person.ID)}
                                            />
                                        ))}
                                        <br/>
                                        <Button spinnerActive={sending} theme={"public_primary"} text={"Подтвердить частичную отмену"}/>
                                    </fieldset>
                                }
                            </form>
                        </div>
                    </section>
                )}
                {notif}
            </>
        );
    };

    const NotFound = () => {
        return (
            <>
                {!spectaclesStore.loading && Object.keys(spectaclesStore.ticket).length === 0 && (
                    <section className={[commonStyles.wrap, commonStyles.wrap_bubble].join(" ")}>
                        <div className={styles.section}>
                            <h2 className={[commonStyles.title, commonStyles.title_underline].join(" ")}>
                                Билеты не найдены или событие прошло.
                            </h2>
                        </div>
                    </section>
                )}
            </>
        );
    };

    return (
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{delay: 0.2, duration: 1}}
        >
            <Breadcrumbs
                items={[
                    {
                        title: "Главная",
                        url: "/",
                    },
                    {
                        title: "Репертуар школьных театров",
                        url: "/spectacles",
                    },
                    {
                        title: "Отмена бронирования билетов",
                        url: "",
                    },
                ]}
            />
            <Loading/>
            <MainBlock/>
            <NotFound/>
        </motion.div>
    );
};

export default CancelSpectacleBookingPage;

import React from 'react';
import {useNavigate} from "react-router-dom";

import useActivitiesStore from "../../../store/admin/activitiesStore";
import useAuthStore from "../../../store/authStore";

import Tabs from "../../../components/tabs/tabs.component";
import Tab from "../../../components/tabs/tab.component";
import Button from "../../../components/button/button.component";
import Table from "../../../components/table/table.component";

const AdminActivitiesPage = () => {
    const { user } = useAuthStore();
    const navigate = useNavigate();

    //Private components
    const CityClub = () => {
        const activitiesStore = useActivitiesStore();

        const fetchData = async () => {
            await activitiesStore.loadCityClubMeetings();
        };

        React.useEffect(() => {
            fetchData();
        }, []);

        const clubMeetingsItemsConfig = [
            {
                header: "ID",
                key: "ID",
                type: "int",
                filter: "number",
                sorting: true,
            },
            {
                header: "Название",
                key: "title",
                type: "string",
                filter: "string",
                sorting: true,
            },
            {
                header: "Кол-во билетов",
                key: "tickets",
                type: "int",
                filter: "int",
                sorting: true,
            },
            {
                header: "Забронировано билетов",
                key: "ticketsLeft",
                type: "int",
                filter: "int",
                sorting: true,
            },
            {
                header: "Дата",
                key: "datetime",
                type: "datetime",
                filter: "date",
                sorting: true,
            },
            {
                header: "Статус",
                key: "status",
                type: "string",
                filter: "select",
                sorting: true,
            },
        ];

        const onClubMeetingsItemClick = (props) => {
            navigate(`/admin/activity/cityClub/${props}`);
        };

        return (
            <Table
                title={"Таблица ближайших встреч Клуба администратора" + user.ID}
                loading={activitiesStore.loading}
                items={activitiesStore.cityClubMeetings}
                itemsConfig={clubMeetingsItemsConfig}
                onItemClick={onClubMeetingsItemClick}
                withFilter={true}
            >
                <Button
                    type="button"
                    iconClass={"mdi mdi-plus"}
                    text="Создать"
                    size="small"
                    aria-label="Создать встречу"
                    onClick={() => navigate("/admin/activity/cityClub/new")}
                />
            </Table>
        );
    };

    const MethodologicalSupport = () => {
        const activitiesStore = useActivitiesStore();

        const fetchData = async () => {
            await activitiesStore.loadMethodologicalSupports();
        };

        React.useEffect(() => {
            fetchData();
        }, []);

        const itemsConfig = [
            {
                header: "ID",
                key: "ID",
                type: "int",
                filter: "number",
                sorting: true,
            },
            {
                header: "Название",
                key: "title",
                type: "string",
                filter: "string",
                sorting: true,
            },
            {
                header: "Сортировка",
                key: "sorting",
                type: "int",
                filter: "int",
                sorting: true,
            },
        ];

        const onItemClick = (props) => {
            navigate(`/admin/activity/methodologicalSupport/${props}`);
        };

        return (
            <Table
                title={"Таблица методической поддержки администратора" + user.ID}
                loading={activitiesStore.loading}
                items={activitiesStore.methodologicalSupports}
                itemsConfig={itemsConfig}
                onItemClick={onItemClick}
                withFilter={true}
            >
                <Button
                    type="button"
                    iconClass={"mdi mdi-plus"}
                    text="Создать"
                    size="small"
                    aria-label="Создать запись"
                    onClick={() => navigate("/admin/activity/methodologicalSupport/new")}
                />
            </Table>
        );
    };

    const AssistanceMentor = () => {

    };

    return (
        <>
            <Tabs title={"Вкладки методической поддержки администратора"}>
                <Tab title={"Городской клуб"}>
                    <CityClub />
                </Tab>
                <Tab title={"Методическая поддержка"}>
                    <MethodologicalSupport />
                </Tab>
                <Tab title={"Помощь консультантов наставников"}>
                    <AssistanceMentor />
                </Tab>
            </Tabs>
        </>
    );
};

export default AdminActivitiesPage;
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import useAuthStore from "../../../store/authStore";
import useSchoolsStore from "../../../store/admin/schoolsStore";
import useTeachersStore from "../../../store/admin/teachersStore";

import Button from "../../../components/button/button.component";
import FieldInput from "../../../components/field/field.input.component";
import Notif from "../../../components/notif/notif.component";
import ImageChange from "../../../components/image_change_block/image.change.component";
import MultiSelect from "../../../components/multi_select/multi_select.component";
import SingleImageWithPreview from "../../../components/single_image_with_preview/single.image.with.preview";

import styles from "../../../components/page_components/theatre/theatre.module.scss";
import commonStyles from "../../common.module.scss";

const TeacherPage = () => {
    let {id} = useParams();
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        reset,
        control,
        getValues
    } = useForm();

    const {user} = useAuthStore();
    const teachersStore = useTeachersStore();
    const schoolsStore = useSchoolsStore();

    const [edit, setEdit] = React.useState(false);

    const fetchData = async () => {
        if (id) {
            reset();
            schoolsStore.loadSchools();
            await teachersStore.loadTeacher({id});
        }
    };

    React.useEffect(() => {
        fetchData();

        return () => {
            teachersStore.clearTeacher();
        }
    }, [id]);

    const back = () => navigate("/admin/teachers");

    //Private component
    const Loading = () => {
        if (teachersStore.loading) {
            return (
                <div className={commonStyles.title_block}>
                    <h1 className={commonStyles.title}>Загрузка...</h1>
                </div>
            );
        }
    };

    const NotFound = () => {
        if (id && !teachersStore.loading && Object.keys(teachersStore.teacher).length === 0) {
            return (
                <div className={commonStyles.title_block}>
                    <Button
                        type='button'
                        theme='text'
                        size='small'
                        iconClass={"mdi mdi-arrow-left"}
                        isIconBtn={true}
                        aria-label='Назад'
                        onClick={back}
                    />
                    <h1 className={commonStyles.title}>Педагог не найден</h1>
                </div>
            );
        }
    };

    const MainBlock = () => {

        const ViewTeacher = () => {

            if (id && !edit && !teachersStore.loading && Object.keys(teachersStore.teacher).length > 0) {
                return (
                    <>
                        <div className={commonStyles.title_block}>
                            <Button
                                type='button'
                                theme='text'
                                size='small'
                                iconClass={"mdi mdi-arrow-left"}
                                isIconBtn={true}
                                aria-label='Назад'
                                onClick={back}
                            />
                            <h1 className={commonStyles.title}>Педагог ID: {teachersStore.teacher.ID}</h1>
                            <Button
                                size='smaller'
                                type='submit'
                                isIconBtn='true'
                                iconClass={"mdi mdi-pencil"}
                                theme='outline'
                                aria-label='Редактировать новость'
                                onClick={() => {
                                    setEdit(true);
                                }}
                            />
                        </div>
                        <ul className={styles.list}>
                            <li className={styles.item}>
                                <h3 className={styles.label}>Доступен для выбора?</h3>
                                <p className={styles.description}>
                                    {teachersStore.teacher.active === "Активен" ? "Да" : "Нет"}
                                </p>
                            </li>
                            <li
                                className={styles.item}
                                style={{ alignItems: "center" }}
                            >
                                <h3 className={styles.label}>Фото</h3>
                                <div className={styles.logoBlock}>
                                    <SingleImageWithPreview image={teachersStore.teacher.photo} />
                                </div>
                            </li>
                            <li className={styles.item}>
                                <h3 className={styles.label}>Фамилия</h3>
                                <p className={styles.description}>{teachersStore.teacher.f}</p>
                            </li>
                            <li className={styles.item}>
                                <h3 className={styles.label}>Имя</h3>
                                <p className={styles.description}>{teachersStore.teacher.i}</p>
                            </li>
                            <li className={styles.item}>
                                <h3 className={styles.label}>Отчество</h3>
                                <p className={styles.description}>{teachersStore.teacher.o}</p>
                            </li>
                            <li className={styles.item}>
                                <h3 className={styles.label}>Должность</h3>
                                <p className={styles.description}>{teachersStore.teacher.position}</p>
                            </li>
                            <li className={styles.item}>
                                <h3 className={styles.label}>Педагогический стаж</h3>
                                <p className={styles.description}>{teachersStore.teacher.experience}</p>
                            </li>
                            <li className={styles.item}>
                                <h3 className={styles.label}>О себе</h3>
                                <p className={styles.description}>{teachersStore.teacher.text}</p>
                            </li>
                            <li className={styles.item}>
                                <h3 className={styles.label}>Школа</h3>
                                <p className={styles.description}>{teachersStore.teacher.school}</p>
                            </li>
                        </ul>
                    </>
                );
            }

        };

        const AddTeacher = () => {
            const [popup, setPopup] = React.useState(<></>);
            const [sending, setSending] = React.useState(false);

            const onAddSubmit = async (params) => {
                if (params.photo.length > 0 && !checkPhoto(params.photo[0]))
                    return;

                params.schoolID = getValues().schoolID.value;

                const result = await teachersStore.addTeacher(params);

                if (!result.error)
                    back();
                else
                    setPopup(
                        <Notif
                            title='Ошибка'
                            text={result.message}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
            };

            const checkPhoto = (file) => {
                if (!file) {
                    setPopup(
                        <Notif
                            title='Ошибка'
                            text={"Файл не читается."}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                if (file.type.match("image.*")) {
                    if (file.size > 2500000) {
                        setPopup(
                            <Notif
                                title='Ошибка'
                                text={"Файл больше 2,5 Мб."}
                                opened={true}
                                onClose={() => {
                                    setPopup(<></>);
                                }}
                            />
                        );
                        return false;
                    }
                } else {
                    setPopup(
                        <Notif
                            title='Ошибка'
                            text={"Файл должен быть изображением."}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
                    return false;
                }

                return true;
            };

            if(!id)
                return (
                    <>
                        <div className={commonStyles.title_block}>
                            <Button
                                type="button"
                                isIconBtn={true}
                                iconClass={"mdi mdi-arrow-left"}
                                theme="text"
                                aria-label="Назад"
                                onClick={back}
                            />
                            <h1 className={commonStyles.title}>Создание педагога</h1>
                        </div>
                        <form
                            onSubmit={handleSubmit(onAddSubmit)}
                            className="form"
                        >
                            <div className="form__container --view-two-columns">
                                <fieldset className="form__section">
                                    <h2 className="form__title">Основная информация</h2>
                                    <FieldInput
                                        label={"Фото"}
                                        type="file"
                                        placeholder={"Выберите фото..."}
                                        layout={"flex"}
                                        {...register("photo")}
                                    />
                                    <FieldInput
                                        label={"Фамилия"}
                                        placeholder={"Введите фамилию..."}
                                        layout={"flex"}
                                        required={true}
                                        {...register("f")}
                                    />
                                    <FieldInput
                                        label={"Имя"}
                                        placeholder={"Введите имя..."}
                                        layout={"flex"}
                                        required={true}
                                        {...register("i")}
                                    />
                                    <FieldInput
                                        label={"Отчество"}
                                        placeholder={"Введите отчество..."}
                                        layout={"flex"}
                                        required={true}
                                        {...register("o")}
                                    />
                                    <FieldInput
                                        label={"Должность"}
                                        placeholder={"Введите должность..."}
                                        layout={"flex"}
                                        required={true}
                                        {...register("position")}
                                    />
                                    <FieldInput
                                        label={"Педагогический стаж"}
                                        placeholder={"Введите педагогический стаж..."}
                                        layout={"flex"}
                                        required={true}
                                        {...register("experience")}
                                    />
                                    <FieldInput
                                        label={"О себе"}
                                        type={"textarea"}
                                        rows={5}
                                        placeholder={"Введите описание..."}
                                        layout={"flex"}
                                        required={true}
                                        {...register("text")}
                                    />
                                    <fieldset className="form__section">
                                        <h2 className="form__title">Школа</h2>
                                        <MultiSelect
                                            control={control}
                                            isMulti={false}
                                            name={"schoolID"}
                                            closeMenuOnSelect={false}
                                            options={schoolsStore?.schools?.map(
                                                (item) => {
                                                    return {
                                                        label: item.org_short_name,
                                                        value: item.ID,
                                                    };
                                                }
                                            )}
                                        />
                                    </fieldset>
                                </fieldset>
                                <fieldset className="form__section">
                                    <h2 className="form__title">Отображение</h2>
                                    <FieldInput
                                        label={"Доступен для выбора?"}
                                        type={"checkbox_variant"}
                                        {...register("active", {
                                            value: true,
                                        })}
                                    />
                                </fieldset>
                            </div>
                            <div className="form__controls">
                                <Button
                                    type="submit"
                                    text={"Создать"}
                                    spinnerActive={sending}
                                />
                            </div>
                        </form>
                        {popup}
                    </>
                );
        }

        const EditTeacher = () => {

            const [popup, setPopup] = React.useState(<></>);
            const [photo, setPhoto] = React.useState(null);
            const [sending, setSending] = React.useState(false);

            const onEditSubmit = async (params) => {
                if (photo === null)
                    params.deletephoto = 1;
                else
                    params.photo = photo;

                params.id = id;
                params.schoolID = getValues().schoolID.value;

                const result = await teachersStore.editTeacher(params);

                if (!result.error){
                    fetchData();
                    setEdit(false);
                }
            };

            const onDeleteSubmit = async () => {
                const result = await teachersStore.removeTeacher({id});

                if (!result.error)
                    back();
                else
                    setPopup(
                        <Notif
                            title='Ошибка'
                            text={result.message}
                            opened={true}
                            onClose={() => {
                                setPopup(<></>);
                            }}
                        />
                    );
            };

            const handleDeleteButton = () => {
                setPopup(
                    <Notif
                        text={"Вы уверены что хотите удалить?"}
                        opened={true}
                        onClose={() => setPopup(<></>)}
                        buttons={
                            <>
                                <Button
                                    type="button"
                                    size={"small"}
                                    text={"Нет"}
                                    theme="text"
                                    onClick={() => setPopup(<></>)}
                                />
                                <Button
                                    type="button"
                                    size={"small"}
                                    theme="info"
                                    text={"Да"}
                                    onClick={async () => {
                                        await onDeleteSubmit();
                                    }}
                                />
                            </>
                        }
                    />
                );
            }

            const handleChangeLogo = (item) => {
                setPhoto(item);
            };

            const handleDeleteLogo = () => {
                setPhoto(null);
            };

            if (id && edit && !teachersStore.loading && Object.keys(teachersStore.teacher).length > 0)
                return (
                    <>
                        <div className={commonStyles.title_block}>
                            <Button
                                type="button"
                                isIconBtn={true}
                                iconClass={"mdi mdi-arrow-left"}
                                theme="text"
                                aria-label="Назад"
                                onClick={() => setEdit(false)}
                            />
                            <h1 className={commonStyles.title}>
                                Редактирование педагога ID: {id}
                            </h1>
                        </div>
                        <form
                            onSubmit={handleSubmit(onEditSubmit)}
                            className="form"
                        >
                            <div className="form__container --view-two-columns">
                                <fieldset className="form__section">
                                    <h2 className="form__title">Основная информация</h2>
                                    <div className="form__multy-block">
                                        <p className="form__label">Фото</p>
                                        <ImageChange
                                            image={teachersStore.teacher.photo ? process.env.REACT_APP_BASE_URL + teachersStore.teacher.photo : ""}
                                            onChange={handleChangeLogo}
                                            onDelete={handleDeleteLogo}
                                        />
                                    </div>
                                    <FieldInput
                                        label={"Фамилия"}
                                        placeholder={"Введите фамилию..."}
                                        layout={"flex"}
                                        required={true}
                                        {...register("f", {value: teachersStore.teacher.f})}
                                    />
                                    <FieldInput
                                        label={"Имя"}
                                        placeholder={"Введите имя..."}
                                        layout={"flex"}
                                        required={true}
                                        {...register("i", {value: teachersStore.teacher.i})}
                                    />
                                    <FieldInput
                                        label={"Отчество"}
                                        placeholder={"Введите отчество..."}
                                        layout={"flex"}
                                        required={true}
                                        {...register("o", {value: teachersStore.teacher.o})}
                                    />
                                    <FieldInput
                                        label={"Должность"}
                                        placeholder={"Введите должность..."}
                                        layout={"flex"}
                                        required={true}
                                        {...register("position", {
                                            value: teachersStore.teacher.position,
                                        })}
                                    />
                                    <FieldInput
                                        label={"Педагогический стаж"}
                                        placeholder={"Введите педагогический стаж..."}
                                        layout={"flex"}
                                        required={true}
                                        {...register("experience", {
                                            value: teachersStore.teacher.experience,
                                        })}
                                    />
                                    <FieldInput
                                        label={"О себе"}
                                        type={"textarea"}
                                        rows={5}
                                        placeholder={"Введите описание..."}
                                        layout={"flex"}
                                        required={true}
                                        {...register("text", {
                                            value: teachersStore.teacher.text,
                                        })}
                                    />
                                    <fieldset className="form__section">
                                        <h2 className="form__title">Школа</h2>
                                        <MultiSelect
                                            control={control}
                                            isMulti={false}
                                            name={"schoolID"}
                                            closeMenuOnSelect={false}
                                            values={{
                                                label: teachersStore.teacher
                                                    .school,
                                                value: teachersStore.teacher
                                                    .schoolID,
                                            }}
                                            options={schoolsStore?.schools?.map(
                                                (item) => {
                                                    return {
                                                        label: item.org_short_name,
                                                        value: item.ID,
                                                    };
                                                }
                                            )}
                                        />
                                    </fieldset>
                                </fieldset>
                                <fieldset className="form__section">
                                    <h2 className="form__title">Отображение</h2>
                                    <FieldInput
                                        label={"Доступен для выбора?"}
                                        type={"checkbox_variant"}
                                        {...register("active", {
                                            value: teachersStore.teacher.active === "Активен",
                                        })}
                                    />
                                </fieldset>
                            </div>
                            <div className="form__controls">
                                <Button
                                    type="submit"
                                    text={"Сохранить"}
                                    spinnerActive={sending}
                                />
                                <Button
                                    type="button"
                                    theme="text"
                                    iconClass={"mdi mdi-delete"}
                                    spinnerActive={sending}
                                    onClick={handleDeleteButton}
                                    text={"Удалить"}
                                />
                            </div>
                        </form>
                        {popup}
                    </>
                );
        }

        return (
            <>
                <ViewTeacher/>
                <AddTeacher/>
                <EditTeacher/>
            </>
        );
    };

    return (
        <>
            <Loading/>
            <MainBlock/>
            <NotFound/>
        </>
    );
};

export default TeacherPage;

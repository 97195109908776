import React from 'react';
import {useNavigate} from "react-router-dom";

import useAuthStore from "../../../store/authStore";

import Table from "../../../components/table/table.component";
import Button from "../../../components/button/button.component";
import useScenarioBankStore from "../../../store/admin/scenarioBankStore";


const ScenariosPage = () => {
    const navigate = useNavigate();

    const { user } = useAuthStore();
    const scenarioBankStore = useScenarioBankStore();

    const onItemClick = (props) => {
        navigate(`/admin/scenarioBank/${props}`);
    };

    const fetchData = async () => {
        await scenarioBankStore.loadAll();
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    const itemConfig = [
        {
            header: "ID",
            key: "ID",
            type: "int",
            filter: "number",
            sorting: true,
        },
        {
            header: "Название",
            key: "title",
            type: "string",
            filter: "string",
            sorting: true,
        },
        {
            header: "Автор",
            key: "author",
            type: "string",
            filter: "string",
            sorting: true,
        },
        {
            header: "Жанр",
            key: "genres",
            type: "array",
            filter: "array",
            arrayKey: "title",
        },
        {
            header: "Для пользователей",
            key: "need_auth",
            type: "string",
            filter: "select",
            sorting: true,
        },
        {
            header: "Статус",
            key: "active",
            type: "string",
            filter: "select",
            sorting: true,
        },
    ];

    return (
        <Table
            title={"Таблица банка сценариев администратора" + user.ID}
            loading={scenarioBankStore.loading}
            items={scenarioBankStore.scenarios}
            itemsConfig={itemConfig}
            onItemClick={onItemClick}
            withFilter={true}
        >
            <Button
                type="button"
                text="Создать"
                theme="primary"
                size="small"
                iconClass={"mdi mdi-plus"}
                aria-label="Добавить сценарий"
                onClick={() => navigate("/admin/scenarioBank/new")}
            />
        </Table>
    );
};

export default ScenariosPage;
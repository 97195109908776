import axios from "axios";

import create from 'zustand'

const urlLoadFestivals = process.env.REACT_APP_BASE_URL + 'php/models/user/festivals/load.php';
const urlLoadFestival = process.env.REACT_APP_BASE_URL + 'php/models/user/festivals/load_by_id.php';
const urlLoadFestivalRequests = process.env.REACT_APP_BASE_URL + 'php/models/user/festivals/load_requests.php';
const urlLoadFestivalRequest = process.env.REACT_APP_BASE_URL + 'php/models/user/festivals/load_request_by_id.php';
const urlAddFestivalRequest = process.env.REACT_APP_BASE_URL + 'php/models/user/festivals/add_request.php';
const urlEditFestivalRequest = process.env.REACT_APP_BASE_URL + 'php/models/user/festivals/edit_request_by_id.php';
const urlReSendFestivalRequest = process.env.REACT_APP_BASE_URL + 'php/models/user/festivals/resend_request.php';
const urlRemoveFestivalRequest = process.env.REACT_APP_BASE_URL + 'php/models/user/festivals/remove_request_by_id.php';

const useFestivalsStore = create(
    (set, get) => ({
        festivals: [],
        festival: null,
        festivalRequests: [],
        festivalRequest: null,

        loading: false,
        sending: false,
        error: false,
        errorText: "",

        setErrorText: (text) => {
            set({ error: true, errorText: text });
        },
        clearErrorText: () => {
            set({ error: false, errorText: "" });
        },

        loadFestivals: async (params) => {

            set({ loading: true });

            let form = new FormData();
            window.global.buildFormData(form, params);

            const response = await axios.postForm(urlLoadFestivals, form).catch(error => {
                set({ sending: false, error: true, errorText: error });
                return { error: true };
            });

            set({ loading: false });

            if (response?.data?.params) {

                set((state) => ({ festivals: response.data.params }));

            }

        },
        loadFestival: async (params) => {

            set({ loading: true, theatre: null });

            let form = new FormData();
            window.global.buildFormData(form, params);

            const response = await axios.postForm(urlLoadFestival, form).catch(error => {
                set({ sending: false, error: true, errorText: error });
                return { error: true };
            });

            set({ loading: false });

            if (response?.data?.params) {

                set((state) => ({ festival: response.data.params }));
                return response.data.params;

            }

        },

        loadFestivalRequests: async (params) => {

            set({ loading: true });

            let form = new FormData();
            window.global.buildFormData(form, params);

            const response = await axios.postForm(urlLoadFestivalRequests, form).catch(error => {
                set({ loading: false, sending: false, error: true, errorText: error });
                return { error: true };
            });

            set({ loading: false });

            if (response?.data?.params) {

                set((state) => ({ festivalRequests: response.data.params }));

            }

        },
        loadFestivalRequest: async (params) => {

            set({ loading: true, theatreRequest: null });

            let form = new FormData();
            window.global.buildFormData(form, params);

            const response = await axios.postForm(urlLoadFestivalRequest, form).catch(error => {
                set({ loading: false, sending: false, error: true, errorText: error });
                return { error: true };
            });

            set({ loading: false });

            if (response?.data?.params) {

                set({ festivalRequest: response.data.params });
                return response.data.params;

            }

        },

        addFestivalRequest: async (params) => {

            set({ sending: true });

            let form = new FormData();
            window.global.buildFormData(form, params);

            const response = await axios.postForm(urlAddFestivalRequest, form).catch(error => {
                set({ sending: false, error: true, errorText: error });
                return { error: true };
            });

            set({ sending: false });

            if (response?.data) {

                if (response.data.error === 1) {

                    set((state) => ({
                        error: true,
                        errorText: response.data.error_text
                    }));

                    return { error: true };

                }

            }

            return { error: false };

        },
        editFestivalRequest: async (params) => {

            set({ sending: true });

            let form = new FormData();
            window.global.buildFormData(form, params);

            const response = await axios.postForm(urlEditFestivalRequest, form).catch(error => {
                set({ sending: false, error: true, errorText: error });
                return { error: true };
            });

            set({ sending: false });

            if (response?.data) {

                if (response.data.error === 1) {

                    set((state) => ({
                        error: true,
                        errorText: response.data.error_text
                    }));

                    return { error: true };

                }

            }

            return { error: false };

        },
        resendFestivalRequest: async (params) => {

            set({ sending: true });

            let form = new FormData();
            window.global.buildFormData(form, params);

            const response = await axios.postForm(urlReSendFestivalRequest, form).catch(error => {
                set({ sending: false, error: true, errorText: error });
                return { error: true };
            });

            set({ sending: false });

            if (response?.data) {

                if (response.data.error === 1) {

                    set((state) => ({
                        error: true,
                        errorText: response.data.error_text
                    }));

                    return { error: true };

                }

            }

            return { error: false };

        },
        removeFestivalRequest: async (params) => {

            set({ sending: true });

            let form = new FormData();
            window.global.buildFormData(form, params);

            const response = await axios.postForm(urlRemoveFestivalRequest, form).catch(error => {
                set({ sending: false, error: true, errorText: error });
                return { error: true };
            });

            set({ sending: false });

            if (response?.data) {

                if (response.data.error === 1) {

                    set({
                        error: true,
                        errorText: response.data.error_text
                    });

                    return { error: true };

                }

            }

            return { error: false };

        },
    })
);

export default useFestivalsStore;
import React from 'react';
import {useNavigate} from "react-router-dom";

import useSpectaclesStore from "../../../store/admin/spectaclesStore";
import useAuthStore from "../../../store/authStore";

import Table from "../../../components/table/table.component";

const AdminSpectacleSchedulesPage = () => {
    const { user } = useAuthStore();
    const navigate = useNavigate();
    const spectaclesStore = useSpectaclesStore();

    const onItemClick = (props) => {
        navigate(`/admin/spectacleSchedules/${props}`);
    };

    const fetchData = async () => {
        await spectaclesStore.loadSpectacleSchedules();
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    const itemConfig = [
        {
            header: "ID",
            key: "ID",
            type: "int",
            filter: "number",
            sorting: true,
        },
        {
            header: "Название театра",
            key: "theatreTitle",
            type: "string",
            filter: "string",
            sorting: true,
        },
        {
            header: "Название постановки",
            key: "spectacleTitle",
            type: "string",
            filter: "string",
            sorting: true,
        },
        {
            header: "Кол-во мест всего",
            key: "placeCount",
            type: "int",
            filter: "number",
            sorting: true,
        },
        {
            header: "Кол-во билетов всего",
            key: "ticketsCount",
            type: "int",
            filter: "number",
            sorting: true,
        },
        // {
        //     header: "Кол-во оставшихся билетов",
        //     key: "ticketsLeft",
        //     type: "int",
        //     filter: "number",
        //     sorting: true,
        // },
        {
            header: "Дата события",
            key: "date",
            type: "datetime",
            filter: "date",
            sorting: true,
        },
        {
            header: "В рамках фестиваля",
            key: "festival",
            type: "string",
            filter: "select",
            sorting: true,
        },
        {
            header: "Баллы",
            key: "score",
            type: "int",
            filter: "number",
            sorting: true,
        },
        {
            header: "Баллы за сезон 1",
            key: "score_season1",
            type: "int",
            filter: "number",
            sorting: true,
        },
        {
            header: "Баллы за сезон 2",
            key: "score_season2",
            type: "int",
            filter: "number",
            sorting: true,
        },
        {
            header: "Баллы за сезон 3",
            key: "score_season3",
            type: "int",
            filter: "number",
            sorting: true,
        },
        {
            header: "Дата обновления",
            key: "update_time",
            type: "datetime",
            filter: "date",
            sorting: true,
        },
    ];

    return (
        <Table
            title={"Таблица расписаний на постановки администратора" + user.ID}
            loading={spectaclesStore.loading}
            items={spectaclesStore.spectacleSchedules}
            itemsConfig={itemConfig}
            onItemClick={onItemClick}
            withFilter={true}
        />
    );
};

export default AdminSpectacleSchedulesPage;
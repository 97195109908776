import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { NavLink, useParams } from "react-router-dom";
import createDOMPurify from "dompurify";
import { motion } from "framer-motion";
import moment from "moment";
import { useForm } from "react-hook-form";

import useSpectaclesStore from "../../../store/public/spectaclesStore";
import useTeachersStore from "../../../store/public/teachersStore";

import FieldInputComponent from "../../../components/field/field.input.component";
import Button from "../../../components/button/button.component";
import ImagePreview from "../../../components/image_preview/image.preview.component";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumbs.component";
import VideoPlayer from "../../../components/video_player/video.player.component";
import MultiSelect from "../../../components/multi_select/multi_select.component";
import Notif from "../../../components/notif/notif.component";

import commonStyles from "../common.module.scss";
import styles from "./spectacle.module.scss";

import { EventIcons, FileIcons } from "../../../components/svgs.js";

const SpectaclePage = () => {
    const DOMPurify = createDOMPurify(window);

    const { id } = useParams();

    const ticketsFormRef = React.useRef();
    const { register, handleSubmit, reset, control, setValue, getValues } = useForm();

    const spectaclesStore = useSpectaclesStore();
    const teachersStore = useTeachersStore();

    const [spectacleID, setSpectacleID] = React.useState(0);
    const [preview, setPreview] = React.useState(<></>);
    const [popup, setPopup] = React.useState(<></>);

    const fetchData = async () => {
        await spectaclesStore.loadSpectacle({ id });
    };

    React.useEffect(() => {
        fetchData();
    }, [id]);

    React.useEffect(() => {
        //console.log(spectaclesStore.spectacle);
    }, [spectaclesStore.spectacle]);

    const handleGalleryClick = (event) => {
        handleImagePreview(spectaclesStore.spectacle.photo);
    };

    const handleImagePreview = (images, index = 0) => {
        //console.log(images);
        setPreview(<ImagePreview open={true} index={index} items={images} onClose={() => setPreview(<></>)} />);
    };

    const handlePreview = (item, index = 0) => {
        if (item && item.type === "pdf") {
            const link = document.createElement("a");
            link.href = process.env.REACT_APP_BASE_URL + item.url;
            link.target = "_blank";
            link.setAttribute("download", item.title);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } else {
            let imageItems = spectaclesStore.spectacle.files.filter((item) => item.type === "image");
            imageItems = [...spectaclesStore.spectacle.posters, ...imageItems];

            handleImagePreview(imageItems, index);
        }
    };

    // Private components
    const Loading = () => {
        return (
            <>
                {(spectaclesStore.loading || teachersStore.loading) && (
                    <section className={[commonStyles.wrap, commonStyles.wrap_bubble].join(" ")}>
                        <div className={styles.section}>
                            <h2 className={[commonStyles.title, commonStyles.title_underline].join(" ")}>
                                загрузка...
                            </h2>
                        </div>
                    </section>
                )}
            </>
        );
    };

    const NotFound = () => {
        return (
            <>
                {!spectaclesStore.loading &&
                    !teachersStore.loading &&
                    (!spectaclesStore.spectacle || Object.keys(spectaclesStore.spectacle).length === 0) && (
                        <section className={[commonStyles.wrap, commonStyles.wrap_bubble].join(" ")}>
                            <div className={styles.section}>
                                <h2 className={[commonStyles.title, commonStyles.title_underline].join(" ")}>
                                    Постановка не найдена
                                </h2>
                            </div>
                        </section>
                    )}
            </>
        );
    };

    const MainInfo = () => {
        const Schedules = () => {
            const Schedule = ({ item }) => {
                return (
                    <>
                        <li
                            className={[
                                styles.ticketItem,
                                item.ticketsLeft > 0 ? null : styles.ticketItemDisabled,
                            ].join(" ")}
                            onClick={() => {
                                if (item.ticketsLeft > 0) {
                                    reset();
                                    setSpectacleID(item.ID);
                                    ticketsFormRef.current.scrollIntoView();
                                }
                            }}
                        >
                            <p className={styles.ticketStatus}>
                                {item.ticketsLeft > 0
                                    ? `${item.ticketsLeft} ${window.global.declOfNum(item.ticketsLeft, [
                                          "билет",
                                          "билета",
                                          "билетов",
                                      ])}`
                                    : "Билетов нет"}
                            </p>
                            <p className={styles.ticketDate}>
                                {moment(item.date).format("DD MMMM")} <br />
                                {moment(item.date).format("HH:mm")}
                            </p>
                        </li>
                    </>
                );
            };

            return (
                <>
                    {spectaclesStore.spectacle.schedules && spectaclesStore.spectacle.schedules.length > 0 && (
                        <ul className={styles.ticketList}>
                            {spectaclesStore.spectacle.schedules.map((item) => (
                                <Schedule key={item.ID} item={item} />
                            ))}
                        </ul>
                    )}
                </>
            );
        };

        const Posters = () => {
            return (
                <>
                    {(spectaclesStore.spectacle.posters.length > 0 || spectaclesStore.spectacle.files.length > 0) && (
                        <div className={[styles.column, styles.column_asside].join(" ")}>
                            <h3 className={styles.caption}>
                                Афиша/программка
                                <span className={styles.eventIcon}> {EventIcons.supportive_waist}</span>
                            </h3>
                            <Splide
                                className='my-splide my-splide_border-radius-sm my-splide_handle-preview'
                                options={{
                                    type: "loop",
                                    arrows: false,
                                    perPage: 1,
                                    perMove: 1,
                                    gap: "1.25em",
                                    rewind: true,
                                    pauseOnHover: true,
                                    autoplay: true,
                                    mediaQuery: "min",
                                }}
                            >
                                {spectaclesStore.spectacle.posters.map((item, index) => (
                                    <>
                                        {item.url !== "" && (
                                            <SplideSlide
                                                data-splide-interval='5000'
                                                key={index}
                                                onClick={() => {
                                                    handlePreview(item, index);
                                                }}
                                            >
                                                <img
                                                    key={index}
                                                    className={styles.poster}
                                                    src={process.env.REACT_APP_BASE_URL + item.url}
                                                    alt={process.env.REACT_APP_BASE_URL + item.url}
                                                />
                                            </SplideSlide>
                                        )}
                                    </>
                                ))}
                                {spectaclesStore.spectacle.files.map((item, index) => (
                                    <SplideSlide
                                        data-splide-interval='5000'
                                        key={index}
                                        onClick={() => {
                                            handlePreview(item);
                                        }}
                                    >
                                        {item.type === "image" ? (
                                            <img
                                                key={index}
                                                className={styles.poster}
                                                src={process.env.REACT_APP_BASE_URL + item.url}
                                                alt={process.env.REACT_APP_BASE_URL + item.url}
                                            />
                                        ) : (
                                            <div className={styles.file} key={index}>
                                                {FileIcons.pdf}
                                                <p className={styles.fileName}>{item.title}</p>
                                            </div>
                                        )}
                                    </SplideSlide>
                                ))}
                            </Splide>
                        </div>
                    )}
                </>
            );
        };

        return (
            <div className={styles.section}>
                <h2 className={[commonStyles.title, commonStyles.title_underline].join(" ")}>
                    {spectaclesStore.spectacle.title}
                </h2>
                <div className={styles.columns}>
                    <div className={[styles.column, styles.column_description].join(" ")}>
                        <h3 className={styles.title}>
                            О событии
                            <span className={styles.titleAccent}>{spectaclesStore.spectacle.duration} мин.</span>
                        </h3>
                        <ul className={[styles.list, styles.list_column, ""].join(" ")}>
                            <li>
                                Образовательная организация:{" "}
                                <span className={styles.listAccent}>{spectaclesStore.spectacle.schoolTitle}</span>
                            </li>
                            <li>
                                Школьный театр:{" "}
                                <span className={styles.listAccent}>
                                    <NavLink
                                        className={commonStyles.link}
                                        to={"/theatres/" + spectaclesStore.spectacle.theatreID}
                                    >
                                        {spectaclesStore.spectacle.theatreTitle}
                                    </NavLink>
                                </span>
                            </li>
                            <li>
                                Возрастной состав:{" "}
                                <span className={styles.listAccent}>
                                    {spectaclesStore.spectacle.age_members.map((item) => item.age).join(", ")}
                                </span>
                            </li>
                            <li>
                                Педагоги:{" "}
                                <span className={styles.listAccent}>
                                    {spectaclesStore.spectacle.teachers.map((item) => (
                                        <div key={item.fio}>
                                            {item.fio}
                                            <br />
                                        </div>
                                    ))}
                                </span>
                            </li>
                        </ul>
                        <Schedules />
                        <div className={styles.description}>
                            <div
                                className={styles.editor}
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(spectaclesStore.spectacle.review),
                                }}
                            />
                        </div>
                    </div>
                    <Posters />
                </div>
            </div>
        );
    };

    const Photo = () => {
        return (
            <>
                {spectaclesStore.spectacle.photo && spectaclesStore.spectacle.photo.length > 0 && (
                    <div className={styles.section}>
                        <h3 className={styles.title}>
                            Фотографии
                            <span className={styles.eventIcon}> {EventIcons.supportive_waist}</span>
                        </h3>
                        <Splide
                            className='my-splide my-splide_border-radius-sm my-splide_handle-preview'
                            options={{
                                // type: "loop",
                                arrows: false,
                                perPage: 1,
                                perMove: 1,
                                cover: true,
                                height: "12.5em",
                                gap: "1.25em",
                                rewind: true,
                                pauseOnHover: true,
                                autoplay: true,
                                mediaQuery: "min",
                                breakpoints: {
                                    768: {
                                        perPage: 2,
                                    },
                                    1024: {
                                        perPage: 4,
                                    },
                                },
                            }}
                        >
                            {spectaclesStore.spectacle.photo.map((item, index) => (
                                <SplideSlide
                                    data-splide-interval='5000'
                                    key={index}
                                    onClick={() => {
                                        handleImagePreview(spectaclesStore.spectacle.photo, index);
                                    }}
                                >
                                    <img
                                        src={process.env.REACT_APP_BASE_URL + item.url}
                                        alt={process.env.REACT_APP_BASE_URL + item.url}
                                    />
                                </SplideSlide>
                            ))}
                        </Splide>
                    </div>
                )}
            </>
        );
    };

    const Video = () => {
        return (
            <>
                {spectaclesStore.spectacle.video && spectaclesStore.spectacle.video.length > 0 && (
                    <div className={styles.section}>
                        <h3 className={styles.title}>
                            Видео анонс-приглашение
                            <span className={styles.eventIcon}> {EventIcons.supportive_waist}</span>
                        </h3>
                        <ul className='gallery-form'>
                            {spectaclesStore.spectacle.video.map((item) => (
                                <li key={item} className='gallery-form__item'>
                                    <VideoPlayer source={item} />
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </>
        );
    };

    const OrderTicketsForm = ({ spectacleID, changeSpectacleID }) => {
        const [ID, setID] = React.useState(0);
        const [notif, setNotif] = React.useState(<></>);
        const [sending, setSending] = React.useState(false);
        const [visible, setVisible] = React.useState(false);
        const [people, setPeople] = React.useState(0);

        React.useEffect(() => {
            if (ID !== 0) {
                const schedule = spectaclesStore.spectacle.schedules.find((item) => item.ID === ID);

                resetForm({
                    dateLabel: `${moment(schedule.date).format("DD MMMM YYYY HH:mm")} (${
                        schedule.ticketsLeft
                    } ${window.global.declOfNum(schedule.ticketsLeft, ["билет", "билета", "билетов"])})`,
                    dateValue: ID,
                });
            }
        }, [ID]);

        React.useEffect(() => {
            if (spectacleID !== 0) {
                setID(spectacleID);
            }
        }, [spectacleID]);

        const resetForm = ({ dateLabel, dateValue }) => {
            setValue("date_select", {
                label: dateLabel,
                value: dateValue,
            });

            setValue("ticketsCount", 1);

            setPeople(1);

            setVisible(true);
        };

        const handleTicketCountChange = () => {
            const currentCount = parseInt(getValues("ticketsCount"));
            const schedule = spectaclesStore.spectacle.schedules.find((item) => item.ID === ID);

            setPeople(currentCount > schedule.ticketsLeft ? schedule.ticketsLeft : currentCount);
        };

        const onSendRequest = async (params) => {
            setSending(true);

            let sendObject = {};

            const schedule = spectaclesStore.spectacle.schedules.find(
                (item) => item.ID === parseInt(params.date_select.value)
            );

            sendObject["theatreTitle"] = spectaclesStore.spectacle.theatreTitle;
            sendObject["schoolTitle"] = spectaclesStore.spectacle.schoolTitle;
            sendObject["spectacleTitle"] = spectaclesStore.spectacle.title;
            sendObject["duration"] = spectaclesStore.spectacle.duration;
            sendObject["address"] = schedule.address;
            sendObject["bookingDate"] = moment().format("DD MMMM YYYY HH:mm");
            sendObject["date"] = [moment(schedule.date).format("DD"), moment(schedule.date).format("MMMM YYYY")];
            sendObject["time"] = moment(schedule.date).format("HH:mm");
            sendObject["spectacleID"] = id;
            sendObject["scheduleID"] = params.date_select.value;
            sendObject["ticketsCount"] = params.ticketsCount;
            sendObject["email"] = params.email;
            sendObject["phone"] = params.phone;

            let people = [];
            for (let i = 0; i < params.ticketsCount; i++) {
                if (params["fio_" + i] === "") {
                    setNotif(
                        <Notif
                            title='Ошибка'
                            text={`ФИО ${i + 1} гостя не заполнено`}
                            opened={true}
                            onClose={() => {
                                setNotif(<></>);
                            }}
                        />
                    );

                    setSending(false);

                    return;
                }

                if (people.includes(params["fio_" + i])) {
                    setNotif(
                        <Notif
                            title='Ошибка'
                            text={`ФИО: ${
                                params["fio_" + i]
                            } уже есть в списке. Пожалуйста удалите или замените данного гостя.`}
                            opened={true}
                            onClose={() => {
                                setNotif(<></>);
                            }}
                        />
                    );

                    setSending(false);

                    return;
                }

                people.push(params["fio_" + i]);
            }

            sendObject["people"] = people;

            const result = await spectaclesStore.sendTicketsRequest(sendObject);

            if (!result.error) {
                reset();
                setSpectacleID(0);
                await fetchData();

                setPopup(
                    <Notif
                        title='Спасибо за заявку!'
                        text={`${params.ticketsCount} ${window.global.declOfNum(params.ticketsCount, [
                            "билет",
                            "билета",
                            "билетов",
                        ])} успешно ${window.global.declOfNum(params.ticketsCount, [
                            "забронирован",
                            "забронированы",
                            "забронированы",
                        ])}. Письмо со списком билетов отправлено на указанную почту.`}
                        opened={true}
                        onClose={() => {
                            spectaclesStore.clearErrorText();
                            setPopup(<></>);
                        }}
                    />
                );
            } else {
                setNotif(
                    <Notif
                        title='Ошибка'
                        text={`${result.errorText}`}
                        opened={true}
                        onClose={() => {
                            setNotif(<></>);
                        }}
                    />
                );
            }

            setSending(false);
        };

        return (
            <>
                {spectaclesStore.spectacle.schedules && spectaclesStore.spectacle.schedules.length > 0 && (
                    <div ref={ticketsFormRef} className={styles.section}>
                        <h3 className={styles.title}>Заказать бесплатный билет</h3>
                        <div className={commonStyles.formWrapper}>
                            <form className={commonStyles.form} onSubmit={handleSubmit(onSendRequest)}>
                                <p className={commonStyles.formTitle}>Оставьте заявку</p>
                                <fieldset className={commonStyles.formFieldset}>
                                    <MultiSelect
                                        disabled={sending}
                                        className={commonStyles.formRow}
                                        required={true}
                                        control={control}
                                        isMulti={false}
                                        name={"date_select"}
                                        closeMenuOnSelect={true}
                                        autoComplete='off'
                                        placeholder={"Выберите дату события"}
                                        onChange={(e) => {
                                            setID(e.value);
                                        }}
                                        options={spectaclesStore.spectacle.schedules.map((item) => {
                                            return {
                                                label: `${moment(item.date).format("DD MMMM YYYY HH:mm")} (${
                                                    item.ticketsLeft
                                                } ${window.global.declOfNum(item.ticketsLeft, [
                                                    "билет",
                                                    "билета",
                                                    "билетов",
                                                ])})`,
                                                value: item.ID,
                                                isDisabled: item.ticketsLeft <= 0 || sending,
                                            };
                                        })}
                                    />
                                    {visible && (
                                        <>
                                            <div className={commonStyles.fieldTikets}>
                                                <Button
                                                    type='button'
                                                    theme={"public_primary"}
                                                    aria-label='Уменьшить'
                                                    title='Уменьшить'
                                                    isIconBtn={true}
                                                    iconClass={"mdi mdi-minus"}
                                                    disabled={sending}
                                                    onClick={() => {
                                                        const currentCount = parseInt(getValues("ticketsCount"));

                                                        if (currentCount > 1) {
                                                            setValue("ticketsCount", currentCount - 1);
                                                            handleTicketCountChange();
                                                        }
                                                    }}
                                                />
                                                <FieldInputComponent
                                                    extraClass={commonStyles.fieldTiketsInput}
                                                    type={"number"}
                                                    placeholder={"Количество билетов..."}
                                                    required={true}
                                                    disabled={sending}
                                                    min={1}
                                                    max={
                                                        ID > 0
                                                            ? spectaclesStore.spectacle.schedules.find(
                                                                  (item) => item.ID === ID
                                                              ).ticketsLeft
                                                            : 1
                                                    }
                                                    {...register("ticketsCount", {
                                                        onBlur: () => {
                                                            handleTicketCountChange();
                                                        },
                                                    })}
                                                />
                                                <Button
                                                    type='button'
                                                    theme={"public_primary"}
                                                    aria-label='Увеличить'
                                                    title='Увеличить'
                                                    isIconBtn={true}
                                                    disabled={sending}
                                                    iconClass={"mdi mdi-plus"}
                                                    onClick={() => {
                                                        const currentCount = parseInt(getValues("ticketsCount"));
                                                        const schedule = spectaclesStore.spectacle.schedules.find(
                                                            (item) => item.ID === ID
                                                        );

                                                        if (currentCount < schedule.ticketsLeft) {
                                                            setValue("ticketsCount", currentCount + 1);
                                                            handleTicketCountChange();
                                                        }
                                                    }}
                                                />
                                            </div>
                                            {[...Array(people)].map((item, index) => (
                                                <FieldInputComponent
                                                    key={index}
                                                    type={"text"}
                                                    autoComplete='off'
                                                    extraClass={commonStyles.formRow}
                                                    placeholder={"ФИО гостя № " + (index + 1) + "..."}
                                                    disabled={sending}
                                                    required={true}
                                                    {...register("fio_" + index, {
                                                        pattern: /^[A-Za-zА-Яа-яЁё\s]+$/i,
                                                    })}
                                                />
                                            ))}
                                            <FieldInputComponent
                                                type={"email"}
                                                placeholder={"Почта..."}
                                                required={true}
                                                disabled={sending}
                                                {...register("email")}
                                            />
                                            <FieldInputComponent
                                                type={"tel"}
                                                placeholder={"Телефон..."}
                                                required={true}
                                                disabled={sending}
                                                {...register("phone")}
                                            />
                                        </>
                                    )}
                                    <Button
                                        type='submit'
                                        theme='public_primary'
                                        extraClass={commonStyles.formRow}
                                        text={"Оставить заявку"}
                                        spinnerActive={sending}
                                    />
                                </fieldset>
                            </form>
                        </div>
                    </div>
                )}
                {notif}
            </>
        );
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 0.2, duration: 1 }}
        >
            <Breadcrumbs
                items={[
                    {
                        title: "Главная",
                        url: "/",
                    },
                    {
                        title: "Репертуар школьных театров",
                        url: "/spectacles",
                    },
                    {
                        title:
                            "Постановка " +
                            (Object.keys(spectaclesStore.spectacle).length > 0
                                ? spectaclesStore.spectacle.title
                                : " не найдена"),
                        url: "",
                    },
                ]}
            />
            <Loading />
            {!spectaclesStore.loading &&
                !teachersStore.loading &&
                spectaclesStore.spectacle &&
                Object.keys(spectaclesStore.spectacle).length > 0 && (
                    <>
                        <section className={[commonStyles.wrap, commonStyles.wrap_bubble].join(" ")}>
                            <MainInfo />
                            <Photo />
                            <Video />
                            <OrderTicketsForm spectacleID={spectacleID} />
                        </section>
                    </>
                )}
            <NotFound />
            {preview}
            {popup}
        </motion.div>
    );
};

export default SpectaclePage;

import axios from "axios";
import create from 'zustand'
import MethodologicalSupportPage from "../../pages/public/activity/methodological.support.page";

const urlLoadCityClubMeetings = process.env.REACT_APP_BASE_URL + 'php/models/admin/activities/club_meetings/load_all.php';
const urlLoadCityClubMeeting = process.env.REACT_APP_BASE_URL + 'php/models/admin/activities/club_meetings/load.php';

const urlAddCityClubMeeting = process.env.REACT_APP_BASE_URL + 'php/models/admin/activities/club_meetings/add.php';
const urlEditCityClubMeeting = process.env.REACT_APP_BASE_URL + 'php/models/admin/activities/club_meetings/edit.php';
const urlRemoveCityClubMeeting = process.env.REACT_APP_BASE_URL + 'php/models/admin/activities/club_meetings/remove.php';

const urlMethodologicalSupports = process.env.REACT_APP_BASE_URL + 'php/models/admin/activities/methodological_support/load_all.php';
const urlMethodologicalSupport = process.env.REACT_APP_BASE_URL + 'php/models/admin/activities/methodological_support/load.php';

const urlAddMethodologicalSupport = process.env.REACT_APP_BASE_URL + 'php/models/admin/activities/methodological_support/add.php';
const urlEditMethodologicalSupport = process.env.REACT_APP_BASE_URL + 'php/models/admin/activities/methodological_support/edit.php';
const urlRemoveMethodologicalSupport = process.env.REACT_APP_BASE_URL + 'php/models/admin/activities/methodological_support/remove.php';

const useActivitiesStore = create(
    (set, get) => ({
        cityClubMeetings: [],
        cityClubMeeting: {},
        methodologicalSupports: [],
        methodologicalSupport: {},

        loading: false,
        sending: false,

        error: false,
        errorText: "",
        setErrorText: (text) => {
            set({error: true, errorText: text});
        },
        clearErrorText: () => {
            set({error: false, errorText: ""});
        },

        loadCityClubMeetings: async (params) => {
            set({loading: true});

            let form = new FormData();
            window.global.buildFormData(form, params);

            const response = await axios.postForm(urlLoadCityClubMeetings, form);

            set({loading: false});

            if(response.data.params){

                set((state) => ({cityClubMeetings: response.data.params}));

            }

        },
        loadCityClubMeeting: async (params) => {
            set({loading: true});

            let form = new FormData();
            window.global.buildFormData(form, params);

            const response = await axios.postForm(urlLoadCityClubMeeting, form);

            set({loading: false});

            if(response.data.params){

                set((state) => ({cityClubMeeting: response.data.params}));

            }

        },
        loadMethodologicalSupports: async (params) => {
            set({loading: true});

            let form = new FormData();
            window.global.buildFormData(form, params);

            const response = await axios.postForm(urlMethodologicalSupports, form);

            set({loading: false});

            if(response.data.params){

                set((state) => ({methodologicalSupports: response.data.params}));

            }
        },
        loadMethodologicalSupport: async (params) => {

            set({loading: true});

            let form = new FormData();
            window.global.buildFormData(form, params);

            const response = await axios.postForm(urlMethodologicalSupport, form);

            set({loading: false});

            if(response.data.params){
                set((state) => ({methodologicalSupport: response.data.params}));
            }
        },

        addCityClubMeeting: async (params) => {

            let form = new FormData();
            window.global.buildFormData(form, params);

            const response = await axios.postForm(urlAddCityClubMeeting, form);

            if (response.data) {

                console.log(response.data);

                if (response.data.error === 1) {

                    return {
                        error: true,
                        errorText: response.data.error_text
                    };

                }

            }

            return {error: false};

        },
        editCityClubMeeting: async (params) => {

            let form = new FormData();
            window.global.buildFormData(form, params);

            const response = await axios.postForm(urlEditCityClubMeeting, form);

            if (response.data) {

                console.log(response.data);

                if (response.data.error === 1) {

                    return {
                        error: true,
                        errorText: response.data.error_text
                    };

                }

            }

            return {error: false};

        },
        removeCityClubMeeting: async (params) => {

            let form = new FormData();
            window.global.buildFormData(form, params);

            const response = await axios.postForm(urlRemoveCityClubMeeting, form);

            if (response.data) {

                console.log(response.data);

                if (response.data.error === 1) {

                    return {
                        error: true,
                        errorText: response.data.error_text
                    };

                }

            }

            return {error: false};

        },
        addMethodologicalSupport: async (params) => {
            let form = new FormData();
            window.global.buildFormData(form, params);

            const response = await axios.postForm(urlAddMethodologicalSupport, form);

            if (response.data) {
                console.log(response.data);

                if (response.data.error === 1) {
                    return {
                        error: true,
                        errorText: response.data.error_text
                    };
                }
            }

            return {error: false};
        },
        editMethodologicalSupport: async (params) => {
            let form = new FormData();
            window.global.buildFormData(form, params);

            const response = await axios.postForm(urlEditMethodologicalSupport, form);

            if (response.data) {

                console.log(response.data);

                if (response.data.error === 1) {
                    return {
                        error: true,
                        errorText: response.data.error_text
                    };
                }
            }

            return {error: false};
        },
        removeMethodologicalSupport: async (params) => {
            let form = new FormData();
            window.global.buildFormData(form, params);

            const response = await axios.postForm(urlRemoveMethodologicalSupport, form);

            if (response.data) {

                console.log(response.data);

                if (response.data.error === 1) {
                    return {
                        error: true,
                        errorText: response.data.error_text
                    };
                }
            }

            return {error: false};
        },
    })
);

export default useActivitiesStore;